<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Tournaments</ion-title>
      </ion-toolbar>
      <ion-list :style="isPlatform('ios') ? 'margin-top: 10px;' : ''">
        <ion-item lines="full" style="height: 0px">
        </ion-item>
        <ion-item lines="full" class="white">
          <ion-button fill="clear" slot="start" @click="refresh()" >
            <ion-icon style="zoom: 1.4" color="primary" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="isOpenAll" fill="clear" slot="start" @click="openAll()">
            <ion-icon style="zoom: 1.4" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isOpenAll" fill="clear" slot="start" @click="closeAll()">
            <ion-icon style="zoom: 1.4" closeAllcolor="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isPlatform('mobile')" fill="clear" slot="start" @click="print()">
              <ion-icon style="zoom: 1.4" color="primary" :md="printOutline" :ios="printOutline"></ion-icon>
          </ion-button>
          <ion-button fill="clear" slot="start" @click="goDateToday()">
            <ion-icon color="primary" slot="start" :md="todayOutline" :ios="todayOutline" style="zoom: 1.4"></ion-icon>
          </ion-button>
          <ion-button fill="clear" slot="start" @click="goDateBack()">
            <ion-icon slot="start" :md="arrowBackOutline" :ios="arrowBackOutline" style="zoom: 1.4"></ion-icon>
          </ion-button>
          <ion-datetime slot="start" v-model="selectedDate" :day-short-names="customDayShortNames" display-format="MMM YY" placeholder="Select Date"></ion-datetime>
          <ion-button fill="clear" slot="start" @click="goDateForward()">
            <ion-icon slot="start" :md="arrowForwardOutline" :ios="arrowForwardOutline" style="zoom: 1.4"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-header>
    
    <ion-content :fullscreen="true" id='print' ref="content">
      <h3 style="margin: 15px;" v-if="tournaments && tournaments.length <= 0">{{ $t('noTournaments') }}</h3>
      <ion-card :id="(tournament.currentSlot ? 'currentSlot' : '')" v-for="(tournament, index) in tournaments" :key="index">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="timeOutline" :ios="timeOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ $t(dayjs(tournament.begin).format('dddd')) }}, {{ dayjs(tournament.begin).format('DD.MM.YYYY HH:mm') }} - {{ dayjs(tournament.end).format('HH:mm') }}</ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding" @click="tournaments[index].showContent = !tournaments[index].showContent">
            <ion-icon v-if="tournaments[index].showContent == false" style="zoom: 1.0" slot="start" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
            <ion-icon v-if="tournaments[index].showContent == true" style="zoom: 1.0" slot="start" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
            <ion-icon @click="register(tournament)" v-if="isLoggedIn && tournament.canRegister" style="zoom: 1.2" slot="start" :color="getPercentage(tournament.registeredPlayers, tournament.teiln_max).color" :md="downloadOutline" :ios="downloadOutline"></ion-icon>
            <ion-icon @click="unregister(tournament)" v-if="isLoggedIn && tournament.canUnRegister" style="zoom: 1.2" slot="start" color="warning" :md="exitOutline" :ios="exitOutline"></ion-icon>
            <ion-card-title class="clickable" :color="getTournamentColor(tournament)" :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'">{{ tournament.name}}</ion-card-title>
            <ion-icon v-if="tournament.imageSrc != ''" @click="showImage(tournament.imageSrc)" style="zoom: 1.2" slot="end" color="primary" :md="documentTextOutline" :ios="documentTextOutline"></ion-icon>
            <ion-img v-if="tournament.sponsorSrc != ''" style="height: 80px" slot="end" :src="tournament.sponsorSrc"></ion-img>
            <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="(tournament.payOnline || tournament.showPrice) && tournament.priceFloat > 0" slot="end">{{ tournament.price }}</ion-card-title>
          </ion-item>
        </ion-card-header>
        <ion-card-content v-if="tournaments[index].showContent">
          <ion-item lines="full" style="margin: 0px;">
            <ion-label v-if="(tournament.canRegisterError > 0 && dayjs().isBefore(dayjs(tournament.begin)) || tournament.canRegisterError == 7)" :color="tournament.canRegisterError == 7 ? 'success' : 'warning'" style="padding-left: 15px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t('tournamentRegisterError' + tournament.canRegisterError).replace('#', $t('competition')) }}</ion-label>
          </ion-item>
          <ion-item lines="full" style="margin: 0px;">
            <ion-label v-if="isLoggedIn && (tournament.canUnRegisterError > 0 && tournament.canUnRegisterError != 4 && dayjs().isBefore(dayjs(tournament.begin)))" color="warning" style="padding-left: 15px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t('tournamentUnRegisterError' + tournament.canUnRegisterError).replace('#', $t('competition')) }}</ion-label>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t('participants') }}: {{ tournament.registeredPlayers }} {{ $t('of') }} {{ tournament.teiln_max }} ( {{ $t('guests') }} {{ tournament.teiln_gast_max }})</ion-label>
            <ion-icon v-if="tournament.gender == 2 || tournament.gender == 1" class="ion-no-padding" style="zoom: 0.8" color="female" :md="womanSharp" :ios="womanSharp"></ion-icon>
            <ion-icon v-if="tournament.gender == 2 || tournament.gender == 0" class="ion-no-padding" style="zoom: 0.8" color="male" :md="manSharp" :ios="manSharp"></ion-icon>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
          <ion-progress-bar style="margin-left: 15px;"  :color="getPercentage(tournament.registeredPlayers, tournament.teiln_max).color" :value="getPercentage(tournament.registeredPlayers, tournament.teiln_max).percentage"></ion-progress-bar>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('webAvailable') }}: {{ tournament.teiln_web_available }} ( {{ $t('guests') }} {{ tournament.teiln_web_gast_available }})</ion-label>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('regPeriod') }}: {{ tournament.registration_period }} </ion-label>
          </ion-item >
          <ion-item style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('hcpi') }}: {{ tournament.hcp_from }} {{ $t('toStr') }} {{ tournament.hcp_to }}</ion-label>
          </ion-item>
          <ion-item class="ion-no-padding" v-for="(round, rindex) in tournament.rounds" :key="rindex">
            <ion-label style="padding-left: 15px;" class="ion-text-wrap">{{ $t('round') }} {{ (rindex + 1) }}: {{ $t(('PF' + round.play_form)) }}, {{ $t(('SM' + round.scoring_mode)) }}, {{ $t(('W' + round.wertung)) }} {{ round.is_inclusion ? ', ' + $t('isInclusion') : '' }} <strong>({{ $t(('HCPREL' + round.hcp_relevance)) }})</strong></ion-label>
            <ion-icon v-if="round.showEntryList" @click="showEntryList(tournament)" style="zoom: 1.2" slot="end" color="primary" :md="listOutline" :ios="listOutline"></ion-icon>
            <ion-icon v-if="round.showStartList" @click="showStartList(tournament, round)" style="zoom: 1.2" slot="end" color="primary" :md="listCircleOutline" :ios="listCircleOutline"></ion-icon>
            <ion-icon v-if="round.showResultList" @click="showResultList(tournament, round)" style="zoom: 1.2" slot="end" color="primary" :md="medalOutline" :ios="medalOutline"></ion-icon>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonButton, IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCardTitle, IonCardSubtitle, 
  IonCardHeader, IonCardContent, IonIcon, IonDatetime, IonItem, IonList, IonInput, loadingController, IonLabel,
  modalController, IonImg, isPlatform, IonProgressBar, toastController, alertController
  } from '@ionic/vue';
import { arrowForwardOutline, arrowBackOutline, todayOutline, helpCircleOutline,refreshOutline, chevronDownOutline,
         chevronUpOutline, listOutline, medalOutline, documentTextOutline, timeOutline,downloadOutline, 
         printOutline, manSharp, womanSharp, listCircleOutline, exitOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import findTournamentsByMonthQueryFile from '../graphql/findTournamentsByMonth.query.gql'
import unregisterTournamentMutationFile from '../graphql/unregisterTournament.mutation.gql'
import translate from '@/plugins/translate';
import * as dayjs from 'dayjs';
import ImageDisplayDialog from './dialogs/ImageDisplayDialog'
import store from '../main'
import ShowEntryListDialog from './dialogs/ShowEntryListDialog'
import ShowStartListDialog from './dialogs/ShowStartListDialog'
import ShowResultListDialog from './dialogs/ShowResultListDialog'
import RegisterTournamentDialog from './dialogs/RegisterTournamentDialog'
import UnRegisterTournamentDialog from './dialogs/UnRegisterTournamentDialog'
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard, IonItem, IonList, IonInput, IonImg, IonProgressBar,
    IonButton, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonIcon, IonDatetime, IonLabel
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      isLoggedIn : function() { 
        if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.findTournamentsByMonthRefetch()
        }
        return this.$store.getters.isLoggedIn
      },
      UserData : function(){ return this.$store.getters.UserData},
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    if (!route.params.date) {
      router.push('/competitions/' + dayjs(new Date()).format('YYYY-MM'))
    }

    let selectedDate = ref(dayjs(new Date()).format('YYYY-MM'))
    if (route.params.date)
      selectedDate = ref(dayjs(new Date(route.params.date)).format())
    const token = store.getters.token
    let loadingSpinner = null
    let isOpenAll = ref(true)
    const content = ref();
    const contentToScroll = ref(400)
    const currentSlot = ref();

    const customDayShortNames = [
      translate('sunday'),
      translate('monday'),
      translate('tuesday'),
      translate('wednesday'),
      translate('thursday'),
      translate('friday'),
      translate('saturday')
    ];

    const { onResult, refetch: findTournamentsByMonthRefetch, result: findTournamentsByMonthResult, loading: findTournamentsByMonthLoading } = useQuery(findTournamentsByMonthQueryFile, () => ({ isEvent: false,  date: dayjs(selectedDate.value).format('YYYY-MM-DD') }), { fetchPolicy: 'no-cache' })
    const tournaments = useResult(findTournamentsByMonthResult, null, data => data.findTournamentsByMonth.tournaments)
    
    const { mutate: unregisterTournamentMutation } = useMutation(unregisterTournamentMutationFile, { fetchPolicy: 'no-cache' });

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!findTournamentsByMonthLoading.value) {
        setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.offsetTop) }, 1000)
          loadingSpinner.dismiss() 
        }
      })
    }).catch(err => { })

    watch(
      () => route.params.date,
      (date, prevId) => {
        if (route.path.startsWith('/competitions') && date != undefined)
          selectedDate.value = dayjs(new Date(route.params.date)).format()
      }
    )

    watch(findTournamentsByMonthLoading, (findTournamentsByMonthLoading, prevLoading) => {
      if (!findTournamentsByMonthLoading && loadingSpinner != null) {
        setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.offsetTop) }, 1000)
        loadingSpinner.dismiss()
        return
      }
      else if (findTournamentsByMonthLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!findTournamentsByMonthLoading) {
                  setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.offsetTop) }, 1000)
                  loadingSpinner.dismiss() 
                }
              })
            }).catch(err => { })
    })

    return {
      findTournamentsByMonthRefetch, findTournamentsByMonthLoading, loadingSpinner,
      customDayShortNames,  selectedDate, tournaments, dayjs, isPlatform,
      todayOutline, arrowBackOutline, arrowForwardOutline,helpCircleOutline,refreshOutline, chevronDownOutline, 
      chevronUpOutline, listOutline, medalOutline, documentTextOutline, timeOutline,
      printOutline, manSharp, womanSharp, isOpenAll, listCircleOutline, exitOutline,
      unregisterTournamentMutation, downloadOutline, content, contentToScroll, currentSlot
    }
  },
  methods: {
    getTournamentColor(tournament) {
      if (tournament.canRegisterError == 7)
        return 'success'
      if (dayjs().isBefore(dayjs(tournament.begin)) && tournament.canRegisterError > 0 )
        return 'warning'
    },
    getPercentage (bookedPlayers, maxPlayers) {
      const percentage = (bookedPlayers / maxPlayers) == 0 ? 1: bookedPlayers / maxPlayers
      let color = "successlight"
      if (bookedPlayers == maxPlayers)
        color = "danger"
      else if (percentage > 0 && percentage <= 0.25)
        color = "success"
      else if (percentage > 0.25 && percentage <= 0.5)
        color = "warninglight"
      else if (percentage > 0.5 && percentage <= 0.75)
        color = "warning"
      else if (percentage > 0.75 && bookedPlayers != 0)
        color = "dangerlight"

      return { percentage: percentage, color: color }
    },
    async showImage(imageSrc) {
      const modal = await modalController
        .create({
          component: ImageDisplayDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              imageSrc: imageSrc,
              headerStr: this.$t('announcement'),
            }
          },
        })
      return modal.present();
    },
    async register(tournament) {
      const modal = await modalController
        .create({
          component: RegisterTournamentDialog,
          componentProps: {
            propsData: {
              tournament: tournament,
            }
          },
        })
        modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              this.findTournamentsByMonthRefetch()
            }
          })
      return modal.present();

    },
    async unregister(tournament) {
      if (tournament.bookedNum > 0) {
        const modal = await modalController
          .create({
            component: UnRegisterTournamentDialog,
            componentProps: {
              propsData: {
                tournament: tournament,
                personId: this.UserData
              }
            },
          })
        modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              this.findTournamentsByMonthRefetch()
            }
          })
        return modal.present();
      }
      const alert = await alertController
        .create({
          header: this.$t('competition'),
          message: this.$t('unregister') + ': ' +  tournament.name + '?',
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.unregisterTournamentMutation({ id: parseInt(tournament.id), addPlayers: '', language: this.$store.getters.language})
                .then(res => {
                  toastController
                    .create({
                      message: this.$t('unregisterSuccessful'),
                      duration: 2000,
                      color: 'warning'
                    }).then(res => res.present())
                    this.findTournamentsByMonthRefetch()
                })
                .catch(err => {
                  toastController
                    .create({
                      message: this.$t('unregisterNotSuccessful' + err),
                      duration: 2000,
                      color: 'danger',
                    }).then(res => res.present())
                })
              },
            },
          ],
        });
      return alert.present();
    },
    async showEntryList(tournament) {
      const modal = await modalController
        .create({
          component: ShowEntryListDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              selectedTournament: tournament,
            }
          },
        })
      return modal.present();
    },
    async showStartList(tournament, round) {
      const modal = await modalController
        .create({
          component: ShowStartListDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              selectedRound: round,
              selectedTournament: tournament,
            }
          },
        })
      return modal.present();
    },
    async showResultList(tournament, round) {
      const modal = await modalController
        .create({
          component: ShowResultListDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              selectedRound: round,
              selectedTournament: tournament,
            }
          },
        })
      return modal.present();
    },
    refresh() {
      this.isOpenAll = true
      this.findTournamentsByMonthRefetch()
    },
    openAll() {
      for (const t of this.tournaments) {
        t.showContent = true
      }
      this.isOpenAll = false
    },
    closeAll() {
      for (const t of this.tournaments) {
        t.showContent = false
      }
      this.isOpenAll = true
    },
    print() {
      window.print();    
    },
    goDateToday() {
      this.$router.push('/competitions/' + dayjs(new Date()).format('YYYY-MM'))
      this.selectedDate = dayjs(new Date()).format()
    },
    goDateBack() {
      this.$router.push('/competitions/' + dayjs(this.selectedDate).subtract(1, 'month').format('YYYY-MM'))
      this.selectedDate = dayjs(this.selectedDate).subtract(1, 'month').format()
    },
    goDateForward() {
      this.$router.push('/competitions/' + dayjs(this.selectedDate).add(1, 'month').format('YYYY-MM'))
      this.selectedDate = dayjs(this.selectedDate).add(1, 'month').format()
    },
  }
});
</script>

<style scoped>

.clickable {
    cursor: pointer;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

ion-item {
  --ion-background-color: white !important;
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-item.white {
  --ion-background-color: white !important;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>