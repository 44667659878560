<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('helpTournamentEL') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedTournament">
          {{ selectedTournament.name }}
        </ion-card-title>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedRound">
          {{ $t('round') }} {{ selectedRound.round_index }}: {{ dayjs(selectedRound.start).format('DD.MM.YYYY') }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list v-for="(item, index) in resultList" :key="index" :value="item">
          <ion-list-header :style="isPlatform('mobile') ? 'padding: 0px; margin: 0px; margin-top: 5px; font-size: 18px !important;': 'padding: 0px; margin: 0px; margin-top: 5px; font-size: 20px !important;'">
            <ion-item lines="none" @click="resultList[index].showContent = !resultList[index].showContent">
             <ion-icon v-if="!resultList[index].showContent" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline" style="zoom: 0.8; margin-right: 5px;"></ion-icon>
             <ion-icon v-if="resultList[index].showContent" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline" style="zoom: 0.8; margin-right: 5px;"></ion-icon>
              {{ item.name }}
           </ion-item>
          </ion-list-header>

          <ion-list  lines="full" style="padding: 0px; margin: 0px; border: none;" v-if="resultList[index].showContent && !resultList[index].isMatchPlay && resultList[index].isTeam" v-for="(team, i) in item.teamResult" :key="i" :value="team">
            <ion-item style="margin-top: 10px;">
              <strong slot="start">{{ team.platzierung }} </strong>
              <strong slot="start">{{ team.name }} </strong>
              <strong slot="end">{{ team.result }} </strong>
            </ion-item>
          
          <ion-item  v-for="(player, p) in team.players" :key="p">
            <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 0.8; margin-right: 5px;"></ion-icon>
            <span>{{ player.nameLong }}</span>
            <span slot="end">{{ player.result }}</span>
          </ion-item>
          </ion-list>

          <ion-list  lines="none" style="padding: 0px; margin: 0px; border: none;" v-if="resultList[index].showContent && resultList[index].isMatchPlay && !resultList[index].isTeam" v-for="(player, i) in item.result" :key="i" :value="player">
            <ion-grid>
              <ion-row>
                <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">
                  <strong v-if="player.is_winner">{{ player.nameLong }}</strong>
                  <span v-if="!player.is_winner">{{ player.nameLong }}</span>
                </ion-col>
                <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding">
                  {{ $t(player.matchplayResult) }}
                </ion-col>
                <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">
                  <strong v-if="player.is_winnerGegner">{{ player.nameLongGegner }}</strong>
                  <span v-if="!player.is_winnerGegner">{{ player.nameLongGegner }}</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-list>

          <ion-list  lines="none" style="padding: 0px; margin: 0px; border: none;" v-if="resultList[index].showContent && !resultList[index].isMatchPlay && !resultList[index].isTeam" v-for="(player, i) in item.result" :key="i" :value="player">
            <ion-item @click="item.result[i].showScorecard = !item.result[i].showScorecard" :lines="(player.drawLine ? 'full' : 'none')">
              <strong v-if="player.is_winner">{{ player.platzierung }}</strong>
              <span v-if="!player.is_winner">{{ player.platzierung }} </span>
              <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 0.8; margin-right: 5px;"></ion-icon>
              <strong v-if="player.is_winner">{{ player.nameLong }}</strong>
              <span v-if="!player.is_winner">{{ player.nameLong }}</span>
              <span slot="end">{{ player.result }}</span>
            </ion-item>
              <ion-grid v-if="player.showScorecard">
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding" size="7">{{ $t('coursename')}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Par</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">CR</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Slope</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Tee</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding" size="7">{{ player.scorecard.course }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.par }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.cr }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.slope }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.tee }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;border-bottom: 1px solid grey;" class="ion-no-padding">#</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.pars" :key="index">{{index+1}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">OUT</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Sc.</ion-col>
                  <ion-col class="ion-no-padding" :style="'border-bottom: 1px solid grey;text-align: center;background-color: ' + player.scorecard.firstNine.color[index]" v-for="(num, index) in player.scorecard.firstNine.score" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.firstNine.scoreSum }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">GBE</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.gbeWithIndexStr.gbe" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.firstNine.GBESum }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Br.</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.brutto" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.firstNine.bruttoSum }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Nto</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.netto" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.firstNine.nettoSum }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Par</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.pars" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.firstNine.parSum }}</ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Hcp</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.index" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding"></ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">//</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;margin-bottom: 10px;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.gbeWithIndexStr.indexStr" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;margin-bottom: 10px;" class="ion-no-padding"></ion-col>
                </ion-row>
                <!-- <ion-row>
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">{{ $t('length')}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;margin-bottom: 10px;" class="ion-no-padding" v-for="(num, index) in player.scorecard.firstNine.length" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.firstNine.lengthSum }}</ion-col>
                </ion-row>-->

                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">#</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.pars" :key="index">{{index+10}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">IN</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">Σ</ion-col>
                  
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Sc.</ion-col>
                  <ion-col class="ion-no-padding" :style="'border-bottom: 1px solid grey;text-align: center;background-color: ' + player.scorecard.secondNine.color[index]" v-for="(num, index) in player.scorecard.secondNine.score" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.secondNine.scoreSum }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.scoreSum }}</ion-col>
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">GBE</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.gbeWithIndexStr.gbe" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.secondNine.GBESum }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.GBESum }}</ion-col>
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Br.</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.brutto" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.secondNine.bruttoSum }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.bruttoSum }}</ion-col>
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Nto</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.netto" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.secondNine.nettoSum }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.nettoSum }}</ion-col>
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Par</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.pars" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.secondNine.parSum }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding">{{ player.scorecard.parSum }}</ion-col>
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;" class="ion-no-padding">Hcp</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.index" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding"></ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;" class="ion-no-padding"></ion-col>
                </ion-row>
                <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">//</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;margin-bottom: 10px;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.gbeWithIndexStr.indexStr" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;margin-bottom: 10px;" class="ion-no-padding"></ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;margin-bottom: 10px;" class="ion-no-padding"></ion-col>
                </ion-row>
                <!-- <ion-row v-if="player.scorecard.secondNine">
                  <ion-col style="border-bottom: 1px solid grey;margin-bottom: 10px;" class="ion-no-padding">{{ $t('length')}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;margin-bottom: 10px;" class="ion-no-padding" v-for="(num, index) in player.scorecard.secondNine.length" :key="index">{{num}}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.secondNine.lengthSum }}</ion-col>
                  <ion-col style="border-bottom: 1px solid grey;text-align: center;color: red;margin-bottom: 10px;" class="ion-no-padding">{{ player.scorecard.lengthSum }}</ion-col>
                </ion-row>-->

              </ion-grid>
            <ion-item>
            </ion-item>
          </ion-list>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonListHeader,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, manSharp, womanSharp, personOutline, chevronUpOutline, chevronDownOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getResultListByRoundIdQueryFile from '../../graphql/getResultListByRoundId.query.gql'
import * as dayjs from 'dayjs'; 

export default defineComponent({
  name: 'ShowResultListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let selectedRound = ref(null)
    let selectedTournament = ref(null)

    const { result: getResultListByRoundIdResult } = useQuery(getResultListByRoundIdQueryFile, () => ({ id: (selectedRound.value ? parseInt(selectedRound.value.id) : 0) }), { fetchPolicy: 'no-cache' })
    let resultList = useResult(getResultListByRoundIdResult, null, data => data.getResultListByRoundId.resultList)
    return { dayjs, closeOutline, selectedRound, selectedTournament, resultList, manSharp, womanSharp, personOutline,
             isPlatform, chevronUpOutline, chevronDownOutline
    }
  },
  mounted() {
    this.selectedRound = this.propsData.selectedRound
    this.selectedTournament = this.propsData.selectedTournament
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-list {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-list:first-of-type {
  border-top: 0.55px solid #c8c7cc;
}
section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>