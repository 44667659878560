<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Marshall</ion-title>
      </ion-toolbar>
    </ion-header>
      <ion-header :translucent="true">
        <ion-item lines="full"  v-if="courseName">
          <ion-button fill="clear" slot="start" @click="refresh()" >
             <ion-icon style="zoom: 1.4" color="primary" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
          </ion-button>
          <ion-label :style="'color: #' + courseColor + ';'" class="course">{{ courseName }}</ion-label>
          <ion-label slot="end" :style="'color: #' + courseColor + ';'" class="course">{{ dayjs().format('DD.MM.YYYY') }}</ion-label>
        </ion-item>
        <ion-item  v-if="courseName">
        <ion-input :placeholder="translate('messageToOffice')" type="text" v-model="message"></ion-input>
          <ion-button @click="sendMessage()">{{$t('send')}}</ion-button>
        </ion-item>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-card :style="'background-color: #' + courseColor + ''">
          <ion-item style="margin: 3px;">
          <ion-grid>
            <ion-row v-for="(teetime, index) in teetimesFound" :key="index">
              <ion-col class="time rows">{{ dayjs(teetime.time).format('HH:mm') }}
                  <ion-grid v-if="teetime.tid > 0 && teetime.players[0].name != ''" class="ion-no-padding ion-no-margin">
                    <ion-row>
                      <ion-col size="4">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="checkin(teetime.tid, 2, 0)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" color="success" :md="checkboxOutline" :ios="checkboxOutline"></ion-icon>
                        </ion-button>     
                      </ion-col>
                      <ion-col size="4">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="checkin(teetime.tid, 0, 0)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" color="danger" :md="closeCircleOutline" :ios="closeCircleOutline"></ion-icon>
                        </ion-button>     
                      </ion-col>
                      <ion-col size="4">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="checkin(teetime.tid, 1, 0)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" color="secondary" :md="arrowUndo" :ios="arrowUndo"></ion-icon>
                        </ion-button>     
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col size="12">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="contacted(teetime.tid)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" :color="teetime.contacted == 0 ? 'warning' : 'success'" :md="carOutline" :ios="carOutline"></ion-icon>
                        </ion-button>     
                      </ion-col>
                    </ion-row>
                  </ion-grid>
              </ion-col>
              <ion-col  v-for="(player, index) in teetime.players" :key="index" class="rows">
                <ion-card :color="teetime.lock ? 'warning' : (player.name == '' ? 'success' : 'primary')" class="playercard ion-no-padding">
                  <ion-card-content class="ion-no-margin ion-no-padding player">
                      <ion-grid v-if="(teetime.lock && player.club || !teetime.lock) && (player.checkedIn != 1 || (player.img && player.img != ''))">
                        <ion-row>
                          <ion-col size="2">
                            <ion-icon v-if="player.checkedIn == 2" style="zoom: 1.8; margin-right: 5px;" color="success" :md="checkmarkOutline" :ios="checkmarkOutline"></ion-icon>
                            <ion-icon v-show="player.checkedIn == 0" style="zoom: 1.8; margin-right: 5px;" color="danger" :md="closeOutline" :ios="closeOutline"></ion-icon>
                          </ion-col>
                          <ion-col size="10" v-if="player.img && player.img != ''">
                            <ion-img :class="imgClass" :src="player.img" @click="changeImg()" />
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                  
                  <ion-label v-if="player.club" color="">{{ player.name }} {{ player.age }}</ion-label>
                  <ion-label :color="player.membercolor">{{ player.memberstatus }}</ion-label>
                  <ion-label v-if="player.vaccinated != ''" color="danger">{{ player.vaccinated }}</ion-label>
                  {{ player.club }}
                  <ion-label v-if="teetime.lockCause != ''" color="danger">{{ teetime.lockCause }}</ion-label>
                  <ion-grid v-if="player.club && player.club != ''" class="ion-no-padding ion-no-margin">
                    <ion-row>
                      <ion-col size="4" v-show="player.checkedIn != 2">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="checkin(player.tid, 2, player.pos)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" color="success" :md="checkboxOutline" :ios="checkboxOutline"></ion-icon>
                        </ion-button>     
                      </ion-col>
                      <ion-col size="4" v-show="player.checkedIn != 0">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="checkin(player.tid, 0, player.pos)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" color="danger" :md="closeCircleOutline" :ios="closeCircleOutline"></ion-icon>
                        </ion-button>     
                      </ion-col>
                      <ion-col size="4" v-show="player.checkedIn != 1">
                        <ion-button class="ion-no-padding ion-no-margin" fill="clear" @click="checkin(player.tid, 1, player.pos)">
                          <ion-icon style="zoom: 1.8; margin-left: 1px;" color="secondary" :md="arrowUndo" :ios="arrowUndo"></ion-icon>
                        </ion-button>     
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
          </ion-item>
        </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import getMarshallQueryFile from '../graphql/getMarshall.query.gql'
import sendMessageMutationFile from '../graphql/sendMessage.mutation.gql'
import setCheckInMutationFile from '../graphql/setCheckIn.mutation.gql'
import setContactedMutationFile from '../graphql/setContacted.mutation.gql'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { timeOutline, refreshOutline, checkmarkCircle, closeCircleOutline, arrowUndo, checkmarkOutline, closeOutline, close, checkbox, checkboxOutline, carOutline} from 'ionicons/icons';
import { useRoute } from 'vue-router';
import * as dayjs from 'dayjs';
import translate from '@/plugins/translate'

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute()
    const message = ref('')
    const imgClass = ref('playerImg')

    const { result: findTeetimesResult, refetch: findTeetimesRefetch } = useQuery(getMarshallQueryFile, () => ({ id: parseInt(route.params.id) }), () => ({ fetchPolicy: 'no-cache', pollInterval: 60000, enabled:  route.params.id != null }))
    const teetimesFound = useResult(findTeetimesResult, null, data => data.getMarshall.teetimes)
    const courseName = useResult(findTeetimesResult, null, data => data.getMarshall.coursename)
    const courseColor = useResult(findTeetimesResult, null, data => data.getMarshall.coursecolor)
    const logo = useResult(findTeetimesResult, null, data => data.getMarshall.logo)

    const { mutate: sendMessageMutation } = useMutation(sendMessageMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: setCheckInMutation } = useMutation(setCheckInMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: setContactedMutation } = useMutation(setContactedMutationFile, { fetchPolicy: 'no-cache' });

    return {
      timeOutline, teetimesFound, dayjs, courseName, courseColor, logo, refreshOutline, findTeetimesRefetch, message, translate, sendMessageMutation, setCheckInMutation, checkmarkCircle, closeCircleOutline, arrowUndo, checkmarkOutline, closeOutline, close, checkbox, checkboxOutline, setContactedMutation, carOutline, imgClass
    }
  },
  methods: {
    changeImg() {
      if (this.imgClass == 'playerImg')
        this.imgClass = 'playerImgBig'
      else
        this.imgClass = 'playerImg'
    },
    contacted(id) {
      this.setContactedMutation({ id: id }).then(res => { 
        if (res.data.setContacted.success) {
          toastController
            .create({
              message: translate('statusSet'),
              duration: 3000,
              color: 'success',
            }).then(res => res.present())
          this.refresh()
        }
        else {
          toastController
            .create({
              message: translate('notSuccessful'),
              duration: 3000,
              color: 'danger',
            }).then(res => res.present())
        }
      })
    },
    checkin(id, status, pos) {
      this.setCheckInMutation({ id: id, status: status, pos: pos }).then(res => {
        if (res.data.setCheckIn.success) {
          toastController
            .create({
              message: translate('statusSet'),
              duration: 3000,
              color: 'success',
            }).then(res => res.present())
          this.refresh()
        }
        else {
          toastController
            .create({
              message: translate('notSuccessful'),
              duration: 3000,
              color: 'danger',
            }).then(res => res.present())
        }
      })
    },
    sendMessage() {
      if (this.message == "")
        return
      this.sendMessageMutation({ message: this.message }).then(res => {
        if (res.data.sendMessage.success) {
          toastController
            .create({
              message: translate('statusSet'),
              duration: 3000,
              color: 'success',
            }).then(res => res.present())
          this.refresh()
          this.message = ''
        }
        else {
          toastController
            .create({
              message: translate('notSuccessful'),
              duration: 3000,
              color: 'danger',
            }).then(res => res.present())
        }
      })

    },
    refresh() {
      this.findTeetimesRefetch()
    },
  }
});
</script>

<style scoped>

.playerImg {
  width: 40px; 
  height:40px
}

.playerImgBig {
  width: 120px; 
  height:120px
}

.card {
  background-color: rgb(75, 59, 219); 
}

.playercard {
   height: auto;
}

.player {
   padding: 5px !important;
}

ion-col.rows {
   margin: 0px;
   padding: 0px;
   height: auto;
   font-weight: bold;
   border-bottom: 1px solid grey;
   vertical-align: center;
}

ion-col.header {
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    vertical-align: center;
  }

ion-col.time {
    font-size: 24px; 
    font-weight: bold;
  }

.course {
    font-size: 24px; 
    font-weight: bold;
  }

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>