<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('helpTournamentML') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedTournament">
          {{ selectedTournament.name }} {{ dayjs(selectedTournament.begin).format('DD.MM.YYYY HH:mm') }}
        </ion-card-title>
      </ion-card-header>
      <ion-list lines="full" class="ion-no-margin">
        <ion-item v-for="(item, index) in tournamentPlayers" :key="index" :value="item">
            <ion-icon  :color="item.sex_sport" :md="(item.sex_sport == 'female' ? womanSharp : (item.sex_sport == 'male' ? manSharp: personOutline))" :ios="(item.sex_sport == 'female' ? womanSharp : (item.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 0.8; margin-right: 5px;"></ion-icon>
            <ion-label class="ion-text-wrap">{{ item.lastName }} {{ item.firstName }} {{item.hcp}}</ion-label>
            <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" v-if="item.avatarLink != '' && item.showAvatar">
              <img :src="item.avatarLink">
            </ion-avatar>
        </ion-item>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, manSharp, womanSharp, personOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getEntryListByTournamentIdQueryFile from '../../graphql/getEntryListByTournamentId.query.gql'
import * as dayjs from 'dayjs'; 

export default defineComponent({
  name: 'ShowEntryListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,
              },
  props: ['propsData'],
  setup() {
    let selectedTournament = ref(null)
    const imgClass = ref('playerImg')

    const { result: getEntryListByTournamentIdResult } = useQuery(getEntryListByTournamentIdQueryFile, () => ({ id: (selectedTournament.value ? parseInt(selectedTournament.value.id) : 0) }), { fetchPolicy: 'no-cache' })
    let tournamentPlayers = useResult(getEntryListByTournamentIdResult, null, data => data.getEntryListByTournamentId.tournamentPlayers)
    return { dayjs, closeOutline, selectedTournament, tournamentPlayers, manSharp, womanSharp, personOutline, isPlatform, imgClass
    }
  },
  mounted() {
    this.selectedTournament = this.propsData.selectedTournament

  },
  methods: {
    changeImg() {
      if (this.imgClass == 'playerImg')
        this.imgClass = 'playerImgBig'
      else
        this.imgClass = 'playerImg'
    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>

.playerImg {
  width: 40px; 
  height: 40px;
}

.playerImgBig {
  width: 120px; 
  height:120px
}

</style>