module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Mijn Club",
    "pros": "Pros",
    "persons": "Personen",
    "competitions": "Toernooien",
    "competition": "toernooi",
    "competitionUC": "Toernooi",
    "bookings": "Boekingen",
    "statistics": "Statistieken",
    "settings": "Instellingen",
    "profile": "Profiel",
    "login": "Inloggen",
    "logout": "Uitloggen",
    "organizations-list": "Mijn Organisaties",
    "companies": "Clubs",
    "company": "Club",
    "searchcompanies": "Clubs zoeken",
    "searchresult": "Zoekresultaat",
    "history": "Recent",
    "register": "Registreren",
    "save": "Save",
    "userData": "Gebruikersgegevens",
    "changePassword": "Wijzig wachtwoord",
    "changeEmail": "Verander e-mail",
    "language": "Taal",
    "firstName": "Voornaam",
    "lastName": "Achternaam",
    "birthdate": "Verjaardag",
    "password": "Wachtwoord",
    "email": "E-mail",
    "profilePicture": "Profiel foto",
    "takePicture": "Selecteer foto",
    "upload": "Foto opslaan",
    "passwordReset": "Verstuur e-mail voor opnieuw instellen van wachtwoord",
    "events": "Evenementen",
    "event": "evenement",
    "eventUC": "Evenement",
    "loading": "Gegevens laden...",
    "newcompanies": "Gastheer worden",
    "erroLogin": "Log in om een club te maken!",
    "newCategory": "Nieuwe categorie",
    "description": "Beschrijving",
    "changeSuccessful": "Succesvol veranderen",
    "changeNotSuccessful": "Fout tijdens wijziging",
    "deleteSuccessful": "Succesvol wissen",
    "deleteNotSuccessful": "Fout bij het verwijderen",
    "addSuccessful": "Succesvol toevoegen",
    "addNotSuccessful": "Fout tijdens toevoegen",
    "notSuccessful": "Er is een fout opgetreden",
    "name": "Naam",
    "shortName": "Korte naam",
    "priority": "Prioriteit",
    "delete": "Delete",
    "cancel": "Annuleren",
    "ok": "OK",
    "bookingDuration": "Duur van de reservering",
    "showAvailableSlots": "Beschikbare slots tonen",
    "showBookedSlots": "Toon geboekte slots",
    "withSlider": "Toon schuifregelaar",
    "intervallBetweenBookings": "Tijd tussen twee boekingen",
    "maxBookingsInFuture": "Max. reserveringen",
    "maxBookingsPerDay": "Max. reserveringen per dag",
    "maxBookingsPerWeek": "Max. boekingen per week",
    "maxPersonsBookable": "Max. aantal te boeken slots",
    "minPersonsBookable": "Min. aantal te boeken slots",
    "newBookingType": "Nieuw reserveringstype",
    "from": "Periode van",
    "to": "Periode tot",
    "cart": "Winkelwagen",
    "teetimes": "Teetimes",
    "pro": "Pro",
    "courses": "Cursussen",
    "course": "Cursus",
    "ranges": "Bereik",
    "available": "",
    "sperrungnoshow": "Gesloten",
    "showAvatar": "Toon avatar in boekingskalenders",
    "withDog": "Met hond",
    "with9Hole": "Plannen om 9 holes te spelen",
    "withPlayWithMe": "Blij om bij mij te boeken",
    "member": "Lid",
    "memberFemale": "Lid vrouw",
    "memberMale": "Lid mannetje",
    "guest": "Gast",
    "guestFemale": "Gast vrouw",
    "guestMale": "Gast man",
    "displayOptions": "Weergave-opties",
    "showName": "Toon naam",
    "showHCP": "Handicap tonen",
    "showGender": "Toon geslacht",
    "all": "Alle",
    "min1": "Min. 1 vrije plaats",
    "min2": "Min. 2 vrije plaatsen",
    "min3": "Min. 3 vrije plaatsen",
    "min4": "Min. 4 vrije plaatsen",
    "hcp": "Handicap",
    "syncHCP": "Handicap synchroniseren",
    "hcpSyncOK": "Handicap synchronisatie succesvol",
    "hcpErrorSync": "Fout bij synchroniseren handicap",
    "bookingTeetimeHelp": "Help teetime boeken",
    "myBooking": "Mijn boeking",
    "monday": "ma",
    "tuesday": "Tue",
    "wednesday": "Wed",
    "thursday": "Donderdag",
    "friday": "vr",
    "saturday": "Zat",
    "sunday": "Zon",
    "makeBooking": "Boek de teetime",
    "makeBookingAndPay": "Boek en betaal teetime",
    "makeProBooking": "Boek pro les",
    "makeAndPayProBooking": "Boek en betaal pro les",
    "makeRangeBooking": "Reserveer een reeks slots",
    "makeAndPayRangeBooking": "Reserveer en betaal range slot",
    "maxDays": "Max. dagen voor reservering",
    "timeSwitch": "Tijd toevoegen extra dag",
    "allowAlwaysBookingHours": "Uren altijd te boeken",
    "courseConfigs": "Cursus configuratie",
    "unlimited": "Geen beperkingen",
    "myCourseData": "Mijn gegevens",
    "myFutureBookings": "Boekingen totaal",
    "myBookingToday": "Boekingen geselecteerde datum",
    "myBookingWeek": "Boekingen deze week",
    "bookingCourseHelp": "Help Cursusconfiguratie",
    "bookingCourseHelp1": "Deze configuraties zijn gedeponeerd door de golfclub.",
    "openFriends": "Vrienden configureren",
    "editBooking": "Mijn boeking",
    "@me": "Ik",
    "player": "Speler",
    "username": "Id-kaartnummer of bijnaam",
    "announcement": "Aankondiging",
    "participants": "Deelnemers",
    "of": "van",
    "round": "Rond",
    "guests": "Gasten",
    "webAvailable": "Beschikbaar in web",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "Niet HCPI™-relevant",
    "HCPREL1": "HCPI™-Relevant",
    "regPeriod": "Registratieperiode",
    "hcpi": "HCPI™",
    "toStr": "naar",
    "timerRunning": "Teetime wacht nog steeds...",
    "timerStopped": "Uw teetime is nu ook beschikbaar voor andere klanten.",
    "teetimeLocked": "Teetime is geblokkeerd",
    "tournamentRegisterError1": "Niet ingelogd",
    "tournamentRegisterError2": "Geslacht komt niet overeen",
    "tournamentRegisterError3": "Handicap komt niet overeen",
    "tournamentRegisterError4": "Registratieperiode komt niet overeen",
    "tournamentRegisterError5": "De inschrijvingslijst is vol",
    "tournamentRegisterError6": "Leeftijdsgroep komt niet overeen",
    "tournamentRegisterError7": "Geregistreerd in #",
    "tournamentUnRegisterError1": "Niet ingelogd",
    "tournamentUnRegisterError2": "Uitschrijven niet toegestaan",
    "tournamentUnRegisterError3": "Uitschrijfdatum overschreden",
    "tournamentUnRegisterError4": "Niet geregistreerd in #",
    "tournamentUnRegisterError5": "Uitschrijven niet toegestaan omdat online betaald",
    "helpCoursesTime": "Cursusdatum, begin- en eindtijd",
    "helpCoursesEnter": "Inschrijven voor cursus",
    "helpCoursesUnregister": "Uitschrijven uit cursus",
    "helpCoursesSeries": "Cursusreeks",
    "helpCourses": "Help Cursussen",
    "helpTournamentTime": "Datum, begin- en eindtijd van het toernooi",
    "helpTournamentDown": "Details openen",
    "helpTournamentUp": "Details sluiten",
    "helpTournamentEnter": "Registreren",
    "helpTournamentEnterWaitlist": "Inschrijven voor wachtlijst",
    "helpTournamentUnregister": "Uitschrijven",
    "helpTournamentGenderFemale": "Toernooi toegestaan voor vrouwen",
    "helpTournamentGenderMale": "Toernooi toegestaan voor mannen",
    "helpTournamentML": "Lijst van binnenkomsten",
    "helpTournamentSL": "Start lijst",
    "helpTournamentEL": "Resultaatlijst",
    "helpTournamentAnnouncment": "Aankondiging",
    "helpTournament": "Toernooien helpen",
    "helpEventTime": "Datum, begin- en eindtijd van het evenement",
    "helpEventDown": "Details openen",
    "helpEventUp": "Details sluiten",
    "helpEventEnter": "Registreren voor evenement",
    "helpEventEnterWaitlist": "Inschrijven voor wachtlijst evenement",
    "helpEventUnregister": "Uitschrijven uit evenement",
    "helpEventGenderFemale": "Evenement toegestaan voor vrouwen",
    "helpEventGenderMale": "Evenement toegestaan voor mannen",
    "helpEventAnnouncment": "Beschrijving",
    "helpEvent": "Help Evenementen",
    "helpRoundSL": "Start lijst",
    "unregister": "Uitschrijven van",
    "unregisterSuccessful": "Succesvol afgemeld",
    "unregisterNotSuccessful": "Fout bij afmelden",
    "registerSuccessful": "Succesvol geregistreerd",
    "registerNotSuccessful": "Fout tijdens register",
    "none": "Geen selectie",
    "early": "Vroeg",
    "late": "Laat",
    "makeTournamentRegistration": "Registreren",
    "makeTournamentRegistrationAndPay": "Registreren en betalen",
    "makeTournamentUnRegistration": "Uitschrijven",
    "comment": "Commentaar",
    "registerTournament": "Registreren",
    "unregisterTournament": "Uitschrijven",
    "addAdditionalPlayers": "Extra Teamplayers",
    "addAdditionalFlightPlayers": "Extra spelers",
    "searchPlayer": "Lid zoeken",
    "friends": "Vrienden",
    "friendGroups": "Vriendengroepen",
    "friendsSave": "Vrienden redden",
    "addAdditionalFriends": "Vrienden toevoegen",
    "friendsNotConfirmed": "Vriendschapsverzoek",
    "friendsDeclined": "Afgewezen vriend verzoeken",
    "noTournaments": "Geen toernooien gevonden",
    "noEvents": "Geen evenementen gevonden",
    "noTeetimeCourseFound": "Geen teetime boekingskalender gevonden",
    "hole": "Gat",
    "given": "gegeven",
    "noPros": "Geen Pro's gevonden",
    "noRanges": "Geen bereik slots gevonden",
    "nextFreeSlot": "De volgende gratis slot van vandaag",
    "booked": "geboekt",
    "precedingHours": "Voorgaande uren om een slot te boeken",
    "precedingStornoMinutes": "Minuten tot een boeking kan worden verwijderd",
    "proHelp": "Help Pro",
    "proConfigs": "Pro configuraties",
    "rangeHelp": "Help Range",
    "rangeConfigs": "Bereikconfiguratie",
    "noCourses": "Geen cursussen gevonden",
    "coursename": "Golfbaan",
    "placename": "Plaats",
    "priceinfo": "Prijs info",
    "onlinecomments": "Commentaar",
    "livescorings": "Digitaal scoren",
    "noLivescorings": "Geen toernooien gevonden vandaag",
    "noEmail": "Er is geen e-mailadres beschikbaar. Neem contact op met de club om uw emailadres te laten toevoegen.",
    "notActivated": "Uw account is niet geactiveerd. Klik op de activeringslink verzenden om uw e-mail te verifiëren. Is het e-mail adres niet meer actueel? Geef vervolgens het huidige e-mailadres door aan de golfclub en herhaal de procedure.",
    "sendActivationLink": "Stuur activeringslink",
    "emailSendOK": "E-mail succesvol verzonden",
    "emailSendError": "Fout bij het verzenden van de e-mail",
    "verify": "Activeer account",
    "verifyEmailOK": "De rekening is succesvol geactiveerd.",
    "verifyEmailError": "Fout bij het activeren van de rekening",
    "reset": "Nieuw wachtwoord instellen",
    "resetPassword": "Wijzig wachtwoord",
    "passwordConfirm": "Bevestig wachtwoord",
    "password8Chars": "Ten minste 8 tekens lang",
    "password1UpperCase": "Ten minste één hoofdletter",
    "password1Number": "Ten minste één cijfer",
    "password1SpecialChar": "Ten minste één speciaal teken (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Wachtwoorden komen overeen met",
    "resetPasswordOK": "Wachtwoord succesvol gewijzigd",
    "resetPasswordError": "Fout bij veranderen wachtwoord",
    "passwordRules": "Let op. 8 karakters lang, één hoofdletter, één cijfer en één speciaal teken (-, +, _, !, @, #, $, %, ^, &, *, .)",
    "bookingDeleted": "Boeking succesvol verwijderd",
    "bookingAdded": "Boeking succesvol toegevoegd",
    "dgvnumber": "ID-nummer van 10 cijfers (uitsluitend voor leden van golfverenigingen)",
    "dgvdescription": "Registreer nu snel en gemakkelijk!",
    "dgvdescription1": "Lid van een van de drie genoemde golfverenigingen?",
    "dgvdescription2": "Selecteer vervolgens de desbetreffende kaart en voer het 10-cijferige ID-nummer in, alsmede de persoonlijke gegevens.",
    "searchClubs": "Zoek naar land/club indien u geen lid bent van een duitse, zwitserse golf of oostenrijkse club",
    "searchCountry": "Zoek land/club",
    "gender": "Geslacht",
    "male": "Mannelijk",
    "female": "Vrouw",
    "title": "Titel",
    "activationLinkSent": "Een activeringslink werd per e-mail verzonden. Controleer uw inbox en activeer uw account. De e-mail kan ook in de map met spam terecht zijn gekomen. Uw gebruikersnaam voor de login is: #",
    "cardclub": "Kaart",
    "marker": "Marker",
    "clear": "Duidelijk",
    "confirm": "Bevestig",
    "signaturePlayer": "Handtekening Speler",
    "signatureMarker": "Handtekening Marker",
    "read": "Lees",
    "gentleman": "Heren",
    "ladies": "Vrouwen",
    "missingScores": "Scores ontbreken. Bevestiging van handtekening niet mogelijk!",
    "hometeetimes": "Boek teetime",
    "homefriends": "Mijn vrienden",
    "homecard": "Mijn RFID-kaarten",
    "homepros": "Boek Pro",
    "homecourses": "Boek cursus",
    "homeranges": "Book range slot",
    "homecompetitions": "Toernooi kalender",
    "homelivescorings": "Scorekaart in app",
    "homeevents": "Evenementenkalender",
    "homeMemberdata": "Mijn gegevens",
    "homePayment": "In-App Betaling",
    "homeMember": "Bent u al lid of gast? Gebruik vervolgens alle online diensten direct nadat u bent ingelogd.",
    "homeEmail": "E-mailadres opgeslagen in de club?",
    "homeNewCustomer": "Registreer nu als nieuwe gast en maak gebruik van alle online diensten.",
    "myclub": "Mijn gegevens",
    "personInfo": "Persoonlijke informatie",
    "clubInfo": "Club Informatie",
    "addressInfo": "Adresgegevens",
    "bankInfo": "Bankgegevens",
    "authorizePayments": "Betalingen goedkeuren",
    "revokePayments": "Toestemming intrekken",
    "paypalPayment": "Paypal betaling",
    "paypalAuthorized": "U heeft met succes PayPal betaling geautoriseerd",
    "creditCardPayment": "Betaling met kredietkaart",
    "cardholderName": "Naam kaarthouder",
    "creditCardNumber": "Krediet kaart nummer",
    "expiration": "Vervaldatum",
    "accept": "Kredietkaart toevoegen",
    "creditCardAuthorized": "U heeft met succes Creditcard betaling geautoriseerd",
    "creditCardAuthorizedFailed": "Autorisatie mislukt",
    "noPaymentMethod": "Er is nog geen betalingsmethode opgeslagen. Sla gewoon in het profiel PayPal of een kredietkaart op.",
    "paymentMethodDeleted": "De betalingsmethode werd verwijderd",
    "paymentMethodCreated": "De betalingsmethode werd toegevoegd",
    "startdate": "Begindatum",
    "contractstart": "Begindatum contract",
    "contractend": "Einddatum contract",
    "enddate": "Einddatum",
    "membership": "Lidmaatschap",
    "contract": "Contract",
    "homeclubnum": "Nummer thuisclub",
    "street": "Straat",
    "city": "Stad",
    "telephone": "Telefoon",
    "mobile": "Mobiel",
    "newsletter": "Nieuwsbrief",
    "allowNewsletter": "Ingeschreven",
    "notAllowNewsletter": "Niet geabonneerd",
    "emailbill": "Stuur factuur per e-mail",
    "allowBillEmail": "Ingeschreven",
    "notAllowBillEmail": "Niet geabonneerd",
    "accountowner": "Rekeninghouder",
    "bank": "Bank",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Debet opnemen",
    "debitsOk": "Toegestaan",
    "debitsNotOk": "Niet toegestaan",
    "refNumber": "Referentienummer mandaat",
    "homeclub": "Home club",
    "credit": "Credit",
    "addFriendGroup": "Vriendengroep toevoegen",
    "addCredit": "Herlaadkrediet",
    "amount": "Bedrag",
    "recharge": "Herladen",
    "automaticRechargeBeyond": "Automatische herlading indien het krediet lager is dan dit bedrag",
    "paymenttype": "Betalingsmogelijkheid",
    "rechargeBy": "Verhoging met bedrag",
    "amountToPay": "Te betalen bedrag",
    "freeSlots": "Gratis gokkasten",
    "addAdditionalFlightFriends": "Bijkomende vrienden",
    "card": "Mijn kaart",
    "assignedCards": "Toegewezen kaarten",
    "valid": "Geldigheid",
    "noCardFound": "Geen kaart gevonden",
    "permissions": "Toestemmingen",
    "status": "Status",
    "locked": "Gesloten",
    "active": "Actief",
    "cardHistory": "Kaart geschiedenis",
    "noHistory": "Geen gegevens beschikbaar",
    "clubSelection": "Club selectie",
    "bonuspoints": "Bonuspunten.",
    "creditoncard": "Krediet op kaart",
    "getBalls": "Verzoek om ballen te krijgen",
    "getCheckIn": "Verzoek inchecken",
    "getDoor": "Verzoek deur",
    "pressButton": "Druk op de zilveren knop op de balmachine",
    "ballmachineTimerRunning": "Druk op de zilveren knop op de balmachine binnen de volgende",
    "checkInTimerRunning": "Druk op de zilveren knop op de CheckIn binnen de volgende",
    "doorTimerRunning": "Druk op de zilveren knop op de deur binnen de volgende paar minuten",
    "seconds": "Seconden",
    "appButtonError": "Fout bij het verwerken van het verzoek. Probeer het opnieuw in een paar seconden.",
    "r0000001": "Er ging iets mis bij het aanmaken van een transactie.",
    "r0000002": "U heeft een betaling aangemaakt. Druk op de zilveren knop op de lezer om de betaling te bevestigen.",
    "r0000003": "De reader wordt door andere mensen gebruikt. Wacht even en probeer het opnieuw",
    "r0000004": "Dit DGV nummer is reeds gebruikt",
    "r0000005": "Hardware niet gevonden",
    "frequency0": "",
    "frequency1": "maandelijks",
    "frequency2": "driemaandelijks",
    "frequency3": "halfjaarlijks",
    "frequency4": "jaarlijks",
    "packageAvailable": "beschikbaar",
    "generalPackage": "Ballenmand pakketten",
    "personalPackage": "Persoonlijke ballenmand pakketten",
    "noRPRs": "Geen geregistreerde particuliere rondes gevonden vandaag",
    "RPRs": "Geregistreerde particuliere rondes",
    "RPR": "Geregistreerde particuliere ronde",
    "getBooking": "Verzoek schakelaar aan",
    "news": "Golf Club Nieuws",
    "imprint": "Impressum",
    "alreadyInFlight": " is al in de flight",
    "acceptAgb": "Ik accepteer de algemene voorwaarden",
    "acceptDSGVO": "Ik accepteer de voorschriften inzake gegevensbescherming",
    "marshalls": "Marshall",
    "send": "Verzenden",
    "messageToOffice": "Bericht aan secretariaat",
    "messageSent": "Bericht verzonden",
    "statusSet": "Status werd ingesteld",
    "holes": "Gaten",
    "tee": "Tee off",
    "personalScorecard": "Persoonlijke scorekaart",
    "getScorecard": "Ga naar scorekaart",
    "length": "Meters",
    "faq": "FAQs",
    "yes": "Ja",
    "no": "Nee",
    "checkIn": "Inchecken",
    "checkInText": "Moet de tijd van vandaag op heden worden gezet?",
    "DSGVO": "Privacybeleid",
    "Agb": "Algemene Voorwaarden",
    "noNews": "Geen nieuws beschikbaar",
    "noPlayers": "Er zijn geen spelers in de groep",
    "functions": "De hieronder vermelde digitale golfervaringen worden door deze golfclub aangeboden!",
    "wantToLogout": "Ik wil graag uitloggen.",
    "canLogin": "Log nu in met het toegewezen wachtwoord en dit 10-cijferige nummer: ",
    "registerGuest": "Registratie voor gasten",
    "nickname": "Bijnaam",
    "contactClub": "Om gegevens te wijzigen, neem contact op met de club.",
    "today": "Vandaag",
    "hcpiInputHint": "Voer HCPI™ in als een getal. Gebruik een punt als scheidingsteken voor de decimale punt.",
    "noInternet": "Niet alle resultaten zijn doorgegeven. Controleer de internetverbinding!",
    "closestClub": "De dichtstbijzijnde club",
    "mobileScreen1": "Digitale golfervaringen op uw golfclub",
    "mobileScreen2": "Rangebooking",
    "mobileScreen3": "Pros & Courses",
    "mobileScreen4": "Manage Cards",

    "de": "Duits",
    "en": "Engels",
    "it": "Italiaans",
    "fr": "Frans",
    "cz": "Tsjechisch",
    "cn": "Chinees",
    "nl": "Nederlands",
    "es": "Spaans",
    "dfdae65b": "Geen gebruiker gevonden met het opgegeven emailadres",
    "71b72952": "Verkeerd wachtwoord.",
    "71b72951": "Ontbrekend e-mail adres. Neem contact op met de club!",
    "71b72953": "Fout token",
    "71b72954": "Token verlopen",
    "71b72955": "Ongeldig wachtwoord",
    "df6b0128": "Een speler van de groep mag niet boeken",
    "df6b0129": "Niet ingelogd",
    "df6b0130": "Gebruiker niet gevonden",
    "df6b0131": "Teetime is vergrendeld",
    "df6b0132": "Teetime niet gevonden",
    "df6b0133": "Boeken in het verleden",
    "df6b0134": "Boeking heeft geen configuratie",
    "df6b0135": "Reservering kan niet worden verwijderd",
    "df6b0136": "Geen plaatsen beschikbaar",
    "df6b0137": "Cursus niet gevonden",
    "df6b0138": "Registratie niet toegestaan",
    "df6b0139": "Uitschrijven niet toegestaan",
    "df6b0140": "Kalender niet gevonden",
    "df6b0141": "Eindtijd bereikt",
    "df6b0142": "Reservering voorafgaande tijd bereikt",
    "df6b0143": "Slot niet beschikbaar",
    "df6b0144": "Tijd is in het verleden",
    "df6b0145": "Max dagen bereikt",
    "df6b0146": "Verwijderingsperiode bereikt",
    "df6b0147": "Toernooi niet gevonden",
    "df6b0148": "Pro niet gevonden",
    "df6b0149": "Bereik niet gevonden",
    "df6b0150": "Teetime online betaald",
    "df6b0151": "Bereik online betaald",
    "df6b0152": "Pro online betaald",
    "19e28470": "Gebruiker bestaat al",
    "7e341661": "Interne Fout",
    "7e341662": "Inlogfout",
    "bfe57d09": "Geen gebruiker met deze login gevonden",
    "bfe57d10": "Geen e-mail voor gebruiker",
    "b4d05e56": "Er ging iets mis tijdens het uploaden van het bestand",
    "b4d05e57": "Er ging iets mis bij het verwijderen van het bestand",
    "b4d05e58": "Er ging iets mis bij het opslaan van gegevens",
    "b4d05e59": "Er ging iets mis bij het verkrijgen van gegevens",
    "b4d05e60": "Het id kaart nummer is al geregistreerd. Gelieve dit nummer te gebruiken om in te loggen",
    "b4d05e61": "Het geselecteerde land/club werd niet gevonden",
    "b4d05e62": "Een persoon met de opgegeven gegevens bestaat al in het systeem. Log in met deze gebruikers-id: #",
    "b4d05e63": "De persoon werd niet gevonden. Controleer de gegeven gegevens met de gegevens op de identiteitskaart (naam, achternaam)"
  }
}