<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ headerStr }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-img v-if="imageSrc != ''" :src="imageSrc"></ion-img>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, IonImg,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'EditBookingDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonImg
              },
  props: ['propsData'],
  setup() {
    let imageSrc = ref('')
    let headerStr = ref('')
    return {
      imageSrc, closeOutline, headerStr
    }
  },
  mounted() {
    this.imageSrc = this.propsData.imageSrc
    this.headerStr = this.propsData.headerStr

  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>