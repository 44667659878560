<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Ranges</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="ranges && ranges.length <= 0">{{ $t('noRanges') }}</h3>
      <ion-card v-for="(range, index) in ranges" :key="index">
        <ion-card-header>
          <ion-item :color="getPercentageColor(range.percentageBooked, range.numSlots)" lines="full" class="ion-no-padding" @click="$router.push( { name: 'range_date', params: {id: range.id, date: dayjs().format('YYYY-MM-DD') }} )">
            <ion-icon style="zoom: 1.0" slot="start" color="" :md="reorderTwoOutline" :ios="reorderTwoOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap clickable" color="">{{ range.name }} ({{ $t('today') }} {{ range.bookedSlots }} {{ $t('of') }} {{ range.numSlots }} {{ $t('booked') }})</ion-label>
          </ion-item>
        </ion-card-header>
        <ion-item lines="full">
          <ion-label class="ion-text-wrap" slot="start">{{ $t('nextFreeSlot') }}: {{ range.nextFreeSlot }}</ion-label>
        </ion-item>
        <ion-item lines="full">
          <ion-grid>
            <ion-row>
              <ion-col style="text-align: left;font-size: 14px;" :size="index == 5 ? 6 : 1" :size-lg="index == 5 ? 6 : 1" :size-sm="index == 5 ? 2 : 2" :size-xs="index == 5 ? 2 : 2" v-for="(col, index) in range.nextSlots" :key="index" router-direction="root" :router-link="'/range/' + range.id + '/' + dayjs(col.date).format('YYYY-MM-DD')">
                <ion-label>{{ dayjs(col.date).format('DD.MM.') }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col style="text-align: left;" :size="index == 5 ? 6 : 1" :size-lg="index == 5 ? 6 : 1" :size-sm="index == 5 ? 2 : 2" :size-xs="index == 5 ? 2 : 2" v-for="(col, index) in range.nextSlots" :key="index" router-direction="root" :router-link="'/range/' + range.id + '/' + dayjs(col.date).format('YYYY-MM-DD')">
                <ion-icon style="zoom: 1.0" :color="getPercentageColor(col.percentageBooked)" :md="calendarClearSharp" :ios="calendarClearSharp"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController } from '@ionic/vue';
import { defineComponent, watch } from 'vue';
import { personOutline, calendarClearSharp, reorderTwoOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getRangesQueryFile from '../graphql/getRanges.query.gql'
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'Ranges',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null

    const { result: getRangesResult, loading: getRangesLoading } = useQuery(getRangesQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const ranges = useResult(getRangesResult, null, data => data.getRanges.ranges)

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getRangesLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getRangesLoading, (getRangesLoading, prevLoading) => {
      if (!getRangesLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getRangesLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getRangesLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      ranges, personOutline, calendarClearSharp, dayjs, reorderTwoOutline
    }
  },
  methods: {
    getPercentageColor (percentage, numSlots) {
      let color = "successlight"
      if (numSlots == 0)
        color = "danger"
      else if (percentage == 0)
        color = "success"
      else if (percentage > 0 && percentage <= 0.25)
        color = "success"
      else if (percentage > 0.25 && percentage <= 0.5)
        color = "warninglight"
      else if (percentage > 0.5 && percentage <= 0.75)
        color = "warning"
      else if (percentage > 0.75 && percentage <= 1)
        color = "dangerlight"

      return color
    },
  }
});
</script>

<style scoped>

.clickable {
    cursor: pointer;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>