<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>News</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="news && news.length <= 0">{{ $t('noNews') }}</h3>
      <ion-card v-for="(item, index) in news" :key="index">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="newspaperOutline" :ios="newspaperOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ dayjs(item.date).format('DD.MM.YYYY HH:mm') }}: {{ item.subject }}</ion-label>
          </ion-item>
        </ion-card-header>
        <ion-card-content>
          {{ item.message }}
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonCardContent } from '@ionic/vue';
import { defineComponent, watch } from 'vue';
import { personOutline, calendarClearSharp, newspaperOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getNewsQueryFile from '../graphql/getNews.query.gql'
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'News',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCardContent,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null

    const { result: getNewsResult, loading: getNewsLoading } = useQuery(getNewsQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const news = useResult(getNewsResult, null, data => data.getNews.news)

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getNewsLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getNewsLoading, (getNewsLoading, prevLoading) => {
      if (!getNewsLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getNewsLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getNewsLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      news, personOutline, calendarClearSharp, dayjs, newspaperOutline
    }
  },
  methods: {
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>