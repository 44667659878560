<template>
  <ion-page>
      <ion-content :fullscreen="true">
        <ion-item  v-if="courseName">
          <ion-img style="width: 40px; height:40px; margin-right: 10px;" :src="logo" />
          <ion-label :style="'color: #' + courseColor + ';'" class="course">{{ courseName }}</ion-label>
          <ion-label slot="end" :style="'color: #' + courseColor + ';'" class="course">{{ dayjs().format('DD.MM.YYYY') }}</ion-label>
        </ion-item>
        <ion-card :style="'background-color: #' + courseColor + ''">
          <ion-item style="margin: 3px;">
          <ion-grid>
            <ion-row v-for="(teetime, index) in teetimesFound" :key="index">
              <ion-col class="time rows">{{ dayjs(teetime.time).format('HH:mm') }}</ion-col>
              <ion-col  v-for="(player, index) in teetime.players" :key="index" class="rows">
                <ion-card :color="teetime.lock ? 'warning' : (player.name == '' ? 'success' : 'primary')" class="playercard ion-no-padding">
                  <ion-card-content class="ion-no-margin ion-no-padding player">{{ player.name }}<br>{{ player.club }}<br>&nbsp;</ion-card-content>
                
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
          </ion-item>
        </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import findTeetimesQueryFile from '../graphql/findTeetimes.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import { timeOutline } from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const { result: findTeetimesResult } = useQuery(findTeetimesQueryFile, () => ({ id: parseInt(route.params.id) }), () => ({ fetchPolicy: 'no-cache', pollInterval: 21000 }))
    const teetimesFound = useResult(findTeetimesResult, null, data => data.findTeetimes.teetimes)
    const courseName = useResult(findTeetimesResult, null, data => data.findTeetimes.coursename)
    const courseColor = useResult(findTeetimesResult, null, data => data.findTeetimes.coursecolor)
    const logo = useResult(findTeetimesResult, null, data => data.findTeetimes.logo)
    const idToUse = useResult(findTeetimesResult, null, data => data.findTeetimes.id)

    watch(idToUse, (newVal, prevVal) => {
      if (newVal != route.params.id)
          setTimeout(() => { router.push('/infoscreen/' + newVal) }, 20000)
    })

    return {
      timeOutline, teetimesFound, dayjs, courseName, courseColor, logo
    }
  },
});
</script>

<style scoped>

.card {
  background-color: rgb(75, 59, 219); 
}

.playercard {
   height: 80px;
}

.player {
   padding: 5px !important;
}

ion-col.rows {
   margin: 0px;
   padding: 0px;
   height: 100px;
   font-weight: bold;
   border-bottom: 1px solid grey;
   vertical-align: center;
}

ion-col.header {
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    vertical-align: center;
  }

ion-col.time {
    font-size: 24px; 
    font-weight: bold;
  }

.course {
    font-size: 24px; 
    font-weight: bold;
  }

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>