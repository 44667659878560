<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>{{ $t('login') }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <div v-if="errorMessage != ''">
        <ion-label v-if="emailMask != ''" class="ion-text-wrap" color="danger">{{ $t(errorMessage) }} ({{ $t(emailMask) }})</ion-label>
        <ion-label v-else class="ion-text-wrap" color="danger">{{ $t(errorMessage) }}</ion-label>
        <!-- <ion-input v-if="errorMessage == 'noEmail'" :placeholder="$t('email')" type="email" v-model="connectEmail"></ion-input>-->
      </div>
        <ion-button position="stacked" v-if="connectEmail && connectEmail != '' && connectEmail.includes('@') && connectEmail.includes('.')" @click="requestEmailVerification(connectEmail)" expand="block" color="primary">{{ $t('sendActivationLink') }}</ion-button>
        <ion-button position="stacked" v-if="errorMessage == 'notActivated'" @click="requestEmailVerification(emailClub)" expand="block" color="primary">{{ $t('sendActivationLink') }}</ion-button>
      <ion-item>
        <ion-label position="stacked">{{ $t('username') }}</ion-label>
        <ion-input placeholder="9008000001" type="email" ref="focusMe" v-model="email"></ion-input>
      </ion-item>
        <ion-item>
        <ion-label position="stacked">{{ $t('password') }}</ion-label>
        <ion-input placeholder="" type="password" v-model="password"></ion-input>
      </ion-item>
      <ion-button @click="loginUser()" expand="block" color="success">{{ $t('login') }}</ion-button>
      <ion-button v-if="email != ''" @click="requestPasswordReset()" expand="block" color="primary">{{ $t('passwordReset') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import loginUserMutationFile from '../graphql/loginUser.mutation.gql'
import requestPasswordResetMutationFile from '../graphql/requestPasswordReset.mutation.gql'
import requestEmailVerificationMutationFile from '../graphql/requestEmailVerification.mutation.gql'
import Storage from "../plugins/storage.js";

export default defineComponent({
  name: 'LoginDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  setup() {
    const errorMessage = ref('')
    const emailMask = ref('')
    const emailClub = ref('')
    const connectEmail = ref('')
    const id = ref(0)
    const { mutate: loginUserMutation } = useMutation(loginUserMutationFile, { errorPolicy: 'all'});
    const { mutate: requestPasswordResetMutation } = useMutation(requestPasswordResetMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: requestEmailVerificationMutation } = useMutation(requestEmailVerificationMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      loginUserMutation, requestEmailVerificationMutation,
      requestPasswordResetMutation, errorMessage, emailMask, emailClub, id, connectEmail
    }
  },
  async mounted() {
      setTimeout(() => {
        this.$refs.focusMe.$el.setFocus();
      }, 500);
  },
  methods: {
    closeDialog(value) {
      return modalController.dismiss(value)
    },
    async sendEmailActivation () {

    },
    async loginUser() {
      await this.loginUserMutation({ email: this.email, password: this.password })
      .then(async res => {
        if (res.data.loginUser) {
          if (!res.data.loginUser.success && res.data.loginUser.errorCode == 1) {
            this.errorMessage = 'noEmail'
            this.id = res.data.loginUser.id
          }
          else if (!res.data.loginUser.success && res.data.loginUser.errorCode == 2) {
            this.errorMessage = 'notActivated'
            this.emailMask = res.data.loginUser.emailmask
            this.emailClub = res.data.loginUser.email
            this.id = res.data.loginUser.id
          }
          else {
            await Storage.setItem('accessToken', res.data.loginUser.accessToken);
            await Storage.setItem('refreshToken', res.data.loginUser.refreshToken);
            this.$store.dispatch('login')
            this.closeDialog(true)
          }
        }
      })
      .catch(err => {
        console.log(err)
      }) 
    },
    requestPasswordReset() {
      this.requestPasswordResetMutation({ email: this.email, language: this.$store.getters.language })
      .then(res => {
        if (res && res.data && res.data.requestPasswordReset && res.data.requestPasswordReset.success) {
          toastController
            .create({
              message: this.$t('emailSendOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          toastController
            .create({
              message: this.$t('emailSendError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('emailSendError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    requestEmailVerification(email) {
      this.requestEmailVerificationMutation({ id: this.id, email: email, language: this.$store.getters.language })
      .then(res => {
        if (res && res.data && res.data.requestEmailVerification && res.data.requestEmailVerification.success) {
          this.connectEmail = ''
          this.errorMessage = ''
          toastController
            .create({
              message: this.$t('emailSendOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          toastController
            .create({
              message: this.$t('emailSendError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
        toastController
          .create({
            message: this.$t('emailSendOK'),
            duration: 3000,
            color: 'success'
          }).then(res => res.present())
      }) 
    },
  }
});
</script>