module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Mein Club",
    "pros": "Pros",
    "persons": "Personen",
    "competitions": "Turniere",
    "competition": "Turnier",
    "competitionUC": "Turnier",
    "bookings": "Buchungen",
    "statistics": "Statistiken",
    "settings": "Einstellungen",
    "profile": "Profil",
    "login": "Einloggen",
    "logout": "Ausloggen",
    "organizations-list": "Meine Organisationen",
    "companies": "Clubs",
    "company": "Club",
    "searchcompanies": "Club suchen",
    "searchresult": "Suchergebnis",
    "history": "Kürzlich",
    "register": "Registrieren",
    "save": "Speichern",
    "userData": "Benutzerdaten",
    "changePassword": "Kennwort ändern",
    "changeEmail": "Email ändern",
    "language": "Sprache",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "birthdate": "Geburtstag",
    "password": "Kennwort",
    "email": "Email",
    "profilePicture": "Profilbild",
    "takePicture": "Bild auswählen",
    "upload": "Bild speichern",
    "passwordReset": "Kennwort ändern Email senden",
    "events": "Veranstaltungen",
    "event": "Veranstaltung",
    "eventUC": "Veranstaltung",
    "loading": "Daten werden geladen ...",
    "newcompanies": "Betreiber werden",
    "erroLogin": "Bitte melde dich für diesen Vorgang an!",
    "newCategory": "Neue Kategorie",
    "description": "Beschreibung",
    "changeSuccessful": "Änderung erfolgreich durchgeführt",
    "changeNotSuccessful": "Bei der Änderung ist ein Fehler aufgetreten",
    "deleteSuccessful": "Löschen erfolgreich durchgeführt",
    "deleteNotSuccessful": "Beim Löschen ist ein Fehler aufgetreten",
    "addSuccessful": "Anlegen erfolgreich durchgeführt",
    "addNotSuccessful": "Beim Anlegen ist ein Fehler aufgetreten",
    "notSuccessful": "Es ist ein Fehler aufgetreten",
    "name": "Name",
    "shortName": "Name kurz",
    "priority": "Reihenfolge",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "ok": "Ok",
    "bookingDuration": "Buchungsdauer",
    "showAvailableSlots": "Verfügbare Slots anzeigen",
    "showBookedSlots": "Gebuchte Slots anzeigen",
    "withSlider": "Slider anzeigen",
    "intervallBetweenBookings": "Zeitraum zwischen zwei Buchungen",
    "maxBookingsInFuture": "Max. Buchungen",
    "maxBookingsPerDay": "Max. Buchungen pro Tag",
    "maxBookingsPerWeek": "Max. Buchungen pro Woche",
    "maxPersonsBookable": "Max. Anzahl buchbarer Slots",
    "minPersonsBookable": "Min. Anzahl buchbarer Slots",
    "newBookingType": "Neuer Buchungstyp",
    "from": "Zeitraum von",
    "to": "Zeitraum bis",
    "cart": "Warenkorb",
    "teetimes": "Startzeiten",
    "pro": "Trainer",
    "courses": "Kurse",
    "course": "Kurs",
    "ranges": "Rangeplätze",
    "available": "",
    "sperrungnoshow": "Sperrung",
    "showAvatar": "Bild in Buchungen anzeigen",
    "withDog": "Mit Hund",
    "with9Hole": "Plant 9 Löcher zu spielen",
    "withPlayWithMe": "Gerne zu mir hinzubuchen",
    "member": "Mitglied",
    "memberFemale": "Mitglied weiblich",
    "memberMale": "Mitglied männlich",
    "guest": "Gast",
    "guestFemale": "Gast weiblich",
    "guestMale": "Gast männlich",
    "displayOptions": "Anzeigeoptionen",
    "showName": "Namen anzeigen",
    "showHCP": "Handicap anzeigen",
    "showGender": "Geschlecht anzeigen",
    "all": "Alle",
    "min1": "Mind. 1 Platz frei",
    "min2": "Mind. 2 Plätze frei",
    "min3": "Mind. 3 Plätze frei",
    "min4": "Mind. 4 Plätze frei",
    "hcp": "Handicap",
    "syncHCP": "Handicap abgleichen",
    "hcpSyncOK": "Handicapabgleich erfolgreich",
    "hcpErrorSync": "Handicapabgleich fehlgeschlagen",
    "bookingTeetimeHelp": "Hilfe Startzeitbuchung",
    "myBooking": "Meine Buchung",
    "monday": "Mo",
    "tuesday": "Di",
    "wednesday": "Mi",
    "thursday": "Do",
    "friday": "Fr",
    "saturday": "Sa",
    "sunday": "So",
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
    "Sunday": "Sonntag",
    "makeBooking": "Startzeit buchen",
    "makeBookingAndPay": "Startzeit buchen und bezahlen",
    "makeProBooking": "Pro-Stunde buchen",
    "makeAndPayProBooking": "Pro-Stunde buchen und bezahlen",
    "makeRangeBooking": "Rangeplatz buchen",
    "makeAndPayRangeBooking": "Rangeplatz buchen und bezahlen",
    "maxDays": "Max. Tage buchbar",
    "timeSwitch": "Uhrzeit Freischaltung weitere Tag",
    "allowAlwaysBookingHours": "Stunden frei buchbar",
    "courseConfigs": "Anlagen-Konfiguration",
    "unlimited": "Keine Einschränkung",
    "myCourseData": "Meine Daten",
    "myFutureBookings": "Alle Buchungen",
    "myBookingToday": "Buchungen dieser Tag",
    "myBookingWeek": "Buchungen diese Woche",
    "bookingCourseHelp": "Hilfe Anlagen Konfiguration",
    "bookingCourseHelp1": "Diese Konfigurationen wurden vom Golfclub hinterlegt.",
    "openFriends": "Freunde konfigurieren",
    "editBooking": "Meine Buchung",
    "@me": "Mich",
    "player": "Spieler",
    "username": "Ausweisnummer oder Nickname",
    "announcement" : "Ausschreibung",
    "participants" : "Teilnehmer",
    "of" : "von",
    "round" : "Runde",
    "guests": "Gäste",
    "webAvailable": "Im Web verfügbar",
    "PF0": "Einzel",
    "PF1": "Aggregat Vierer",
    "PF2": "Vierer",
    "PF3": "Vierer Auswahldrive",
    "PF4": "Chapman Vierer",
    "PF5": "Ryesome Vierer",
    "PF6": "Vierball",
    "PF7": "Vierball Bestball",
    "PF8": "Scramble",
    "PF9": "Scramble 2er",
    "PF10": "Scramble Texas",
    "PF11": "Scramble Florida",
    "PF12": "RPR",
    "SM0": "Zählspiel",
    "SM1": "Stableford",
    "SM2": "Gegen Par",
    "SM3": "Lochwettspiel",
    "SM4": "Zählspiel und Stableford",
    "SM5": "Gemischt",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Zählspiel Höchstergebnis",
    "W0": "18-Loch",
    "W1": "9-Loch",
    "HCPREL0": "Nicht HCPI™-Relevant",
    "HCPREL1": "HCPI™-Relevant",
    "regPeriod": "Registrierungs-Zeitraum",
    "hcpi": "HCPI™",
    "toStr": "bis",
    "timerRunning": "Solange wartet die Startzeit noch...",
    "timerStopped": "Deine Startzeit steht nun auch anderen Kunden zur Verfügung.",
    "teetimeLocked": "Startzeit ist blockiert",
    "tournamentRegisterError1": "Nicht eingeloggt",
    "tournamentRegisterError2": "Geschlecht nicht passen",
    "tournamentRegisterError3": "Handicap nicht passend",
    "tournamentRegisterError4": "Registrierungszeitraum nicht passend",
    "tournamentRegisterError5": "Teilnehmerliste ist voll",
    "tournamentRegisterError6": "Altersklasse nicht passend",
    "tournamentRegisterError7": "Für # angemeldet",
    "tournamentUnRegisterError1": "Nicht eingeloggt",
    "tournamentUnRegisterError2": "Abmelden nicht erlaubt",
    "tournamentUnRegisterError3": "Abmeldedatum überschritten",
    "tournamentUnRegisterError4": "Nicht bei # angemeldet",
    "tournamentUnRegisterError5": "Aufgrund online Bezahlung ist keine Abmeldung möglich",
    "helpCoursesTime": "Kurs Datum, Start und Ende",
    "helpCoursesEnter": "Für Kurs anmelden",
    "helpCoursesUnregister": "Vom Kurs abmelden",
    "helpCoursesSeries" : "Kursserie",
    "helpCourses": "Hilfe Kurse",
    "helpTournamentTime": "Turnier Datum, Start und Ende",
    "helpTournamentDown": "Details aufklappen",
    "helpTournamentUp": "Details schließen",
    "helpTournamentEnter": "Für Turnier anmelden",
    "helpTournamentEnterWaitlist": "Für Turnierwarteliste anmelden",
    "helpTournamentUnregister": "Vom Turnier abmelden",
    "helpTournamentGenderFemale": "Turnier für Frauen zugelassen",
    "helpTournamentGenderMale": "Turnier für Männer zugelassen",
    "helpTournamentML": "Meldeliste",
    "helpTournamentSL": "Startliste",
    "helpTournamentEL": "Ergebnisliste",
    "helpTournamentAnnouncment": "Auschreibung",
    "helpTournament": "Hilfe Turniere",
    "helpEventTime": "Veranstaltung Datum, Start und Ende",
    "helpEventDown": "Details aufklappen",
    "helpEventUp": "Details schließen",
    "helpEventEnter": "Für Veranstaltung anmelden",
    "helpEventEnterWaitlist": "Für Veranstaltungwarteliste anmelden",
    "helpEventUnregister": "Von Veranstaltung abmelden",
    "helpEventGenderFemale": "Veranstaltung für Frauen zugelassen",
    "helpEventGenderMale": "Veranstaltung für Männer zugelassen",
    "helpEventAnnouncment": "Beschreibung",
    "helpEvent": "Hilfe Veranstaltungen",
    "helpRoundSL": "Startliste",
    "unregister": "Abmelden von",
    "unregisterSuccessful": "Erfolgreich abgemeldet",
    "unregisterNotSuccessful": "Bei der Abmeldung ist ein Fehler aufgetreten",
    "registerSuccessful": "Erfolgreich angemeldet",
    "registerNotSuccessful": "Bei der Anmeldung ist ein Fehler aufgetreten",
    "none": "Keine Auswahl",
    "early": "Früh",
    "late": "Spät",
    "makeTournamentRegistration": "Anmelden",
    "makeTournamentRegistrationAndPay": "Anmelden und Bezahlen",
    "makeTournamentUnRegistration": "Abmelden",
    "comment": "Kommentar",
    "registerTournament": "Anmeldung",
    "unregisterTournament": "Abmeldung",
    "addAdditionalPlayers": "Zusätzliche Teamspieler",
    "addAdditionalFlightPlayers": "Zusätzliche Spieler",
    "searchPlayer": "Mitglied suchen",
    "friends": "Freunde",
    "friendGroups": "Freundegruppen",
    "friendsSave": "Freunde speichern",
    "addAdditionalFriends": "Freunde hinzufügen",
    "friendsNotConfirmed": "Freundschaftsanfrage",
    "friendsDeclined": "Abgelehnte Freundschaftsanfragen",
    "noTournaments": "Keine Turniere gefunden",
    "noEvents": "Keine Veranstaltungen gefunden",
    "noTeetimeCourseFound": "Keine Startzeitbuchungskalender gefunden",
    "hole": "Loch",
    "given": "gegeben",
    "noPros": "Keine Pros gefunden",
    "noRanges": "Keine Rangeplätze gefunden",
    "nextFreeSlot": "Heute nächster freier Platz",
    "booked": "gebucht",
    "precedingHours": "Stunden innerhalb dem ein Slot nicht mehr buchbar ist",
    "precedingStornoMinutes": "Minuten bis Buchung noch löschbar ist",
    "proHelp": "Hilfe Pro",
    "proConfigs": "Pro Konfigurationen",
    "rangeHelp": "Hilfe Range",
    "rangeConfigs": "Range Konfigurationen",
    "noCourses": "Keine Kurse gefunden",
    "coursename": "Platz",
    "placename": "Ort",
    "priceinfo": "Preisinfo",
    "onlinecomments": "Kommentar",
    "livescorings": "Digitales Scoring",
    "noLivescorings": "Für heute keine Turnier gefunden",
    "noEmail": "Es ist keine E-Mailadresse vorhanden. Bitte den Club kontaktieren damit Ihre E-Mailadresse hinterlegt wird.",
    "notActivated": "Das Konto ist noch nicht aktiviert. Bitte auf Aktivierungslink senden klicken, um das Konto zu aktivieren. Ist die E-Mailadresse nicht mehr aktuell? Dann bitte dem Golfclub die aktuelle E-Mailadresse mitteilen und den Vorgang wiederholen.",
    "sendActivationLink": "Aktivierungslink senden",
    "emailSendOK": "E-Mail wurde erfolgreich versendet",
    "emailSendError": "Beim E-Mail versenden ist ein Fehler aufgetreten",
    "verify": "Konto aktivieren",
    "verifyEmailOK": "Das Konto wurde erfolgreich aktiviert.",
    "verifyEmailError": "Beim Aktivieren des Kontos ist ein Fehler aufgetreten",
    "reset": "Neues Kennwort vergeben",
    "resetPassword": "Kennwort ändern",
    "passwordConfirm": "Kennwort bestätigen",
    "password8Chars": "Mindestens 8 Zeichen lang",
    "password1UpperCase": "Mindestens 1 Großbuchstabe",
    "password1Number": "Mindestens eine Zahl",
    "password1SpecialChar": "Mindestens ein Sonderzeichen (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Kennwörter stimmen überein",
    "resetPasswordOK": "Kenwort erfolgreich geändert",
    "resetPasswordError": "Fehler beim Ändern des Kennworts",
    "passwordRules": "Mind. 8 Zeichen lang, ein Großbuchstabe, eine Zahl und ein Sonderzeichen (-, +, _, !, @, #, $, %, ^, &, *, .)",
    "bookingDeleted": "Die Buchung wurde gelöscht",
    "bookingAdded": "Die Buchung wurde hinzugefügt",
    "dgvnumber": "10-stellige Ausweisnummer (ausschließlich für Mitglieder von Golfverbänden)",
    "dgvdescription": "Jetzt ganz einfach und schnell registrieren!",
    "dgvdescription1": "Mitglied in einem der drei angeführten Golfverbände?",
    "dgvdescription2": "Dann bitte die jeweilige Karte auswählen und die 10-stellige Ausweisnummer, sowie die persönlichen Daten eintragen.",
    "searchCountry": "Land/Club suchen",
    "gender": "Geschlecht",
    "male": "Männlich",
    "female": "Weiblich",
    "title": "Titel",
    "activationLinkSent": "Es wurde ein Aktivierungslink per E-Mail versendet. Bitte Posteingang überprüfen und den Account aktivieren. Die E-Mail kann auch im Spam-Order gelandet sein. Ihr Benutzerlogin für den Login lautet: #",
    "cardclub": "Karte",
    "May": "Mai",
    "Dec": "Dez",
    "marker": "Zähler",
    "clear": "Löschen",
    "confirm": "Bestätigen",
    "signaturePlayer": "Unterschrift Spieler",
    "signatureMarker": "Unterschrift Zähler",
    "read": "Vorlesen",
    "gentleman": "Herren",
    "ladies": "Damen",
    "missingScores": "Es fehlen Ergebnisse in der Scorekarte. Bestätigen der Unterschrift nicht möglich!",
    "hometeetimes":"Startzeit buchen",
    "homefriends":"Meine Freunde",
    "homecard":"Meine Funkkarten",
    "homepros":"Pro buchen",
    "homecourses":"Kurs buchen",
    "homeranges":"Rangeplatz buchen",
    "homecompetitions":"Turnierkalender",
    "homelivescorings":"Scorekarte in der App",
    "homeevents":"Veranstaltungskalender",
    "homeMemberdata":"Meine Daten",
    "homePayment":"In App Bezahlung",
    "homeMember":"Bereits Mitglied oder Gast? Dann direkt nach dem Login alle Online Services nutzen.",
    "homeEmail":"Email Adresse im Club hinterlegt?",
    "homeNewCustomer":"Jetzt als neuer Gast registrieren und alle Online Services nutzen.",
    "myclub":"Meine Daten",
    "personInfo": "Persönliche Informationen",
    "clubInfo": "Club Informationen",
    "addressInfo": "Adress Informationen",
    "bankInfo": "Bank Informationen",
    "authorizePayments": "Zahlungen autorisieren",
    "revokePayments": "Autorisierung löschen",
    "paypalPayment": "Paypal Zahlung",
    "paypalAuthorized":"Sie haben Paypalzahlung erfolgreich autorisiert",
    "creditCardPayment": "Kreditkarten Zahlung",
    "cardholderName": "Name des Karteninhabers",
    "creditCardNumber": "Kreditkartennummer",
    "expiration": "Ablauf",
    "accept": "Kreditkarte hinzufügen",
    "creditCardAuthorized":"Sie haben Kreditkartenzahlung erfolgreich autorisiert",
    "creditCardAuthorizedFailed":"Autorisierung fehlgeschlagen",
    "noPaymentMethod": "Es ist noch keine Bezahlmethode hinterlegt. Einfach im Profil Paypal oder eine Kreditkarte hinterlegen.",
    "paymentMethodDeleted": "Die Bezahlmethode wurde gelöscht",
    "paymentMethodCreated": "Die Bezahlmethode wurde erfolgreich hinterlegt",
    "startdate": "Eintritt",
    "contractstart": "Vertragsbeginn",
    "contractend": "Vertragsende",
    "enddate": "Austritt",
    "membership": "Mitgliedschaft",
    "contract": "Vertrag",
    "homeclubnum": "Heimatclubnummer",
    "street": "Strasse",
    "city": "Ort",
    "telephone": "Telefon",
    "mobile": "Mobil",
    "newsletter": "Newsletter",
    "allowNewsletter": "Abonniert",
    "notAllowNewsletter": "Nicht abonniert",
    "emailbill": "Rechnung per Email",
    "allowBillEmail": "Abonniert",
    "notAllowBillEmail": "Nicht abonniert",
    "accountowner": "Kontoinhaber",
    "bank": "Bank",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Lastschrifteinzug",
    "debitsOk": "Erlaubt",
    "debitsNotOk": "Nicht erlaubt",
    "refNumber": "Mandatsreferenznummer",
    "homeclub": "Heimatclub",
    "credit": "Guthaben",
    "addFriendGroup": "Freundegruppe hinzufügen",
    "addCredit": "Guthaben aufladen",
    "amount": "Betrag",
    "recharge": "Aufladen",
    "automaticRechargeBeyond": "Automatisch Aufladen wenn Guthaben diesen Betrag unterschreitet",
    "paymenttype": "Bezahloption",
    "rechargeBy": "Erhöhen um Betrag",
    "amountToPay": "Zu zahlender Betrag",
    "freeSlots": "Freie Plätze",
    "addAdditionalFlightFriends": "Zusätzliche Freunde",
    "card": "Meine Karte",
    "assignedCards": "Zugeordnete Karten",
    "valid": "Gültigkeit",
    "noCardFound": "Kein Karte gefunden",
    "permissions": "Berechtigungen",
    "status": "Status",
    "locked": "Gesperrt",
    "active": "Aktiv",
    "cardHistory": "Kartenprotokoll",
    "noHistory" : "Keine Daten vorhanden",
    "clubSelection": "Zur Clubauswahl",
    "bonuspoints": "Sonderpunkte",
    "creditoncard": "Guthaben auf der Karte",
    "getBalls": "Bälle ausgeben anfordern",
    "getCheckIn": "Check-In anfordern",
    "getDoor": "Türe anfordern",
    "pressButton": "Den silbernen Knopf am Ballautomaten drücken",
    "ballmachineTimerRunning": "Den silbernen Knopf am Ballautomaten drücken innerhalb der nächsten ",
    "checkInTimerRunning": "Den silbernen Knopf am CheckIn drücken innerhalb der nächsten ",
    "doorTimerRunning": " Den silbernen Knopf an der Türe drücken innerhalb der nächsten ",
    "seconds": "Sekunden",
    "appButtonError": "Bei der Anforderung ist ein Fehler aufgetreten. Bitte in ein paar Sekunden noch einmal probieren.",
    "r0000001": "Fehler für diese Transaktion aufgetreten",
    "r0000002": "Bezahlung wurde erstellt. Bitte den silbernen Knopf drücken.",
    "r0000003": "Der Leser wird von einer anderen Person benutzt. Bitte einen Moment warten",
    "r0000004": "Die DGV Nummer wurde schon benutzt",
    "r0000005": "Leser wurde nicht gefunden",
    "frequency0": "",
    "frequency1": "Monatlich",
    "frequency2": "Quartal",
    "frequency3": "Halbjährlich",
    "frequency4": "Jährlich",
    "packageAvailable": "verfügbar",
    "generalPackage": "Ballkorb Pakete",
    "personalPackage": "Persönliches Ballkorb Pakete",
    "noRPRs": "Für heute keine registrierten privaten Runden gefunden",
    "RPRs": "Rregistrierte private Runden",
    "RPR": "Rregistrierte private Runde",
    "getBooking": "Einschalten anfordern",
    "news": "Golfclub-News",
    "imprint": "Impressum",
    "alreadyInFlight": " ist bereits in der Spielgruppe",
    "acceptAgb": "Ich akzeptiere die allgemeinen Geschäftsbedingungen",
    "acceptDSGVO": "Ich akzeptiere die Datenschutzbestimmungen",
    "agb": "Allgemeinen Geschäftsbedingungen",
    "datalegal": "Datenschutzbestimmungen",
    "marshalls": "Marshall",
    "send": "Senden",
    "messageToOffice": "Nachricht an Sekreteriat",
    "messageSent": "Nachricht verschickt",
    "statusSet": "Status wurde gesetzt",
    "holes": "Löcher",
    "tee": "Abschlag",
    "personalScorecard": "Persönliche Scorekarte",
    "getScorecard": "Zur Scorekarte",
    "length": "Meter",
    "faq": "FAQs",
    "yes": "Ja",
    "no": "Nein",
    "checkIn": "Einchecken",
    "checkInText": "Sollen die heutigen Startzeiten auf anwesend gesetzt werden?",
    "DSGVO": "Datenschutzbestimmungen",
    "Agb": "Allgemeinen Geschäftsbedingungen",
    "noNews": "Keine Neuigkeiten vorhanden",
    "isInclusion": "Behindertengerecht",
    "ranking": "Rangliste",
    "noPlayers": "Es sind keine Spieler in der Gruppe",
    "addPerson": "Spieler hinzufügen",
    "functions": "Die unten aufgeführten digitalen Golferlebnisse stellt Ihnen dieser Golfclub zur Verfügung!",
    "wantToLogout": "Ich möchte mich ausloggen.",
    "canLogin": "Jetzt mit dem vergebenen Kennwort und dieser 10-stelligen Nummer einloggen: ",
    "registerGuest": "Registrierung für Gäste",
    "nickname": "Nickname",
    "contactClub": "Zum Ändern von Daten bitte den Club kontaktieren.",
    "today": "Heute",
    "hcpiInputHint": "HCPI™ als Zahl eingeben. Als Trennzeichen für die Kommastelle eine Punkte verwenden.",
    "noInternet": "Es sind nicht alle Ergebnisse übertragen. Bitte die Internetverbindung prüfen!",
    "closestClub": "Der nächstgelegene Club",
    "mobileScreen1": "Digitale Golferlebnisse in Ihrem Golfclub",
    "mobileScreen2": "Rangebuchung",
    "mobileScreen3": "Pros & Kurse",
    "mobileScreen4": "Karten verwalten",
    "mobileScreen5": "Ballguthaben aufladen",

    // FAQ    
    "FAQ": "Haben Sie noch Fragen? Dann werfen Sie doch einen Blick auf unsere FAQs.",
    "faq0000001": "Was kann ich mit CLUB IN ONE - 4U machen?",
    "faq0000001-a": "Sie können damit Ihre Buchungen rund um dem Golfsport in Ihrem Golfclub durchführen. Weiter stehen Livescoring Funktionen für Turnier zur Verfügung. in IHREM Kundenportal erhalten sie Information über ihre hinterlegten Daten und werden vom Golfclub über aktuelle News informiert. Falls der Golfclub die CLUB IN ONE Reader für Ballautomaten oder Türen besitzt können diese per CLUB IN ONE 4U - App (i-Phone, Android) bedient werden.",
    "faq0000002": "Wo ist CLUB IN ONE - 4U verfügbar?",
    "faq0000002-a": "CLUB IN ONE - 4U ist auf den Golfplätzen verfügbar welche die Clubverwaltungs-Software CLUB IN ONE einsetzen.",
    "faq0000003": "Wie erkenne ich, ob CLUB IN ONE - 4U verfügbar ist?",
    "faq0000003-a": "Sie finden einen Link auf der Webseite des Golfclubs oder finden Clubs in der CLUB IN ONE 4U - App (iPhone und Android) in der Suchfunktion.",
    "faq0000004": "Wie kann ich CLUB IN ONE - 4U verwenden?",
    "faq0000004-a": "Haben sie im Club bereits eine EMail Adresse hinterlegt und sind Mitglied oder haben schon einen Gast-Login für die Startzeitbuchung ihres Clubs, können sie die 10-stellige Ausweisnummer zur Aktivierung bzw. dem Login verwenden. Natürlich können sie alle Kalender auch ohne Login einsehen.",
    
    // lnguages
    "de": "Deutsch",
    "en": "Englisch",
    "it": "Italienisch",
    "fr": "Französisch",
    "cz": "Tschechisch",
    "cn": "Chinesisch",
    "nl": "Niederländisch",
    "es": "Spanisch",

    // Errors
    "dfdae65b": "Kein Benutzer mit der Email-Adresse gefunden",
    "71b72952": "Kennwort falsch",
    "71b72951": "Email-Adresse nicht hinterlegt. Bitte wenden sie sich an den Club!",
    "71b72953": "Token fehlerhaft",
    "71b72954": "Token abgelaufen",
    "71b72955": "Ungültiges Kennwort",
    "df6b0128": "Einem Spieler aus der Gruppe ist es nicht erlaubt zu buchen",
    "df6b0129": "Nicht angemeldet",
    "df6b0130": "Benutzer nicht gefunden",
    "df6b0131": "Startzeit ist blockiert",
    "df6b0132": "Startzeit nicht gefunden",
    "df6b0133": "Buchung ist in der Vergangenheit",
    "df6b0134": "Buchung ist nicht konfiguriert",
    "df6b0135": "Buchung kann nicht gelöscht werden",
    "df6b0136": "Keine Slot verfügbar",
    "df6b0137": "Kurs nicht gefunden",
    "df6b0138": "Registrierung nicht erlaubt",
    "df6b0139": "Abmeldung nicht erlaubt",
    "df6b0140": "Kalender nicht gefunden",
    "df6b0141": "Endzeit ist erreicht",
    "df6b0142": "Buchung löschen Zeitfenster erreicht",
    "df6b0143": "Slot nicht verfügbar",
    "df6b0144": "Zeit ist in der Vergangenheit",
    "df6b0145": "Max. Anzahl Tage erreicht",
    "df6b0146": "Löschzeitraum erreicht",
    "df6b0147": "Turnier nicht gefunden",
    "df6b0148": "Pro nicht gefunden",
    "df6b0149": "Range nicht gefunden",
    "df6b0150": "Startzeit wurde online bezahlt",
    "df6b0151": "Range wurde online bezahlt",
    "df6b0152": "Pro wurde online bezahlt",
    "19e28470": "Benutzer existiert schon",
    "7e341661": "Internal Error",
    "7e341662": "Login Fehler",
    "bfe57d09": "Kein Benutzer mit diesem Login gefunden",
    "bfe57d10": "Kein E-Mail für diesen Benutzer",
    "b4d05e56": "Fehler beim Datei Upload",
    "b4d05e57": "Fehler beim Datei löschen",
    "b4d05e58": "Fehler beim Daten speichern",
    "b4d05e59": "Fehler beim Daten selektieren",
    "b4d05e60": "Die Ausweisnummer ist schon registriert. Bitte mit dieser Nummer einloggen",
    "b4d05e61": "Das ausgewähle Land/Club wurde nicht gefunden",
    "b4d05e62": "Es gibt schon eine Person mit diesen Angaben im System. Melde dich mit diesem Benutzer an: #",
    "b4d05e63": "Die Person konnte nicht gefunden werden. Bitte überprüfe Deine Angaben mit denen auf dem Ausweis (Name, Nachname)",
  }
}