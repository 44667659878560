<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('rangeHelp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
          <ion-icon :md="squareSharp" color="success" :ios="squareSharp" style="zoom: 1.0"></ion-icon>
          <ion-label>&nbsp;{{ $t("myBooking") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="downloadOutline" color="success" :ios="downloadOutline" style="zoom: 1.0"></ion-icon>
          <ion-label>&nbsp;{{ $t("makeRangeBooking") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="openOutline" color="primary" :ios="openOutline" style="zoom: 1.0"></ion-icon>
          <ion-label>&nbsp;{{ $t("editBooking") }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, 
          personAddOutline,  peopleOutline, openOutline, manSharp, womanSharp, downloadOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'HelpBookingTeetimeDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider },
  setup() {
    return {
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline,
       peopleOutline, openOutline, manSharp, womanSharp, downloadOutline
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>