<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Verify</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section>
      <ion-card>
        <ion-list v-if="!verifyOk" lines="full">
          <ion-item>
            <ion-label  position="stacked" class="ion-text-wrap" color="primary">{{ $t('password') }}</ion-label>
            <ion-input  :placeholder="$t('password')" type="password" v-model="password"></ion-input>
          </ion-item>
          <ion-item>
            <ul style="list-style-type: none;">
              <li>          
                <ion-icon color="danger" v-if="!isMinLength8" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isMinLength8" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password8Chars') }}</small>
              </li>
              <li>          
                <ion-icon color="danger" v-if="!isContainsUppercase" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isContainsUppercase" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1UpperCase') }}</small>
              </li>
              <li>          
                <ion-icon color="danger" v-if="!isContainsNumber" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isContainsNumber" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1Number') }}</small>
              </li>
              <li>
                <ion-icon color="danger" v-if="!isContainsSpecial" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isContainsSpecial" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1SpecialChar') }}</small>
              </li>
              <li>
                <ion-icon color="danger" v-if="!isPasswordSame" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isPasswordSame" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1PasswordSame') }}</small>
              </li>
            </ul>
            <ion-label  position="stacked" class="ion-text-wrap" color="primary">{{ $t('passwordConfirm') }}</ion-label>
            <ion-input  :placeholder="$t('passwordConfirm')" type="password" v-model="passwordConfirm"></ion-input>
          </ion-item>
          <ion-item>
             <ion-checkbox color="primary" v-model="okAgb"></ion-checkbox>
              <ion-label style="margin-left: 5px;">{{ $t("acceptAgb") }}</ion-label>
              <ion-button @click="openAgb()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
          </ion-item>
          <ion-item>
             <ion-checkbox color="primary" v-model="okData"></ion-checkbox>
              <ion-label style="margin-left: 5px;">{{ $t("acceptDSGVO") }}</ion-label>
              <ion-button @click="openData()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
          </ion-item>
          <ion-button v-if="v$" :disabled="isDisabled" @click="activateAccount()" expand="block" color="primary">{{ $t('verify') }}</ion-button>
        </ion-list>
        <ion-list v-if="verifyOk" lines="full">
          <ion-item>
            <ion-label class="ion-text-wrap" color="primary">{{ $t('canLogin') + ' ' + teetimeId }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-card>
      </section>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader,
IonInput, IonItem, IonList, IonCheckbox, IonIcon, alertController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import verifyEmailMutationFile from '../graphql/verifyEmail.mutation.gql'
import { useMutation } from '@vue/apollo-composable'
import { useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { openOutline, checkmarkOutline, closeOutline } from 'ionicons/icons'
import agbDialog from './dialogs/AGBDialog'
import dataDialog from './dialogs/DataLegalDialog'


const containsUppercase = (value) => new RegExp("(?=.*[A-Z])").test(value)
const containsSpecial = (value) => new RegExp("(?=.*[-+_!@#$%^&*., ?])").test(value)
const containsNumber = (value) => new RegExp("(?=.*[0-9])").test(value)

export default defineComponent({
  name: 'Verify',
  components: {
    IonContent, IonInput, IonItem, IonList,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCheckbox, IonIcon,
    IonButton, IonLabel, IonCard, IonCardHeader
  },
  setup() {
    const route = useRoute();
    const token = route.params.token
    const verifyOk = ref(false)
    const password = ref('')
    const passwordConfirm = ref('')
    const okAgb = ref(false)
    const okData = ref(false)
    const isMinLength8 = ref(false)
    const isContainsUppercase = ref(false)
    const isContainsSpecial = ref(false)
    const isContainsNumber = ref(false)
    const isPasswordSame = ref(false)
    const teetimeId = ref('')

    const { mutate: verifyEmailMutation } = useMutation(verifyEmailMutationFile, { fetchPolicy: 'no-cache' });

    return {
      verifyEmailMutation, token, verifyOk, v$: useVuelidate(), password, passwordConfirm, okAgb, okData, openOutline, checkmarkOutline, closeOutline, isMinLength8, isContainsUppercase, isContainsSpecial, isContainsNumber, isPasswordSame, teetimeId
    }
  },
  computed: {
    isDisabled() {
      return this.v$.$invalid || this.password != this.passwordConfirm || !this.okAgb || !this.okData;
    },
  },
  validations () {
    return {
      password: { required, minLength: minLength(8), containsUppercase, containsSpecial, containsNumber }
    }
  },
  watch: {
    password: function() {
      this.isMinLength8 = this.password.length>=8?true:false
      this.isContainsUppercase = containsUppercase(this.password);
      this.isContainsSpecial = containsSpecial(this.password);
      this.isContainsNumber = containsNumber(this.password);
      this.isPasswordSame = this.password == this.passwordConfirm;
    },
    passwordConfirm: function() {
      this.isPasswordSame = this.password == this.passwordConfirm;
    },
  },
  methods: {
    async openAgb() {
      const modal = await modalController
        .create({
          component: agbDialog,
        })
      return modal.present();
    },
    async openData() {
      const modal = await modalController
        .create({
          component: dataDialog,
        })
      return modal.present();
    },
    activateAccount() {
      this.verifyEmailMutation({
        token: this.token, password: this.password
      })
      .then( async res =>  {
        if (res && res.data && res.data.verifyEmail && res.data.verifyEmail.success) {
          this.verifyOk = true
          this.password = ''
          this.passwordConfirm = ''
          this.teetimeId = res.data.verifyEmail.addInfo
          /*const alert = await alertController
            .create({
              header: this.$t('verifyEmailOK'),
              message:  this.$t('canLogin') + '<br><strong>' + res.data.verifyEmail.addInfo + '</strong>',
              buttons: [
                {
                  text: this.$t('ok') ,
                  role: 'cancel',
                },
              ],
            });
          alert.present();*/

          toastController
            .create({
              message: this.$t('verifyEmailOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else
          toastController
            .create({
              message: this.$t(res.data.verifyEmail.errorCode),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('verifyEmailError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      })

    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>