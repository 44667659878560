<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescorings</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="isLoggedIn">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('competitions') }}</ion-label>
          </ion-item>
          <h3 style="margin: 15px;" v-if="livescorings && livescorings.length <= 0">{{ $t('noLivescorings') }}</h3>
          <ion-item v-for="(livescoring, index) in livescorings" :key="index" lines="full" class="ion-no-padding" router-direction="root" :router-link="'/livescoring/' + livescoring.id">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="createOutline" :ios="createOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ dayjs(livescoring.start_time).format('HH:mm') }} Tee {{ livescoring.start_tee }}: {{ livescoring.name }} - {{ $t('round') }} {{ livescoring.round_index }}</ion-label>
          </ion-item>
        </ion-card-header>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('personalScorecard') }}</ion-label>
          </ion-item>
          <h3 style="margin: 15px;" v-if="courses && courses.length <= 0">{{ $t('noClubCourses') }}</h3>
          <ion-grid v-if="courses && courses.length > 0">
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('coursename')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-select  interface="popover" v-model="selectedCourse">
                  <ion-select-option v-for="(item, index) in courses" :key="index" :value="item">{{ item.name }} ({{ item.numHoles }} {{ $t('holes')  }})</ion-select-option>          
                </ion-select>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('tee')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-select  interface="popover" v-model="selectedTee">
                  <ion-select-option v-for="(item, index) in selectedCourse.tees" :key="index" :value="item">{{ item.name }}  {{ (item.gender == 1 ? $t('gentleman') : $t('ladies')) }}</ion-select-option>          
                </ion-select>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('hcpi')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-input type="text" v-model="selectedHCPI"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap">{{ $t('hcpiInputHint') }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button color="primary" @click="getScorecard()">{{  $t('getScorecard') }}</ion-button>
        </ion-card-header>
      </ion-card>
      <!-- <ion-card>
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('RPRs') }}</ion-label>
            <ion-button fill="clear">
            <ion-icon style="zoom: 1.4" slot="end" color="success" :md="addCircleOutline" :ios="addCircleOutline"></ion-icon>
            </ion-button>
          </ion-item>
          <h3 style="margin: 15px;" v-if="rprs && rprs.length <= 0">{{ $t('noRPRs') }}</h3>
          <ion-item v-for="(rpr, index) in rprs" :key="index" lines="full" class="ion-no-padding" router-direction="root" :router-link="'/livescoringrpr/' + rpr.id">
            <ion-icon v-if="rpr.status == 0 && !rpr.myRPR" style="zoom: 1.0" slot="start" color="primary" :md="createOutline" :ios="createOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ dayjs(rpr.date).format('DD.MM.YYYY HH:mm') }}: {{ rpr.comment }}</ion-label>
          </ion-item>
        </ion-card-header>
      </ion-card> -->
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonSelect, IonSelectOption,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonInput, IonList } from '@ionic/vue';
import { defineComponent, watch, ref, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, addCircleOutline } from 'ionicons/icons';
import { useQuery, useResult, onResult } from '@vue/apollo-composable'
import getLivescoringsQueryFile from '../graphql/getLivescorings.query.gql'
import * as dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
import store from '../main'

export default defineComponent({
  name: 'Livescorings',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonSelect, IonSelectOption, IonInput, IonList,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
   const router = useRouter()
   const route = useRoute()
   let selectedCourse = ref()
   let selectedTee = ref()
   let selectedHCPI = ref(28.4)

    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 

    const {onResult, result: getLivescoringsResult, loading: getLivescoringsLoading } = useQuery(getLivescoringsQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const livescorings = useResult(getLivescoringsResult, null, data => data.getLivescorings.livescorings)
    const rprs = useResult(getLivescoringsResult, null, data => data.getLivescorings.rprs)
    const courses = useResult(getLivescoringsResult, null, data => data.getLivescorings.courses)
    onResult(queryResult => {
      if (queryResult.data && queryResult.data.getLivescorings && queryResult.data.getLivescorings.courses.length > 0) {
        if (route.query.courseid && queryResult.data.getLivescorings.courses.find(f => f.cid == route.query.courseid))
          selectedCourse.value = queryResult.data.getLivescorings.courses.find(f => f.cid == route.query.courseid)
        else if (route.query.course && route.query.plant && queryResult.data.getLivescorings.courses.find(f => f.course == route.query.course && f.plant == route.query.plant))
          selectedCourse.value = queryResult.data.getLivescorings.courses.find(f => f.course == route.query.course && f.plant == route.query.plant)
        else
          selectedCourse.value = queryResult.data.getLivescorings.courses[0]
      }
      if (queryResult.data && queryResult.data.getLivescorings && queryResult.data.getLivescorings.hcp)
        selectedHCPI.value = parseFloat(queryResult.data.getLivescorings.hcp)
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getLivescoringsLoading.value)  
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getLivescoringsLoading, (getLivescoringsLoading, prevLoading) => {
      if (!getLivescoringsLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getLivescoringsLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getLivescoringsLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    watch(selectedCourse, (newVal, preVal) => {
      selectedTee.value = newVal.tees[0]
    })

    return {
      livescorings, personOutline, calendarClearSharp, dayjs, createOutline, rprs, addCircleOutline, selectedCourse, selectedTee, courses, selectedHCPI, isLoggedIn
    }
  },
  methods: {
    getScorecard() {
      if (isNaN(this.selectedHCPI))
        this.selectedHCPI = 54
      this.$router.push('/livescoringsc/' + this.selectedTee.tid + '/' + this.selectedHCPI)
    },
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>