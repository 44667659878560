<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>{{ $t('addPerson') }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">{{ $t('gender') }}</ion-label>
        <ion-select interface="popover" v-model="gender" :value="gender">
          <ion-select-option value="female">{{ $t('female') }}</ion-select-option>          
          <ion-select-option value="male">{{ $t('male') }}</ion-select-option>          
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('firstName')}}</ion-label>
        <ion-input type="text" v-model="name"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('lastName')}}</ion-label>
        <ion-input type="text" v-model="lastname"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('email') }}</ion-label>
        <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('hcp') }}</ion-label>
        <ion-input type="number" :value="hcp" v-model="hcp"></ion-input>
      </ion-item>

      <ion-button :disabled="name.trim() == '' || lastname.trim() == ''" @click="save()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonSelect, IonSelectOption, IonButton, IonContent, 
          IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonToggle,
          IonCol, IonRow, modalController, IonDatetime } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'AddPersonManual',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonToggle, IonDatetime },
  props: ['propsData'],
  setup() {
    const name = ref('')
    const lastname = ref('')
    const hcp = ref(0)
    const email = ref('')
    const gender = ref('female')

    return {
      closeOutline, name, lastname, hcp, email, gender
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    save() {
      return modalController.dismiss({ name: this.name, lastname: this.lastname, email: this.email, hcp: this.hcp, gender: this.gender })
    },
  }
});


</script>