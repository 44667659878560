<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescoring</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="livescoring" id="scorecard" >
        <ion-card-header>
          <ion-card-title>
            <ion-item class="ion-no-padding">
              <h3>{{ livescoring.name }}</h3>
              <ion-button v-if="livescoring.allowRanking" @click="openRanking()" slot="end" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: top;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-card-title>
          <ion-label class="ion-text-wrap">{{ $t('round') }} {{ (livescoring.round_index) }}: {{ $t(('PF' + livescoring.play_form)) }}, {{ $t(('SM' + livescoring.scoring_mode)) }}, {{ $t(('W' + livescoring.wertung)) }} <strong>({{ $t(('HCPREL' + livescoring.hcp_relevance)) }})</strong></ion-label><br><br>

          <ion-label class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('player') }}: {{ livescoring.player.nameLong }}</ion-label><br>
          <ion-label class="ion-text-wrap" style="font-size: 18px;--margin-top: 0px;--padding: 0px;" v-for="(teamPlayer, index) in livescoring.player.teamPlayers" :key="index">{{ teamPlayer }}</ion-label>
          <br v-if="livescoring.player.teamPlayers.length > 0">
          <ion-label class="ion-text-wrap" style="--margin-top: 0px;--padding: 0px;">{{ livescoring.player.course}}: {{ $t(livescoring.player.teegender) }} - {{ livescoring.player.teename }}, Par: {{livescoring.player.par}}, CR: {{livescoring.player.cr}} Slope: {{livescoring.player.slope}}</ion-label><br><br>

          <ion-label class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('marker') }}: {{ livescoring.marker.nameLong }}</ion-label><br>
          <ion-label class="ion-text-wrap" style="font-size: 18px;--margin-top: 0px;--padding: 0px;" v-for="(teamPlayer, index) in livescoring.marker.teamPlayers" :key="index">{{ teamPlayer }}</ion-label>
          <br v-if="livescoring.marker.teamPlayers.length > 0">
          <ion-label class="ion-text-wrap" style="--margin-top: 0px;--padding: 0px;">{{ livescoring.marker.course}}: {{ $t(livescoring.marker.teegender) }} - {{ livescoring.marker.teename }}, Par: {{livescoring.marker.par}}, CR: {{livescoring.marker.cr}} Slope: {{livescoring.marker.slope}}</ion-label><br><br>

          <ion-card-title>Tee {{ livescoring.start_tee }}: {{ dayjs(livescoring.start_time).format('HH:mm') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-grid v-if="livescoring.scoring_mode != 3">
            <ion-row>
              <ion-col class="ion-no-padding text-center numcol bordertop">#</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">Par</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">//</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol bordertop">{{ $t('player') }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol bordertop borderright">{{ $t('marker') }}</ion-col>
            </ion-row>
            <ion-row v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol"><ion-label>{{index+1}}</ion-label></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index); saveScore(index+1)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresPlayer[index] = livescoring.pars[index]; saveScore(index+1)" fill="outline" color="" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index) }}
                    </ion-label>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index]++;saveScore(index+1)" :disabled="scoreCardSigned" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>
              </ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenMarker[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol  borderright">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresMarker, index);" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index] = livescoring.pars[index]" fill="outline" color="secondary" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresMarker, index) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index]++" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresMarker.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18" v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol">{{index+10}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index+9]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9); saveScore(index+10)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresPlayer[index+9] = livescoring.pars[index+9];saveScore(index+10)" fill="outline" color="" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresPlayer[index+9]++;saveScore(index+10)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenMarker[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresMarker, index+9);" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index+9] = livescoring.pars[index+9];" fill="outline" color="secondary" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresMarker, index+9) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index+9]++" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx >= 9 ).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresMarker.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.scoring_mode == 1 ? '' : livescoring.pars.reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresMarker.reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>

          <!-- Matchplay -->
          <ion-grid v-else>
            <ion-row>
              <ion-col class="ion-no-padding text-center numcol bordertop">#</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">Par</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">//</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center wincol bordertop">{{ $t('player') }}</ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center wincol bordertop">{{ $t('marker') }}</ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol borderright bordertop"></ion-col>
            </ion-row>
            <ion-row v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol"><ion-label>{{index+1}}</ion-label></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center wincol">

                  <ion-button :disabled="scoreCardSigned || livescoring.matchPlayScore.filter((f, idx) => idx < (index) && f.score.indexOf('&') >= 0).length > 0" @click="saveMatchplayScore(index+1,livescoring.player.roundPlayerId)" fill="outline" :color="livescoring.matchPlayScore[index].colorWinPlayer" class="ion-no-padding sizewin" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ $t('win') }}
                    </ion-label>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol">{{ livescoring.matchPlayScore[index].score }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenMarker[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center wincol">

                  <ion-button :disabled="scoreCardSigned || livescoring.matchPlayScore.filter((f, idx) => idx < (index) && f.score.indexOf('&') >= 0).length > 0" @click="saveMatchplayScore(index+1,livescoring.marker.roundPlayerId)" fill="outline" :color="livescoring.matchPlayScore[index].colorWinMarker" class="ion-no-padding" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ $t('win') }}
                    </ion-label>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol borderright">
                  <ion-button :disabled="scoreCardSigned || livescoring.matchPlayScore.filter((f, idx) => idx < (index) && f.score.indexOf('&') >= 0).length > 0" @click="saveMatchplayScore(index+1,livescoring.player.roundPlayerId, true)" fill="outline" :color="livescoring.matchPlayScore[index].colorWinAs" class="ion-no-padding" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ $t('AS') }}
                    </ion-label>
                  </ion-button>

              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                <ion-label>{{ livescoring.bruttoPlayer.filter((num,idx) => idx < 9 && num > 0).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                <ion-label>{{ livescoring.bruttoMarker.filter((num,idx) => idx < 9 && num > 0).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol borderright">{{ -livescoring.bruttoMarker.filter((num,idx) => idx < 9 && num == -1).reduce((a, b) => a + b, 0) }}</ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18" v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol">{{index+10}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index+9]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                  <ion-button :disabled="scoreCardSigned || livescoring.matchPlayScore.filter((f, idx) => idx < (index+9) && f.score.indexOf('&') >= 0).length > 0" @click="saveMatchplayScore(index+9+1,livescoring.player.roundPlayerId)" fill="outline" :color="livescoring.matchPlayScore[index+9].colorWinPlayer" class="ion-no-padding sizewin" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ $t('win') }}
                    </ion-label>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol">{{ livescoring.matchPlayScore[index+9].score }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenMarker[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center wincol">

                  <ion-button :disabled="scoreCardSigned || livescoring.matchPlayScore.filter((f, idx) => idx < (index+9) && f.score.indexOf('&') >= 0).length > 0" @click="saveMatchplayScore(index+9+1,livescoring.marker.roundPlayerId)" fill="outline" :color="livescoring.matchPlayScore[index+9].colorWinMarker" class="ion-no-padding sizewin" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ $t('win') }}
                    </ion-label>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol borderright">
                  <ion-button :disabled="scoreCardSigned || livescoring.matchPlayScore.filter((f, idx) => idx < (index+9) && f.score.indexOf('&') >= 0).length > 0" @click="saveMatchplayScore(index+9+1,livescoring.player.roundPlayerId, true)" fill="outline" :color="livescoring.matchPlayScore[index+9].colorWinAs" class="ion-no-padding" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ $t('AS') }}
                    </ion-label>
                  </ion-button>

              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                <ion-label>{{ livescoring.bruttoPlayer.filter((num,idx) => idx >= 9 && num > 0).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                <ion-label>{{ livescoring.bruttoMarker.filter((num,idx) => idx >= 9 && num > 0).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol borderright">{{ -livescoring.bruttoMarker.filter((num,idx) => idx >= 9 && num == -1).reduce((a, b) => a + b, 0) }}</ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.scoring_mode == 1 ? '' : livescoring.pars.reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                <ion-label>{{ livescoring.bruttoPlayer.filter((num,idx) => num > 0).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center wincol">
                <ion-label>{{ livescoring.bruttoMarker.filter((num,idx) => num > 0).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center matchplayscorecol borderright">{{ -livescoring.bruttoMarker.filter((num,idx) => num == -1).reduce((a, b) => a + b, 0) }}</ion-col>
            </ion-row>
          </ion-grid>
          <ion-button v-if="livescoring.scoring_mode != 3" @click="read()">{{ $t('read') }}</ion-button><br>
          <ion-label v-if="saveCount == saveCountDone"><strong>{{ $t('signaturePlayer') }}</strong></ion-label>
          <Signature v-if="livescoring.result_type != 1 && saveCount == saveCountDone" :isConfirmed="isConfirmedPlayerPad" @saveSignature="saveSignaturePlayer" v-model="livescoring.signaturePlayer" />
          <ion-img v-if="livescoring.result_type == 1" :src="livescoring.signaturePlayer" style="width: 300px; height: 150px;"></ion-img>
          <ion-label v-if="saveCount == saveCountDone"><strong>{{ $t('signatureMarker') }}</strong></ion-label>
          <Signature v-if="livescoring.result_type != 1 && saveCount == saveCountDone" :isConfirmed="isConfirmedMarkerPad" @saveSignature="saveSignatureMarker" v-model="livescoring.signatureMarker"/>
          <ion-img v-if="livescoring.result_type == 1" :src="livescoring.signatureMarker" style="width: 300px; height: 150px;"></ion-img>
          <ion-label v-if="saveCount != saveCountDone" color="danger"><strong>{{ $t('noInternet') }}</strong></ion-label>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>

import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardTitle,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, IonBadge, loadingController, IonChip,
        toastController, IonImg, modalController } from '@ionic/vue';
import { defineComponent, ref, watch, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline, openOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getLivescoringQueryFile from '../graphql/getLivescoring.query.gql'
import saveScoreMutationFile from '../graphql/saveScore.mutation.gql'
import saveMatchplayScoreMutationFile from '../graphql/saveMatchplayScore.mutation.gql'
import saveScorecardMutationFile from '../graphql/saveScorecard.mutation.gql'
import * as dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
import Signature from "./SignaturePad"
import { isPlatform } from '@ionic/vue';
import translate from '@/plugins/translate'
import domtoimage from "dom-to-image-more"
import { Geolocation } from '@capacitor/geolocation';
import ShowLivescoringResultDialog from './dialogs/ShowLivescoringResultDialog'

export default defineComponent({
  name: 'Livescorings',
  components: {
    IonContent,
    IonHeader, Signature, IonImg,
    IonPage,
    IonTitle, 
    IonToolbar, IonCardContent, IonCardTitle, IonBadge, IonChip,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
    let loadingSpinner = null
    const router = useRouter()
    const route = useRoute()
    const isConfirmedPlayer = ref(false)
    const isConfirmedMarker = ref(false)
    const printMe = ref(null);
    let saveCount = ref(0)
    let saveCountDone = ref(0)

    const {refetch: getLivescoringRefetch, result: getLivescoringResult, loading: getLivescoringLoading } = useQuery(getLivescoringQueryFile, () => ({ id: route.params.id == null ? 0 :  parseInt(route.params.id) }), { fetchPolicy: 'no-cache' })
    const livescoring = useResult(getLivescoringResult, null, data => data.getLivescoring.livescoring)

    const { mutate: saveScoreMutation } = useMutation(saveScoreMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveMatchplayScoreMutation } = useMutation(saveMatchplayScoreMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveScorecardMutation } = useMutation(saveScorecardMutationFile, { fetchPolicy: 'no-cache' });

    const isConfirmedPlayerPad = computed(() => isConfirmedPlayer.value || livescoring.value.result_type == 1 ) 
    const isConfirmedMarkerPad = computed(() => isConfirmedMarker.value || livescoring.value.result_type == 1 ) 

    const saveSignatureMarker = (signature) => {
      if (livescoring.value.scoring_mode != 3 && livescoring.value.scoresPlayer.filter((f, idx) => f == 0 && idx < livescoring.value.player.numHoles).length > 0) {
        toastController
          .create({
            message: translate('missingScores'),
            duration: 5000,
            color: 'warning'
          }).then(res => res.present())
          return
      }
      if (signature == 'data:,')
       return
       
      livescoring.value.signatureMarker = signature
      isConfirmedMarker.value = true

    }

    const saveSignaturePlayer = async (signature) => {
      if (livescoring.value.scoring_mode != 3 && livescoring.value.scoresPlayer.filter((f, idx) => f == 0 && idx < livescoring.value.player.numHoles).length > 0) {
        toastController
          .create({
            message: translate('missingScores'),
            duration: 5000,
            color: 'warning'
          }).then(res => res.present())
          return
      }
      if (signature == 'data:,')
       return

      livescoring.value.signaturePlayer = signature
      isConfirmedPlayer.value = true
    }
    const saveScore = (hole) => {
      Geolocation.getCurrentPosition().then(res => {
        saveCount.value++
        saveScoreMutation({ roundPlayerId: parseInt(livescoring.value.player.roundPlayerId), hole: parseInt(hole), score: parseInt(livescoring.value.scoresPlayer[hole-1]), latitude: res.coords.latitude, longitude: res.coords.longitude }).then(res => { saveCountDone.value++ })
      }).catch(err => {
        saveCount.value++
        saveScoreMutation({ roundPlayerId: parseInt(livescoring.value.player.roundPlayerId), hole: parseInt(hole), score: parseInt(livescoring.value.scoresPlayer[hole-1])  }).then(res => { saveCountDone.value++ })
      })

    }
    const saveMatchplayScore = async (hole, roundPlayerId, isAS = false)  => {
      saveCount.value++
      Geolocation.getCurrentPosition().then(res => {
        saveMatchplayScoreMutation({ roundPlayerId: parseInt(roundPlayerId), hole: parseInt(hole), isAS: isAS, latitude: res.coords.latitude, longitude: res.coords.longitude  }).then(res => { getLivescoringRefetch(); saveCountDone.value++ })
        
      }).catch(err => {
        saveCount.value++
        saveMatchplayScoreMutation({ roundPlayerId: parseInt(roundPlayerId), hole: parseInt(hole), isAS: isAS  }).then(res => { getLivescoringRefetch(); saveCountDone.value++; })
      })

    }
    const decreaseScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return
      else if (scoring_mode != 1 && scores[index] == 0)
        return
      else
        scores[index]--
    }

    const showScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return '-'
      else if (scores[index] == 0)
        return ''
      else
        return scores[index]
    }

    watch([isConfirmedPlayer, isConfirmedMarker], ([player, marker], [prevPlayer, prevMarker]) => {
      if (isConfirmedPlayer.value && isConfirmedMarker.value) {
        domtoimage
          .toJpeg(document.getElementById("scorecard"), { quality: 0.5 })
          .then(function (dataUrl) {
            saveScorecardMutation({ roundPlayerId: parseInt(livescoring.value.player.roundPlayerId), signaturePlayer: livescoring.value.signaturePlayer, signatureMarker: livescoring.value.signatureMarker, scorecard: dataUrl  })
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          })
      }
      
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getLivescoringLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getLivescoringLoading, (getLivescoringLoading, prevLoading) => {
      if (!getLivescoringLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getLivescoringLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getLivescoringLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })
    
    if (isPlatform('capacitor'))
      screen.orientation.lock('landscape')
    watch(
      () => route.params.id,
      (id, prevId) => {
        if (id == undefined && prevId > 0 && isPlatform('capacitor'))
          screen.orientation.unlock()
        else if (route.path.startsWith('/livescoring') && id != undefined && isPlatform('capacitor'))
          screen.orientation.lock('landscape')
      }
    )
    return {
      livescoring, personOutline, calendarClearSharp, dayjs, createOutline, route, saveMatchplayScore,
      removeCircle, addCircle, saveSignatureMarker, saveSignaturePlayer, arrowDownCircleOutline, arrowUpCircleOutline, isConfirmedPlayer, isConfirmedMarker, saveScore, printMe, showScore, decreaseScore, isConfirmedPlayerPad, isConfirmedMarkerPad, openOutline, isPlatform, saveCount, saveCountDone
    }
  },
  computed: {
      scoreCardSigned : function(){ return  this.livescoring.signaturePlayer != '' || this.livescoring.result_type == 1 },
    },
  data () {
    return {
      synth: window.speechSynthesis,
    }
  },
  methods: {
    async openRanking() {
      const modal = await modalController
        .create({
          component: ShowLivescoringResultDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              livescoring: this.livescoring,
            }
          },
        })
      return modal.present();
    },
    speak(list, interval, count) {
      if (list.length) {
        var msg = new SpeechSynthesisUtterance();
        msg.text = list[0];

        this.synth.speak(msg);

        setTimeout(() => {
            this.speak(list.slice(1), (count % 3 == 0 ? 2000 : 300), count + 1)
        }, interval)
      }
    },
    read() {
      let list = []
      for (const score of this.livescoring.scoresPlayer) {
        list.push(score.toString())
      }
      this.speak(list, 300, -1)
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-col.numcol {
    flex: 0 0 25px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    vertical-align: center;
  }

ion-col.parindexcol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.vorgabecol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }


ion-col.scorecol {
    flex: 0 0 150px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.matchplayscorecol {
    flex: 0 0 50px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.wincol {
    flex: 0 0 100px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

.sizebig {
  height: 45px;
  width: 45px;
  vertical-align: top;
}

.borderright {
    border-right: 1px solid grey;
}

.bordertop {
    border-top: 1px solid grey;
}

</style>