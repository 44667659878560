<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>{{ $t('registerGuest') }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-label class="ion-text-wrap" style="margin-left: 15px;">{{ $t('dgvdescription') }}</ion-label><br><br>
    <ion-label class="ion-text-wrap"  style="margin-left: 15px; font-size: 14px;">{{ $t('dgvdescription1') }}<br><div style="font-weight: bold; margin-left: 15px;">DGV, SwissGolf, ÖGV</div><div style="margin-left: 15px;">{{ $t('dgvdescription2') }}</div>
    </ion-label>
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">{{ $t('dgvnumber') }}</ion-label>
          <ion-row style="margin-top: 15px; margin-left: 0px; padding-left: 0px;">
          <ion-col size="5">
            <ion-select style="min-width: 100% !important;" interface="popover" v-model="cardType" :value="cardType">
              <ion-select-option value="DGV">DGV {{ $t('cardclub') }}</ion-select-option>          
              <ion-select-option value="SwissGolf">Swiss Golf {{ $t('cardclub') }}</ion-select-option>     
              <ion-select-option value="ÖGV">ÖGV {{ $t('cardclub') }}</ion-select-option>          
            </ion-select>
          </ion-col>
          <ion-col size="7">
            <ion-input placeholder="9008100001" type="text" v-model="dgvnumber"></ion-input>
          </ion-col>
        </ion-row>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('gender') }}</ion-label>
        <ion-select interface="popover" v-model="gender" :value="gender">
          <ion-select-option value="female">{{ $t('female') }}</ion-select-option>          
          <ion-select-option value="male">{{ $t('male') }}</ion-select-option>          
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('title') }}</ion-label>
        <ion-select interface="popover" v-model="title" :value="title">
          <ion-select-option value=""></ion-select-option>          
          <ion-select-option value="Dr.">Dr.</ion-select-option>          
          <ion-select-option value="Prof.">Prof.</ion-select-option>          
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('firstName') }}</ion-label>
        <ion-input placeholder="Max" type="text" v-model="firstName"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('lastName') }}</ion-label>
        <ion-input placeholder="Mustermann" type="text" v-model="lastName"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked"  @click="showDatePicker = !showDatePicker">{{ $t('birthdate') }}</ion-label>
        <span v-if="!isPlatform('capacitor')" style="font-size: 16px;" position="stacked" @click="showDatePicker = !showDatePicker"> {{ birthdateFormated }}</span>
        <DatePicker v-if="!isPlatform('capacitor')" v-show="showDatePicker == true" v-model="birthdate" />
        <ion-datetime v-if="isPlatform('capacitor')" v-model="birthdate" display-format="DD.MM.YYYY" required></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('email') }}</ion-label>
        <ion-input placeholder="test@test.com" type="email" v-model="email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('hcp') }}</ion-label>
        <ion-input type="number" :value="hcp" v-model="hcp"></ion-input>
      </ion-item>
      <ion-item-divider v-if="!useIDCardNumber" color="">
        <ion-label>
          {{ $t('searchCountry') }}
        </ion-label>
      </ion-item-divider>
      <ion-item v-if="selectedClub && !useIDCardNumber">
        <ion-icon @click="removeSelectedClub()" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
        <ion-label color="success">{{ selectedClub.name }}</ion-label>
      </ion-item>
      <ion-item v-if="!useIDCardNumber" lines="full">
        <ion-searchbar :placeholder="$t('searchCountry')" v-model="searchText"  @ionBlur="setOnBlur()"  @ionClear="findClub('')" @input="findClub($event.target.value)"></ion-searchbar>
      </ion-item>
      <ion-item v-if="clubsFound && clubsFound.length > 0">
        <ion-list>
          <ion-list-header>
            {{ $t('searchresult') }}
          </ion-list-header>
          <ion-item class="ion-text-wrap" v-for="(club, i) in clubsFound" :key="i" @click="addClub(club)">
            <ion-label class="ion-text-wrap clickable" style="margin-left: 5px">
              <h2>{{ club.name }}</h2>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-item>
      <ion-item>
          <ion-checkbox color="primary" v-model="okAgb"></ion-checkbox>
          <ion-label style="margin-left: 5px;">{{ $t("acceptAgb") }}</ion-label>
          <ion-button @click="openAgb()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
            <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
          </ion-button>
      </ion-item>
      <ion-item>
          <ion-checkbox color="primary" v-model="okData"></ion-checkbox>
          <ion-label style="margin-left: 5px;">{{ $t("acceptDSGVO") }}</ion-label>
          <ion-button @click="openData()" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
            <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
          </ion-button>
      </ion-item>
      <ion-label v-if="errorText != ''" class="ion-text-wrap"><strong>{{ errorText }}</strong></ion-label>
      <ion-button :disabled="isDisabled" @click="registerUser()" expand="block" color="success">{{ $t('register') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, isPlatform,
        IonItemDivider, IonSearchbar, IonListHeader, IonSelectOption, IonSelect, IonCheckbox } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, closeCircleOutline, trashOutline, openOutline } from 'ionicons/icons';

import { useMutation, useResult, useQuery } from '@vue/apollo-composable'
import registerUserMutationFile from '../graphql/registerUser.mutation.gql'
import findClubsQueryFile from '../graphql/findClubs.query.gql'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import agbDialog from './dialogs/AGBDialog'
import dataDialog from './dialogs/DataLegalDialog'

import * as dayjs from 'dayjs';
import 'v-calendar/dist/style.css';

const isEmail = (value) => new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)


export default defineComponent({
  name: 'RegisterDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, IonCheckbox,
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonSearchbar, IonListHeader, IonSelectOption, IonSelect },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      dgvnumber: '',
      password: '',
    }
  },
  setup() {
    const birthdate = ref('1970-06-15')
    const selectedClub = ref()
    const searchText = ref('')
    const errorText = ref('')
    const gender = ref('female')
    const title = ref('')
    const addInfo = ref('')
    const cardType = ref('DGV')
    const hcp = ref(0)
    const linkSent = ref(false)
    const useIDCardNumber = ref(false)
    const okAgb = ref(false)
    const okData = ref(false)
    const showDatePicker = ref(false)

    const { refetch: findClubsRefetch, result: findClubsResult, query: findClubsQuery } = useQuery(findClubsQueryFile, () => ({ searchTerm: '' }))

    const clubsFound = useResult(findClubsResult, null, data => data.findClubs.clubs)

    const { mutate: registerUserMutation } = useMutation(registerUserMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline, birthdate, findClubsResult, clubsFound, errorText, gender, title, hcp, isPlatform,
      closeCircleOutline, selectedClub, searchText, trashOutline, addInfo, linkSent, cardType,
      registerUserMutation, findClubsRefetch, v$: useVuelidate(), useIDCardNumber, okAgb, okData, openOutline, showDatePicker
    }
  },
  computed: {
    birthdateFormated() { return dayjs(this.birthdate).format('DD.MM.YYYY') },
    isDisabled() {
      if (this.linkSent)
        return true
      if (this.dgvnumber.length == 10 && isEmail(this.email) && !this.v$.firstName.$invalid && !this.v$.lastName.$invalid) {
        this.useIDCardNumber = true
        return false
      }
      if (this.dgvnumber.length == 10)
        this.useIDCardNumber = true
      else
        this.useIDCardNumber = false
      return this.v$.$invalid || !this.okAgb || !this.okData || (!this.selectedClub && (this.dgvnumber == '' || this.dgvnumber.length != 10))
    },
  },
  validations () {
    return {
      firstName: { required }, lastName: { required }, email: { required, isEmail }
    }
  },
  methods: {
    async openAgb() {
      const modal = await modalController
        .create({
          component: agbDialog,
        })
      return modal.present();
    },
    async openData() {
      const modal = await modalController
        .create({
          component: dataDialog,
        })
      return modal.present();
    },
    removeSelectedClub()  {
      this.selectedClub = null
    },
    setOnBlur() {
      setTimeout(() => { this.searchText = '' }, 500)
      
    },
    async addClub(club) {
      this.selectedClub = club
      this.findClubsRefetch({ searchTerm: ''})
    },
    async findClub(searchTerm) {
      if (searchTerm.length > 3) {
        this.findClubsRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findClubsRefetch({ searchTerm: ''})
      }
    },
    closeDialog() {
      return modalController.dismiss();
    },
    registerUser() {
      this.registerUserMutation({         
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        birthdate: dayjs(this.birthdate).format('YYYY-MM-DD'),
        gender: this.gender,
        title: this.title,
        hcp: this.hcp == null || this.hcp == '' ? 59 : parseFloat(this.hcp),
        dgvnumber: this.dgvnumber,
        cardtype: this.cardType,
        club: this.selectedClub ? parseInt(this.selectedClub._id) : 0,
        language: this.$store.getters.language
        })
        .then(res => {
          if (res && res.data && res.data.registerUser && !res.data.registerUser.success) {
            this.addInfo = res.data.registerUser.addInfo
            this.errorText = this.$t(res.data.registerUser.errorCode).replace('#', res.data.registerUser.addInfo)
          }
          if (res && res.data && res.data.registerUser && res.data.registerUser.success) {
            this.linkSent = true
            this.errorText = this.$t('activationLinkSent').replace('#', res.data.registerUser.addInfo)
          }
        })
        .catch(err => {
            this.errorText = this.$t('b4d05e58') + ': ' + err
        })
    }

  }
});
</script>

<style scoped>

.clickable {
    cursor: pointer;
}
</style>