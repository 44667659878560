module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Můj klub",
    "pros": "Klady",
    "persons": "Osoby",
    "competitions": "Turnaje",
    "competition": "turnaj",
    "competitionUC": "Turnaj",
    "bookings": "Rezervace",
    "statistics": "Statistiky",
    "settings": "Nastavení",
    "profile": "Profil",
    "login": "Přihlášení",
    "logout": "Odhlášení",
    "organizations-list": "Moje organizace",
    "companies": "Kluby",
    "company": "Klub",
    "searchcompanies": "Vyhledávání klubů",
    "searchresult": "Výsledek hledání",
    "history": "Nedávné",
    "register": "Registrace",
    "save": "Uložit",
    "userData": "Údaje o uživateli",
    "changePassword": "Změna hesla",
    "changeEmail": "Změna e-mailu",
    "language": "Jazyk",
    "firstName": "Křestní jméno",
    "lastName": "Příjmení",
    "birthdate": "Narozeniny",
    "password": "Heslo",
    "email": "E-mail",
    "profilePicture": "Profilový obrázek",
    "takePicture": "Vybrat obrázek",
    "upload": "Uložit obrázek",
    "passwordReset": "Odeslání e-mailu s obnovením hesla",
    "events": "Události",
    "event": "událost",
    "eventUC": "Událost",
    "loading": "Načítání dat ...",
    "newcompanies": "Staňte se hostitelem",
    "erroLogin": "Pro vytvoření klubu se prosím přihlaste!",
    "newCategory": "Nová kategorie",
    "description": "Popis",
    "changeSuccessful": "Úspěšná změna",
    "changeNotSuccessful": "Chyba při změně",
    "deleteSuccessful": "Úspěšně smazat",
    "deleteNotSuccessful": "Chyba při mazání",
    "addSuccessful": "Přidat úspěšný",
    "addNotSuccessful": "Chyba při přidávání",
    "notSuccessful": "Došlo k chybě",
    "name": "Název",
    "shortName": "Krátký název",
    "priority": "Priorita",
    "delete": "Odstranit",
    "cancel": "Zrušit",
    "ok": "OK",
    "bookingDuration": "Doba trvání rezervace",
    "showAvailableSlots": "Zobrazit dostupné sloty",
    "showBookedSlots": "Zobrazit rezervované sloty",
    "withSlider": "Zobrazit posuvník",
    "intervallBetweenBookings": "Doba mezi dvěma rezervacemi",
    "maxBookingsInFuture": "Maximální počet rezervací",
    "maxBookingsPerDay": "Maximální počet rezervací za den",
    "maxBookingsPerWeek": "Maximální počet rezervací za týden",
    "maxPersonsBookable": "Maximální počet rezervovatelných slotů",
    "minPersonsBookable": "Min. počet rezervovatelných slotů",
    "newBookingType": "Nový typ rezervace",
    "from": "Období od",
    "to": "Období do",
    "cart": "Košík",
    "teetimes": "Teetimes",
    "pro": "Pro",
    "courses": "Kurzy",
    "course": "Kurz",
    "ranges": "Rozsah",
    "available": "",
    "sperrungnoshow": "Uzamčené stránky",
    "showAvatar": "Zobrazení avatara v rezervačních kalendářích",
    "withDog": "Se psem",
    "with9Hole": "Plány na hraní 9 jamek",
    "withPlayWithMe": "Rádi si se mnou rezervujete",
    "member": "Člen",
    "memberFemale": "Členka",
    "memberMale": "Člen muž",
    "guest": "Host",
    "guestFemale": "Hostující žena",
    "guestMale": "Hostující muž",
    "displayOptions": "Možnosti zobrazení",
    "showName": "Zobrazit název",
    "showHCP": "Zobrazit handicap",
    "showGender": "Zobrazit pohlaví",
    "all": "Všechny",
    "min1": "Min. 1 volné místo",
    "min2": "Min. 2 volná místa",
    "min3": "Min. 3 volná místa",
    "min4": "Min. 4 volná místa",
    "hcp": "Handicap",
    "syncHCP": "Synchronizovat handicap",
    "hcpSyncOK": "Synchronizace handicapu úspěšná",
    "hcpErrorSync": "Chyba při synchronizaci handicapu",
    "bookingTeetimeHelp": "Nápověda k rezervaci teetime",
    "myBooking": "Moje rezervace",
    "monday": "Mon",
    "tuesday": "Út",
    "wednesday": "St",
    "thursday": "Čt",
    "friday": "Pá",
    "saturday": "Sat",
    "sunday": "Sun",
    "makeBooking": "Rezervujte si teetime",
    "makeBookingAndPay": "Rezervace a platba teetime",
    "makeProBooking": "Rezervujte si profesionální lekci",
    "makeAndPayProBooking": "Rezervujte a zaplaťte profesionální lekci",
    "makeRangeBooking": "Rezervace rozsahu slot",
    "makeAndPayRangeBooking": "Rezervujte a zaplaťte interval",
    "maxDays": "Maximální počet dní pro rezervaci",
    "timeSwitch": "Čas přidat další den",
    "allowAlwaysBookingHours": "Hodiny jsou vždy rezervovatelné",
    "courseConfigs": "Konfigurace kurzu",
    "unlimited": "Žádná omezení",
    "myCourseData": "Moje data",
    "myFutureBookings": "Celkový počet rezervací",
    "myBookingToday": "Vybrané datum rezervací",
    "myBookingWeek": "Rezervace v tomto týdnu",
    "bookingCourseHelp": "Nápověda Konfigurace kurzu",
    "bookingCourseHelp1": "Tyto konfigurace byly uloženy golfovým klubem.",
    "openFriends": "Konfigurace přátel",
    "editBooking": "Moje rezervace",
    "@me": "Já",
    "player": "Hráč",
    "username": "Číslo identifikační karty nebo přezdívka",
    "announcement": "Oznámení",
    "participants": "Účastníci",
    "of": "z",
    "round": "Kulaté",
    "guests": "Hosté",
    "webAvailable": "K dispozici na webu",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "Není relevantní pro HCPI™",
    "HCPREL1": "HCPI™-Relevantní",
    "regPeriod": "Období registrace",
    "hcpi": "HCPI™",
    "toStr": "na",
    "timerRunning": "Teetime stále čeká...",
    "timerStopped": "Váš teetime je nyní k dispozici i ostatním zákazníkům.",
    "teetimeLocked": "Teetime je blokován",
    "tournamentRegisterError1": "Nepřihlášen",
    "tournamentRegisterError2": "Neodpovídající pohlaví",
    "tournamentRegisterError3": "Neodpovídající handicap",
    "tournamentRegisterError4": "Neshodující se období registrace",
    "tournamentRegisterError5": "Seznam přihlášených je plný",
    "tournamentRegisterError6": "Neodpovídající věková skupina",
    "tournamentRegisterError7": "Registrováno v #",
    "tournamentUnRegisterError1": "Nepřihlášen",
    "tournamentUnRegisterError2": "Zrušení registrace není povoleno",
    "tournamentUnRegisterError3": "Překročení data odhlášení",
    "tournamentUnRegisterError4": "Není registrován v #",
    "tournamentUnRegisterError5": "Odhlášení není povoleno, protože bylo zaplaceno online",
    "helpCoursesTime": "Datum, čas zahájení a ukončení kurzu",
    "helpCoursesEnter": "Registrace do kurzu",
    "helpCoursesUnregister": "Odhlášení z kurzu",
    "helpCoursesSeries": "Série kurzů",
    "helpCourses": "Kurzy nápovědy",
    "helpTournamentTime": "Datum, čas zahájení a ukončení turnaje",
    "helpTournamentDown": "Otevřít podrobnosti",
    "helpTournamentUp": "Zavřít podrobnosti",
    "helpTournamentEnter": "Registrace",
    "helpTournamentEnterWaitlist": "Zaregistrujte se na čekací listinu",
    "helpTournamentUnregister": "Zrušení registrace",
    "helpTournamentGenderFemale": "Turnaj povolen pro ženy",
    "helpTournamentGenderMale": "Turnaj povolen pro muže",
    "helpTournamentML": "Seznam záznamů",
    "helpTournamentSL": "Úvodní seznam",
    "helpTournamentEL": "Seznam výsledků",
    "helpTournamentAnnouncment": "Oznámení",
    "helpTournament": "Nápověda Turnaje",
    "helpEventTime": "Datum, čas začátku a konce události",
    "helpEventDown": "Otevřít podrobnosti",
    "helpEventUp": "Zavřít podrobnosti",
    "helpEventEnter": "Registrace na akci",
    "helpEventEnterWaitlist": "Zaregistrujte se na čekací listinu události",
    "helpEventUnregister": "Odhlášení z události",
    "helpEventGenderFemale": "Událost povolená pro ženy",
    "helpEventGenderMale": "Událost povolena pro muže",
    "helpEventAnnouncment": "Popis",
    "helpEvent": "Nápověda Události",
    "helpRoundSL": "Úvodní seznam",
    "unregister": "Odhlásit se z",
    "unregisterSuccessful": "Úspěšně se odhlásil",
    "unregisterNotSuccessful": "Chyba při odhlašování",
    "registerSuccessful": "Úspěšná registrace",
    "registerNotSuccessful": "Chyba při registraci",
    "none": "Žádný výběr",
    "early": "Early",
    "late": "Pozdě",
    "makeTournamentRegistration": "Registrace",
    "makeTournamentRegistrationAndPay": "Registrace a platba",
    "makeTournamentUnRegistration": "Zrušení registrace",
    "comment": "Komentář:",
    "registerTournament": "Registrace",
    "unregisterTournament": "Zrušení registrace",
    "addAdditionalPlayers": "Další hráči týmu",
    "addAdditionalFlightPlayers": "Další hráči",
    "searchPlayer": "Hledat člena",
    "friends": "Přátelé",
    "friendGroups": "Skupiny přátel",
    "friendsSave": "Uložit přátele",
    "addAdditionalFriends": "Přidat přátele",
    "friendsNotConfirmed": "Žádost o přátelství",
    "friendsDeclined": "Odmítnuté žádosti o přátelství",
    "noTournaments": "Nebyly nalezeny žádné turnaje",
    "noEvents": "Nebyly nalezeny žádné události",
    "noTeetimeCourseFound": "Nebyl nalezen žádný rezervační kalendář teetime",
    "hole": "Díra",
    "given": "vzhledem k",
    "noPros": "Nebyl nalezen žádný profesionál",
    "noRanges": "Nebyly nalezeny žádné sloty pro rozsah",
    "nextFreeSlot": "Dnešní další volný slot",
    "booked": "rezervováno",
    "precedingHours": "Předcházející hodiny pro rezervaci slotu",
    "precedingStornoMinutes": "Minuty, do kterých lze rezervaci smazat",
    "proHelp": "Nápověda Pro",
    "proConfigs": "Konfigurace Pro",
    "rangeHelp": "Rozsah nápovědy",
    "rangeConfigs": "Konfigurace rozsahu",
    "noCourses": "Nebyly nalezeny žádné kurzy",
    "coursename": "Golfové hřiště",
    "placename": "Místo",
    "priceinfo": "Informace o ceně",
    "onlinecomments": "Komentář:",
    "livescorings": "Digitální bodování",
    "noLivescorings": "Dnes nebyly nalezeny žádné turnaje",
    "noEmail": "Není k dispozici žádná e-mailová adresa. Pro přidání e-mailové adresy kontaktujte klub.",
    "notActivated": "Váš účet není aktivován. Klikněte na aktivační odkaz a ověřte svůj e-mail. Není e-mailová adresa již aktuální? Poté prosím informujte golfový klub o aktuální e-mailové adrese a postup opakujte.",
    "sendActivationLink": "Odeslat aktivační odkaz",
    "emailSendOK": "E-mail byl úspěšně odeslán",
    "emailSendError": "Chyba při odesílání e-mailu",
    "verify": "Aktivace účtu",
    "verifyEmailOK": "Účet byl úspěšně aktivován.",
    "verifyEmailError": "Chyba při aktivaci účtu",
    "reset": "Nastavení nového hesla",
    "resetPassword": "Změna hesla",
    "passwordConfirm": "Potvrzení hesla",
    "password8Chars": "Délka alespoň 8 znaků",
    "password1UpperCase": "Alespoň 1 velké písmeno",
    "password1Number": "Alespoň jedno číslo",
    "password1SpecialChar": "Alespoň jeden speciální znak (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Hesla se shodují",
    "resetPasswordOK": "Heslo bylo úspěšně změněno",
    "resetPasswordError": "Chyba při změně hesla",
    "passwordRules": "Mysl. 8 znaků, jedno velké písmeno, jedna číslice a jeden speciální znak (-, +, _, !, @, #, $, %, ^, &, *, .).",
    "bookingDeleted": "Rezervace byla úspěšně odstraněna",
    "bookingAdded": "Úspěšně přidaná rezervace",
    "dgvnumber": "Desetimístné identifikační číslo (výhradně pro členy golfových asociací)",
    "dgvdescription": "Zaregistrujte se snadno a rychle!",
    "dgvdescription1": "Člen jedné ze tří uvedených golfových asociací?",
    "dgvdescription2": "Pak prosím vyberte příslušnou kartu a zadejte desetimístné identifikační číslo a osobní údaje.",
    "searchClubs": "Hledat zemi/klub, pokud nejste členem německého, švýcarského nebo rakouského golfového klubu",
    "searchCountry": "Hledat zemi/klub",
    "gender": "Pohlaví",
    "male": "Muži",
    "female": "Ženy",
    "title": "Název",
    "activationLinkSent": "Aktivační odkaz byl zaslán e-mailem. Zkontrolujte si prosím doručenou poštu a aktivujte svůj účet. E-mail také mohl skončit ve složce nevyžádané pošty. Vaše přihlašovací jméno pro přihlášení je: #",
    "cardclub": "Karta",
    "marker": "Značka",
    "clear": "Přehledně",
    "confirm": "Potvrďte",
    "signaturePlayer": "Podpisový hráč",
    "signatureMarker": "Podpisová značka",
    "read": "Přečtěte si",
    "gentleman": "Muži",
    "ladies": "Ženy",
    "missingScores": "Chybí skóre. Potvrzení podpisu není možné!",
    "hometeetimes": "Book teetime",
    "homefriends": "Moji přátelé",
    "homecard": "Moje RFID karty",
    "homepros": "Book Pro",
    "homecourses": "Book course",
    "homeranges": "Book range slot",
    "homecompetitions": "Kalendář turnajů",
    "homelivescorings": "Výsledková karta v aplikaci",
    "homeevents": "Kalendář akcí",
    "homeMemberdata": "Moje data",
    "homePayment": "Platba v aplikaci",
    "homeMember": "Jste již členem nebo hostem? Po přihlášení pak můžete přímo používat všechny online služby.",
    "homeEmail": "E-mailová adresa uložená v klubu?",
    "homeNewCustomer": "Zaregistrujte se nyní jako nový host a využívejte všechny online služby.",
    "myclub": "Moje data",
    "personInfo": "Osobní údaje",
    "clubInfo": "Informace o klubu",
    "addressInfo": "Informace o adrese",
    "bankInfo": "Informace o bance",
    "authorizePayments": "Autorizace plateb",
    "revokePayments": "Odvolání oprávnění",
    "paypalPayment": "Platba Paypal",
    "paypalAuthorized": "Úspěšně jste autorizovali platbu PayPal",
    "creditCardPayment": "Platba kreditní kartou",
    "cardholderName": "Jméno držitele karty",
    "creditCardNumber": "Číslo kreditní karty",
    "expiration": "Expirace",
    "accept": "Přidat kreditní kartu",
    "creditCardAuthorized": "Úspěšně jste autorizovali platbu kreditní kartou",
    "creditCardAuthorizedFailed": "Autorizace se nezdařila",
    "noPaymentMethod": "Zatím není uložen žádný způsob platby. Stačí uložit do profilu PayPal nebo kreditní kartu.",
    "paymentMethodDeleted": "Způsob platby byl odstraněn",
    "paymentMethodCreated": "Byl přidán způsob platby",
    "startdate": "Datum zahájení",
    "contractstart": "Datum zahájení smlouvy",
    "contractend": "Datum ukončení smlouvy",
    "enddate": "Datum ukončení",
    "membership": "Členství",
    "contract": "Smlouva",
    "homeclubnum": "Číslo domovského klubu",
    "street": "Ulice",
    "city": "Město",
    "telephone": "Telefon",
    "mobile": "Mobilní",
    "newsletter": "Zpravodaj",
    "allowNewsletter": "Předplatné",
    "notAllowNewsletter": "Nepředplaceno",
    "emailbill": "Odeslání účtu e-mailem",
    "allowBillEmail": "Předplatné",
    "notAllowBillEmail": "Nepředplaceno",
    "accountowner": "Vlastník účtu",
    "bank": "Banka",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Debetní výběr",
    "debitsOk": "Povolené",
    "debitsNotOk": "Není povoleno",
    "refNumber": "Referenční číslo pověření",
    "homeclub": "Home klub",
    "credit": "Kredit",
    "addFriendGroup": "Přidat skupinu přátel",
    "addCredit": "Dobíjení kreditu",
    "amount": "Částka",
    "recharge": "Dobíjení",
    "automaticRechargeBeyond": "Automatické dobíjení, pokud je kredit nižší než tato částka",
    "paymenttype": "Možnost platby",
    "rechargeBy": "Zvýšení o částku",
    "amountToPay": "Částka k úhradě",
    "freeSlots": "Hrací automaty zdarma",
    "addAdditionalFlightFriends": "Další přátelé",
    "card": "Moje karta",
    "assignedCards": "Přiřazené karty",
    "valid": "Platnost",
    "noCardFound": "Nebyla nalezena žádná karta",
    "permissions": "Oprávnění",
    "status": "Stav",
    "locked": "Uzamčené stránky",
    "active": "Aktivní",
    "cardHistory": "Historie karet",
    "noHistory": "Nejsou k dispozici žádné údaje",
    "clubSelection": "Výběr klubu",
    "bonuspoints": "Bonusové body",
    "creditoncard": "Kredit na kartě",
    "getBalls": "Žádost o získání míčků",
    "getCheckIn": "Žádost od",
    "getDoor": "",
    "pressButton": "Stiskněte stříbrné tlačítko na kuličkovém stroji",
    "ballmachineTimerRunning": "Stiskněte stříbrné tlačítko na stroji na míče v následujícím okamžiku.",
    "checkInTimerRunning": "Stiskněte stříbrné tlačítko na CheckIn během následujícího dne.",
    "doorTimerRunning": "Během několika následujících minut stiskněte stříbrné tlačítko na dveřích",
    "seconds": "Sekundy",
    "appButtonError": "Chyba při zpracování požadavku. Zkuste to prosím znovu za několik sekund.",
    "r0000001": "Při vytváření transakce se něco pokazilo",
    "r0000002": "Vytvořili jste platbu. Stiskněte prosím stříbrné tlačítko na čtečce pro potvrzení platby.",
    "r0000003": "Čtečku používají další lidé. Počkejte prosím chvíli a zkuste to znovu",
    "r0000004": "Toto číslo DGV již bylo použito",
    "r0000005": "Hardware nebyl nalezen",
    "frequency0": "",
    "frequency1": "měsíční",
    "frequency2": "čtvrtletně",
    "frequency3": "pololetně",
    "frequency4": "roční",
    "packageAvailable": "k dispozici na",
    "generalPackage": "Balení míčových košů",
    "personalPackage": "Balíčky osobních košů na míče",
    "noRPRs": "Dnes nebyla nalezena žádná registrovaná soukromá kola",
    "RPRs": "Registrovaná soukromá kola",
    "RPR": "Registrované soukromé kolo",
    "getBooking": "Požadavek na zapnutí",
    "news": "Novinky v golfovém klubu",
    "imprint": "Impressum",
    "alreadyInFlight": " je již v flight",
    "acceptAgb": "Souhlasím se všeobecnými podmínkami",
    "acceptDSGVO": "Souhlasím s předpisy o ochraně údajů",
    "agb": "Všeobecné podmínky",
    "datalegal": "Privacy policy",
    "marshalls": "Marshall",
    "send": "Odeslat",
    "messageToOffice": "Zpráva pro sekretariát",
    "messageSent": "Odeslaná zpráva",
    "statusSet": "Stav byl nastaven",
    "holes": "Otvory",
    "tee": "Tee off",
    "personalScorecard": "Osobní hodnotící karta",
    "getScorecard": "K výsledkové kartě",
    "length": "Měřiče",
    "faq": "FAQs",
    "yes": "Ano",
    "no": "Ne",
    "checkIn": "Odbavení",
    "checkInText": "Měly by být dnešní teetimes nastaveny na současnost?",
    "DSGVO": "Zásady ochrany osobních údajů",
    "Agb": "Všeobecné podmínky",
    "noNews": "Nejsou k dispozici žádné novinky",
    "noPlayers": "Ve skupině nejsou žádní hráči",
    "functions": "Níže uvedené digitální golfové zážitky poskytuje tento golfový klub!",
    "wantToLogout": "Rád bych se odhlásil.",
    "canLogin": "Nyní se přihlaste pomocí přiděleného hesla a tohoto desetimístného čísla: ",
    "registerGuest": "Registrace hostů",
    "nickname": "Přezdívka",
    "contactClub": "Chcete-li změnit údaje, kontaktujte prosím klub",
    "today": "Dnes",
    "hcpiInputHint": "Zadejte HCPI™ jako číslo. Jako oddělovač desetinné tečky použijte tečku.",
    "noInternet": "Ne všechny výsledky byly předány. Zkontrolujte prosím připojení k internetu!",
    "closestClub": "Nejbližší klub",
    "mobileScreen1": "Digitální golfové zážitky ve vašem golfovém klubu",
    "mobileScreen2": "Rangebooking",
    "mobileScreen3": "Klady a kurzy",
    "mobileScreen4": "Správa karet",

    "de": "Němčina",
    "en": "Angličtina",
    "it": "Italský",
    "fr": "Francouzština",
    "cz": "Česky",
    "cn": "Cínština",
    "nl": "Holandština",
    "es": "Spanělština",
    "dfdae65b": "Nebyl nalezen žádný uživatel s danou e-mailovou adresou",
    "71b72952": "Špatné heslo",
    "71b72951": "Chybějící e-mailová adresa. Kontaktujte prosím klub!",
    "71b72953": "Chyba tokenu",
    "71b72954": "Platnost tokenu vypršela",
    "71b72955": "Neplatné heslo",
    "df6b0128": "Hráč ze skupiny si nesmí rezervovat.",
    "df6b0129": "Nepřihlášen",
    "df6b0130": "Uživatel nebyl nalezen",
    "df6b0131": "Teetime je uzamčen",
    "df6b0132": "Teetime nenalezen",
    "df6b0133": "Rezervace v minulosti",
    "df6b0134": "Rezervace nemá žádnou konfiguraci",
    "df6b0135": "Rezervaci nelze smazat",
    "df6b0136": "Nejsou k dispozici žádné sloty",
    "df6b0137": "Kurz nebyl nalezen",
    "df6b0138": "Registrace není povolena",
    "df6b0139": "Zrušení registrace není povoleno",
    "df6b0140": "Kalendář nebyl nalezen",
    "df6b0141": "Dosažený čas ukončení",
    "df6b0142": "Dosažený čas předcházející rezervaci",
    "df6b0143": "Slot není k dispozici",
    "df6b0144": "Čas je minulostí",
    "df6b0145": "Maximální počet dosažených dnů",
    "df6b0146": "Dosažené období vymazání",
    "df6b0147": "Turnaj nebyl nalezen",
    "df6b0148": "Pro nenalezen",
    "df6b0149": "Rozsah nebyl nalezen",
    "df6b0150": "Teetime placené online",
    "df6b0151": "Rozsah placený online",
    "df6b0152": "Pro vyplaceno online",
    "19e28470": "Uživatel již existuje",
    "7e341661": "Interní chyba",
    "7e341662": "Chyba přihlášení",
    "bfe57d09": "Nebyl nalezen žádný uživatel s tímto přihlašovacím jménem",
    "bfe57d10": "Žádný e-mail pro uživatele",
    "b4d05e56": "Při nahrávání souboru se něco pokazilo",
    "b4d05e57": "Při mazání souboru se něco pokazilo",
    "b4d05e58": "Při ukládání dat se něco pokazilo",
    "b4d05e59": "Při získávání dat se něco pokazilo",
    "b4d05e60": "Číslo identifikační karty je již zaregistrováno. Použijte prosím toto číslo pro přihlášení",
    "b4d05e61": "Vybraná země/klub nebyla nalezena",
    "b4d05e62": "Osoba se zadanými údaji již v systému existuje. Přihlaste se pod tímto uživatelským jménem: #",
    "b4d05e63": "Osoba nebyla nalezena. Zkontrolujte prosím zadané údaje s údaji na identifikační kartě (jméno, příjmení)."
  }
}