<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescoring</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="livescoring" id="scorecard" >
        <ion-card-header>
          <ion-card-title>{{ $t('RPR') }} {{ dayjs(livescoring.date).format('DD.MM.YYYY') }}</ion-card-title><br>

          <ion-label class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('player') }}: {{ livescoring.player.nameLong }}</ion-label><br>
          <ion-label class="ion-text-wrap" style="--margin-top: 0px;--padding: 0px;">{{ livescoring.player.course}}: {{ $t(livescoring.player.teegender) }} - {{ livescoring.player.teename }}, Par: {{livescoring.player.par}}, CR: {{livescoring.player.cr}} Slope: {{livescoring.player.slope}}</ion-label><br><br>

          <ion-label class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('marker') }}: {{ livescoring.marker.nameLong }}</ion-label><br>

        </ion-card-header>
        <ion-card-content>
          <ion-grid v-if="livescoring.scoring_mode != 3">
            <ion-row>
              <ion-col class="ion-no-padding text-center numcol bordertop">#</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">Par</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">//</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol bordertop borderright">{{ $t('player') }}</ion-col>
            </ion-row>
            <ion-row v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol"><ion-label>{{index+1}}</ion-label></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index); saveScore(index+1)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresPlayer[index] = livescoring.pars[index]; saveScore(index+1)" fill="outline" color="" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index) }}
                    </ion-label>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index]++;saveScore(index+1)" :disabled="scoreCardSigned" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18" v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol">{{index+10}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index+9]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9); saveScore(index+10)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresPlayer[index+9] = livescoring.pars[index+9];saveScore(index+10)" fill="outline" color="" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresPlayer[index+9]++;saveScore(index+10)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx >= 9 ).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.scoring_mode == 1 ? '' : livescoring.pars.reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>


          <ion-button v-if="livescoring.scoring_mode != 3" @click="read()">{{ $t('read') }}</ion-button><br>
          <ion-label><strong>{{ $t('signaturePlayer') }}</strong></ion-label>
          <Signature v-if="livescoring.status != 1" :isConfirmed="isConfirmedPlayerPad" @saveSignature="saveSignaturePlayer" v-model="livescoring.signaturePlayer" />
          <ion-img v-if="livescoring.status == 1" :src="livescoring.signaturePlayer" style="width: 300px; height: 150px;"></ion-img>
          <ion-label><strong>{{ $t('signatureMarker') }}</strong></ion-label>
          <Signature v-if="livescoring.status != 1" :isConfirmed="isConfirmedMarkerPad" @saveSignature="saveSignatureMarker" v-model="livescoring.signatureMarker"/>
          <ion-img v-if="livescoring.status == 1" :src="livescoring.signatureMarker" style="width: 300px; height: 150px;"></ion-img>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>

import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardTitle,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, IonBadge, loadingController, IonChip,
        toastController, IonImg } from '@ionic/vue';
import { defineComponent, ref, watch, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getLivescoringQueryFile from '../graphql/getLivescoringRPR.query.gql'
import saveScoreMutationFile from '../graphql/saveScoreRPR.mutation.gql'
import saveScorecardMutationFile from '../graphql/saveScorecardRPR.mutation.gql'
import * as dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
import Signature from "./SignaturePad"
import { isPlatform } from '@ionic/vue';
import translate from '@/plugins/translate'
import domtoimage from "dom-to-image-more"

export default defineComponent({
  name: 'Livescorings',
  components: {
    IonContent,
    IonHeader, Signature, IonImg,
    IonPage,
    IonTitle, 
    IonToolbar, IonCardContent, IonCardTitle, IonBadge, IonChip,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
   const router = useRouter()
   const route = useRoute()
    const isConfirmedPlayer = ref(false)
    const isConfirmedMarker = ref(false)
    const printMe = ref(null);

    const {refetch: getLivescoringRefetch, result: getLivescoringResult, loading: getLivescoringLoading } = useQuery(getLivescoringQueryFile, () => ({ id: route.params.id == null ? 0 :  parseInt(route.params.id) }), { fetchPolicy: 'no-cache' })
    const livescoring = useResult(getLivescoringResult, null, data => data.getLivescoringRPR.livescoring)
    //console.log("🚀 ~ file: LivescoringRPR.vue ~ line 341 ~ setup ~ livescoring", livescoring)

    const { mutate: saveScoreMutation } = useMutation(saveScoreMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveScorecardMutation } = useMutation(saveScorecardMutationFile, { fetchPolicy: 'no-cache' });

    const isConfirmedPlayerPad = computed(() => isConfirmedPlayer.value || livescoring.value.status == 1 ) 
    const isConfirmedMarkerPad = computed(() => isConfirmedMarker.value || livescoring.value.status == 1 ) 

    const saveSignatureMarker = (signature) => {
      if (livescoring.value.scoring_mode != 3 && livescoring.value.scoresPlayer.filter((f, idx) => f == 0 && idx < livescoring.value.player.numHoles).length > 0) {
        toastController
          .create({
            message: translate('missingScores'),
            duration: 5000,
            color: 'warning'
          }).then(res => res.present())
          return
      }
      if (signature == 'data:,')
       return
       
      livescoring.value.signatureMarker = signature
      isConfirmedMarker.value = true

    }

    const saveSignaturePlayer = async (signature) => {
      if (livescoring.value.scoring_mode != 3 && livescoring.value.scoresPlayer.filter((f, idx) => f == 0 && idx < livescoring.value.player.numHoles).length > 0) {
        toastController
          .create({
            message: translate('missingScores'),
            duration: 5000,
            color: 'warning'
          }).then(res => res.present())
          return
      }
      if (signature == 'data:,')
       return

      livescoring.value.signaturePlayer = signature
      isConfirmedPlayer.value = true
    }
    const saveScore = (hole) => {
      saveScoreMutation({ roundPlayerId: parseInt(livescoring.value.eds_id), hole: parseInt(hole), score: parseInt(livescoring.value.scoresPlayer[hole-1])  })
    }
    const decreaseScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return
      else if (scoring_mode != 1 && scores[index] == 0)
        return
      else
        scores[index]--
    }

    const showScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return '-'
      else if (scores[index] == 0)
        return ''
      else
        return scores[index]
    }

    watch([isConfirmedPlayer, isConfirmedMarker], ([player, marker], [prevPlayer, prevMarker]) => {
      if (isConfirmedPlayer.value && isConfirmedMarker.value) {
        domtoimage
          .toJpeg(document.getElementById("scorecard"), { quality: 0.5 })
          .then(function (dataUrl) {
            saveScorecardMutation({ roundPlayerId: parseInt(livescoring.value.eds_id), signaturePlayer: livescoring.value.signaturePlayer, signatureMarker: livescoring.value.signatureMarker, scorecard: dataUrl  })
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          })
      }
      
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getLivescoringLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getLivescoringLoading, (getLivescoringLoading, prevLoading) => {
      if (!getLivescoringLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getLivescoringLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getLivescoringLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })
    
    if (isPlatform('capacitor'))
      screen.orientation.lock('landscape')
    watch(
      () => route.params.id,
      (id, prevId) => {
        if (id == undefined && prevId > 0 && isPlatform('capacitor'))
          screen.orientation.unlock()
        else if (route.path.startsWith('/livescoring') && id != undefined && isPlatform('capacitor'))
          screen.orientation.lock('landscape')
      }
    )
    return {
      livescoring, personOutline, calendarClearSharp, dayjs, createOutline, route,
      removeCircle, addCircle, saveSignatureMarker, saveSignaturePlayer, arrowDownCircleOutline, arrowUpCircleOutline, isConfirmedPlayer, isConfirmedMarker, saveScore, printMe, showScore, decreaseScore, isConfirmedPlayerPad, isConfirmedMarkerPad
    }
  },
  computed: {
      scoreCardSigned : function(){ return  this.livescoring.signaturePlayer != '' || this.livescoring.status == 1 },
    },
  data () {
    return {
      synth: window.speechSynthesis,
    }
  },
  methods: {
    speak(list, interval, count) {
      if (list.length) {
        var msg = new SpeechSynthesisUtterance();
        msg.text = list[0];

        this.synth.speak(msg);

        setTimeout(() => {
            this.speak(list.slice(1), (count % 3 == 0 ? 2000 : 300), count + 1)
        }, interval)
      }
    },
    read() {
      let list = []
      for (const score of this.livescoring.scoresPlayer) {
        list.push(score.toString())
      }
      this.speak(list, 300, -1)
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-col.numcol {
    flex: 0 0 25px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    vertical-align: center;
  }

ion-col.parindexcol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.vorgabecol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }


ion-col.scorecol {
    flex: 0 0 150px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.wincol {
    flex: 0 0 100px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

.sizebig {
  height: 45px;
  width: 45px;
  vertical-align: top;
}

.borderright {
    border-right: 1px solid grey;
}

.bordertop {
    border-top: 1px solid grey;
}

</style>