import { createRouter, createWebHistory } from '@ionic/vue-router';
import Profile from '../views/Profile.vue'
import MyClub from '../views/MyClub.vue'
import store from '../main.js'
import Tournaments from '../views/Tournaments.vue'
import Events from '../views/Events.vue'
import Teetime from '../views/Teetime.vue'
import Friends from '../views/Friends.vue'
import Card from '../views/Card.vue'
import Pros from '../views/Pros.vue'
import Pro from '../views/Pro.vue'
import Courses from '../views/Courses.vue'
import Ranges from '../views/Ranges.vue'
import Range from '../views/Range.vue'
import Home from '../views/Home.vue'
import Teetimes from '../views/Teetimes.vue'
import Livescorings from '../views/Livescorings.vue'
import Livescoring from '../views/Livescoring.vue'
import LivescoringRPR from '../views/LivescoringRPR.vue'
import LivescoringSC from '../views/LivescoringSC.vue'
import News from '../views/News.vue'
import Verify from '../views/Verify.vue'
import Reset from '../views/Reset.vue'
import Infoscreen from '../views/Infoscreen.vue'
import Marshall from '../views/Marshall.vue'
import Marshalls from '../views/Marshalls.vue'
import Faq from '../views/Faq.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/teetimes',
    name: 'teetimes',
    component: Teetimes
  },
  {
    path: '/teetime/:id/:date',
    name: 'teetime_date',
    component: Teetime
  },
  {
    path: '/pros',
    name: 'pros',
    component: Pros
  },
  {
    path: '/pro/:id/:date',
    name: 'pro_date',
    component: Pro
  },
  {
    path: '/courses/:date',
    name: 'courses_',
    component: Courses
  },
  {
    path: '/courses',
    name: 'courses',
    component: Courses
  },
  {
    path: '/ranges',
    name: 'ranges',
    component: Ranges
  },
  {
    path: '/range/:id/:date',
    name: 'range_date',
    component: Range
  },
  {
    path: '/competitions/:date',
    name: 'Competitions_',
    component: Tournaments
  },
  {
    path: '/competitions',
    name: 'Competitions',
    component: Tournaments
  },
  {
    path: '/livescorings',
    name: 'Livescoring',
    component: Livescorings
  },
  {
    path: '/livescoring/:id',
    name: 'livescoring_',
    component: Livescoring
  },
  {
    path: '/livescoringsc/:id/:hcpi',
    name: 'livescoringsc_',
    component: LivescoringSC
  },
/*  {
    path: '/livescoringrpr/:id',
    name: 'livescoringrpr_',
    component: LivescoringRPR
  },*/
  {
    path: '/events/:date',
    name: 'Events_',
    component: Events
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/myclub',
    name: 'MyClub',
    component: MyClub
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends
  },
  {
    path: '/card',
    name: 'Card',
    component: Card
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/verify/:token',
    name: 'Verify',
    title: 'Verify',
    component: Verify
  },
  {
    path: '/reset/:token',
    name: 'Reset',
    title: 'Reset',
    component: Reset
  },
  {
    path: '/infoscreen/:id',
    name: 'Infoscreen',
    title: 'Infoscreen',
    showNav: false,
    component: Infoscreen
  },
  {
    path: '/marshalls',
    name: 'marshalls',
    component: Marshalls
  },
  {
    path: '/marshall/:id/:date',
    name: 'Marshall',
    title: 'Marshall',
    showNav: false,
    component: Marshall
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) =>  {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
    next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
