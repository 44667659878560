<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Reset</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section>
      <ion-card>
        <ion-list lines="full">
          <ion-item>
            <ion-label  position="stacked" class="ion-text-wrap" color="primary">{{ $t('password') }}</ion-label>
            <ion-input  :placeholder="$t('password')" type="password" v-model="password"></ion-input>
          </ion-item>
          <ion-item>
            <ul style="list-style-type: none;">
              <li>          
                <ion-icon color="danger" v-if="!isMinLength8" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isMinLength8" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password8Chars') }}</small>
              </li>
              <li>          
                <ion-icon color="danger" v-if="!isContainsUppercase" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isContainsUppercase" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1UpperCase') }}</small>
              </li>
              <li>          
                <ion-icon color="danger" v-if="!isContainsNumber" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isContainsNumber" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1Number') }}</small>
              </li>
              <li>
                <ion-icon color="danger" v-if="!isContainsSpecial" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isContainsSpecial" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1SpecialChar') }}</small>
              </li>
              <li>
                <ion-icon color="danger" v-if="!isPasswordSame" :ios="closeOutline" :md="closeOutline"></ion-icon>
                <ion-icon color="success" v-if="isPasswordSame" :ios="checkmarkOutline" :md="checkmarkOutline"></ion-icon>
                <small>{{ $t('password1PasswordSame') }}</small>
              </li>
            </ul>
            <ion-label  position="stacked" class="ion-text-wrap" color="primary">{{ $t('passwordConfirm') }}</ion-label>
            <ion-input  :placeholder="$t('passwordConfirm')" type="password" v-model="passwordConfirm"></ion-input>
          </ion-item>
          <ion-button v-if="v$" :disabled="isDisabled" @click="resetPassword()" expand="block" color="primary">{{ $t('resetPassword') }}</ion-button>
        </ion-list>
      </ion-card>
      </section>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import resetPasswordMutationFile from '../graphql/resetPassword.mutation.gql'
import { useMutation } from '@vue/apollo-composable'
import { useRoute } from 'vue-router';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { openOutline, checkmarkOutline, closeOutline } from 'ionicons/icons'
import LoginDialog from './Login'


const containsUppercase = (value) => new RegExp("(?=.*[A-Z])").test(value)
const containsSpecial = (value) => new RegExp("(?=.*[-+_!@#$%^&*., ?])").test(value)
const containsNumber = (value) => new RegExp("(?=.*[0-9])").test(value)

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute();
    const token = route.params.token
    const password = ref('')
    const passwordConfirm = ref('')
    const isMinLength8 = ref(false)
    const isContainsUppercase = ref(false)
    const isContainsSpecial = ref(false)
    const isContainsNumber = ref(false)
    const isPasswordSame = ref(false)

    const { mutate: resetPasswordMutation } = useMutation(resetPasswordMutationFile, { fetchPolicy: 'no-cache' });

    return {
      resetPasswordMutation, token, passwordConfirm, password, v$: useVuelidate(), isMinLength8, isContainsUppercase, isContainsSpecial, isContainsNumber, isPasswordSame, openOutline, checkmarkOutline, closeOutline
    }
  },
  computed: {
    isDisabled() {
      return this.v$.$invalid || this.password != this.passwordConfirm;
    },
  },
  validations () {
    return {
      password: { required, minLength: minLength(8), containsUppercase, containsSpecial, containsNumber }
    }
  },
  watch: {
    password: function() {
      this.isMinLength8 = this.password.length>=8?true:false
      this.isContainsUppercase = containsUppercase(this.password);
      this.isContainsSpecial = containsSpecial(this.password);
      this.isContainsNumber = containsNumber(this.password);
      this.isPasswordSame = this.password == this.passwordConfirm;
    },
    passwordConfirm: function() {
      this.isPasswordSame = this.password == this.passwordConfirm;
    },
  },
  methods: {
    resetPassword() {
      this.resetPasswordMutation({
        token: this.token, password: this.password
      })
      .then(res => {
        if (res && res.data && res.data.resetPassword && res.data.resetPassword.success) {
          toastController
            .create({
              message: this.$t('resetPasswordOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
            this.login()
        }
        else
          toastController
            .create({
              message: this.$t('resetPasswordError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('resetPasswordError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      })

    },
    async login() {
      const modal = await modalController
        .create({
          component: LoginDialog,
        })
        modal.onDidDismiss()
          .then(async (data) => {
            if (data.data) {
                await this.$router.push('/home')
                this.$router.go()
            }
          })
      return modal.present();
    },
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>