module.exports = {
  "translation": {
    "clubinone": "CLUBINONE 4U",
    "home": "Mon club",
    "pros": "Pour",
    "persons": "Personnes",
    "competitions": "Tournois",
    "competition": "tournoi",
    "competitionUC": "Tournoi",
    "bookings": "Réservations",
    "statistics": "Statistiques",
    "settings": "Paramètres",
    "profile": "Profil",
    "login": "Connexion",
    "logout": "Déconnexion",
    "organizations-list": "Mes organisations",
    "companies": "Clubs",
    "company": "Club",
    "searchcompanies": "Recherche de clubs",
    "searchresult": "Résultat de la recherche",
    "history": "Récent",
    "register": "Registre",
    "save": "Sauvez",
    "userData": "Données de l'utilisateur",
    "changePassword": "Modifier le mot de passe",
    "changeEmail": "Modifier l'e-mail",
    "language": "Langue",
    "firstName": "Prénom",
    "lastName": "Nom de famille",
    "birthdate": "Anniversaire",
    "password": "Mot de passe",
    "email": "Courriel :",
    "profilePicture": "Photo de profil",
    "takePicture": "Sélectionner l'image",
    "upload": "Enregistrer l'image",
    "passwordReset": "Envoyer un e-mail de réinitialisation du mot de passe",
    "events": "Événements",
    "event": "événement",
    "eventUC": "Événement",
    "loading": "Chargement des données ...",
    "newcompanies": "Devenir hôte",
    "erroLogin": "Veuillez vous connecter pour créer un club !",
    "newCategory": "Nouvelle catégorie",
    "description": "Description",
    "changeSuccessful": "Changement réussi",
    "changeNotSuccessful": "Erreur pendant le changement",
    "deleteSuccessful": "Supprimer le succès",
    "deleteNotSuccessful": "Erreur lors de la suppression",
    "addSuccessful": "Ajouter le succès",
    "addNotSuccessful": "Erreur pendant l'ajout",
    "notSuccessful": "Une erreur s'est produite",
    "name": "Nom",
    "shortName": "Nom court",
    "priority": "Priorité",
    "delete": "Supprimer",
    "cancel": "Annuler",
    "ok": "OK",
    "bookingDuration": "Durée de la réservation",
    "showAvailableSlots": "Afficher les créneaux disponibles",
    "showBookedSlots": "Afficher les créneaux réservés",
    "withSlider": "Afficher le curseur",
    "intervallBetweenBookings": "Temps entre deux réservations",
    "maxBookingsInFuture": "Réservations maximales",
    "maxBookingsPerDay": "Nombre maximal de réservations par jour",
    "maxBookingsPerWeek": "Nombre maximal de réservations par semaine",
    "maxPersonsBookable": "Nombre maximal de créneaux horaires réservables",
    "minPersonsBookable": "Nombre minimal de créneaux horaires réservables",
    "newBookingType": "Nouveau type de réservation",
    "from": "Période du",
    "to": "Période à",
    "cart": "Panier",
    "teetimes": "Heures d'ouverture",
    "pro": "Pro",
    "courses": "Cours",
    "course": "Cours",
    "ranges": "Gamme",
    "available": "",
    "sperrungnoshow": "Verrouillé",
    "showAvatar": "Afficher l'avatar dans les calendriers de réservation",
    "withDog": "Avec le chien",
    "with9Hole": "Prévoit de jouer 9 trous",
    "withPlayWithMe": "Heureux de réserver avec moi",
    "member": "Membre",
    "memberFemale": "Membre féminin",
    "memberMale": "Membre masculin",
    "guest": "Invité :",
    "guestFemale": "Femme invitée",
    "guestMale": "Homme invité",
    "displayOptions": "Options d'affichage",
    "showName": "Nom du spectacle",
    "showHCP": "Afficher le handicap",
    "showGender": "Afficher le sexe",
    "all": "Tous",
    "min1": "Min. 1 place gratuite",
    "min2": "Min. 2 places libres",
    "min3": "Min. 3 places libres",
    "min4": "Min. 4 places libres",
    "hcp": "Handicap",
    "syncHCP": "Synchroniser le handicap",
    "hcpSyncOK": "Synchronisation des handicaps réussie",
    "hcpErrorSync": "Erreur de synchronisation du handicap",
    "bookingTeetimeHelp": "Aide à la réservation de teetime",
    "myBooking": "Ma réservation",
    "monday": "Lun",
    "tuesday": "Mar",
    "wednesday": "Mer",
    "thursday": "Jeu",
    "friday": "Ven",
    "saturday": "Sam",
    "sunday": "Dim",
    "makeBooking": "Réservez le teetime",
    "makeBookingAndPay": "Réservez et payez le teetime",
    "makeProBooking": "Réserver une leçon pro",
    "makeAndPayProBooking": "Réserver et payer une leçon pro",
    "makeRangeBooking": "Réserver une plage horaire",
    "makeAndPayRangeBooking": "Réservation et paiement de créneaux horaires",
    "maxDays": "Jours maximum pour la réservation",
    "timeSwitch": "Temps ajouter un jour supplémentaire",
    "allowAlwaysBookingHours": "Heures toujours réservables",
    "courseConfigs": "Configuration du cours",
    "unlimited": "Aucune restriction",
    "myCourseData": "Mes données",
    "myFutureBookings": "Total des réservations",
    "myBookingToday": "Date sélectionnée pour les réservations",
    "myBookingWeek": "Réservations cette semaine",
    "bookingCourseHelp": "Aide Configuration du parcours",
    "bookingCourseHelp1": "Ces configurations ont été déposées par le club de golf.",
    "openFriends": "Configurer les amis",
    "editBooking": "Ma réservation",
    "@me": "Moi",
    "player": "Joueur",
    "username": "Numéro de carte d'identité ou surnom",
    "announcement": "Annonce",
    "participants": "Participants",
    "of": "de",
    "round": "Ronde",
    "guests": "Invités",
    "webAvailable": "Disponible en ligne",
    "PF0": "Single",
    "PF1": "Aggregat Foursome",
    "PF2": "Foursome",
    "PF3": "Foursome best drive",
    "PF4": "Chapman Foursome",
    "PF5": "Ryesome Foursome",
    "PF6": "Fourball",
    "PF7": "Fourball Bestball",
    "PF8": "Scramble",
    "PF9": "Twosome Scramble",
    "PF10": "Texas Scramble",
    "PF11": "Florida Scramble",
    "PF12": "RPR",
    "SM0": "Strokeplay",
    "SM1": "Stableford",
    "SM2": "Against Par",
    "SM3": "Matchplay",
    "SM4": "Strokeplay and Stableford",
    "SM5": "Mixed",
    "SM6": "Flag",
    "SM7": "Free",
    "SM8": "Strokeplay max score",
    "W0": "18 holes",
    "W1": "9 holes",
    "HCPREL0": "Non HCPI™-Relevant",
    "HCPREL1": "HCPI™-Relevant",
    "regPeriod": "Période d'enregistrement",
    "hcpi": "HCPI™",
    "toStr": "à",
    "timerRunning": "En attendant l'heure du thé.",
    "timerStopped": "Votre temps d'essai est maintenant disponible pour les autres clients.",
    "teetimeLocked": "Teetime est bloqué",
    "tournamentRegisterError1": "Non connecté",
    "tournamentRegisterError2": "Le sexe ne correspond pas",
    "tournamentRegisterError3": "Handicap non conforme",
    "tournamentRegisterError4": "La période d'enregistrement ne correspond pas",
    "tournamentRegisterError5": "La liste des participants est complète",
    "tournamentRegisterError6": "Groupe d'âge ne correspondant pas",
    "tournamentRegisterError7": "Inscrit dans le numéro",
    "tournamentUnRegisterError1": "Non connecté",
    "tournamentUnRegisterError2": "Désinscription non autorisée",
    "tournamentUnRegisterError3": "Date de désinscription dépassée",
    "tournamentUnRegisterError4": "Non enregistré dans le #.",
    "tournamentUnRegisterError5": "Le désenregistrement n'est pas autorisé parce que le paiement a été effectué en ligne",
    "helpCoursesTime": "Date, heure de début et de fin du cours",
    "helpCoursesEnter": "S'inscrire au cours",
    "helpCoursesUnregister": "Se désinscrire du cours",
    "helpCoursesSeries": "Série de cours",
    "helpCourses": "Cours d'aide",
    "helpTournamentTime": "Date, heure de début et de fin du tournoi",
    "helpTournamentDown": "Ouvrir les détails",
    "helpTournamentUp": "Fermer les détails",
    "helpTournamentEnter": "Registre",
    "helpTournamentEnterWaitlist": "S'inscrire sur la liste d'attente",
    "helpTournamentUnregister": "Désenregistrer",
    "helpTournamentGenderFemale": "Tournoi autorisé pour les femmes",
    "helpTournamentGenderMale": "Tournoi autorisé pour les hommes",
    "helpTournamentML": "Liste d'entrée",
    "helpTournamentSL": "Liste de départ",
    "helpTournamentEL": "Liste des résultats",
    "helpTournamentAnnouncment": "Annonce",
    "helpTournament": "Aide Tournois",
    "helpEventTime": "Date, heure de début et de fin de l'événement",
    "helpEventDown": "Ouvrir les détails",
    "helpEventUp": "Fermer les détails",
    "helpEventEnter": "S'inscrire à l'événement",
    "helpEventEnterWaitlist": "S'inscrire sur la liste d'attente de l'événement",
    "helpEventUnregister": "Se désinscrire de l'événement",
    "helpEventGenderFemale": "Événement autorisé pour les femmes",
    "helpEventGenderMale": "Événement autorisé pour les hommes",
    "helpEventAnnouncment": "Description",
    "helpEvent": "Événements d'aide",
    "helpRoundSL": "Liste de départ",
    "unregister": "Se déconnecter de",
    "unregisterSuccessful": "Signé avec succès",
    "unregisterNotSuccessful": "Erreur lors de la déconnexion",
    "registerSuccessful": "Enregistré avec succès",
    "registerNotSuccessful": "Erreur pendant le registre",
    "none": "Aucune sélection",
    "early": "Early",
    "late": "Late",
    "makeTournamentRegistration": "Registre",
    "makeTournamentRegistrationAndPay": "S'inscrire et payer",
    "makeTournamentUnRegistration": "Désenregistrer",
    "comment": "Commentaire",
    "registerTournament": "Registre",
    "unregisterTournament": "Désenregistrer",
    "addAdditionalPlayers": "Joueurs d'équipe supplémentaires",
    "addAdditionalFlightPlayers": "Joueurs supplémentaires",
    "searchPlayer": "Rechercher un membre",
    "friends": "Amis",
    "friendGroups": "Groupes d'amis",
    "friendsSave": "Sauver les amis",
    "addAdditionalFriends": "Ajouter des amis",
    "friendsNotConfirmed": "Demande d'ami",
    "friendsDeclined": "Demandes d'amis refusées",
    "noTournaments": "Aucun tournoi trouvé",
    "noEvents": "Aucun événement trouvé",
    "noTeetimeCourseFound": "Aucun calendrier de réservation Teetime n'a été trouvé",
    "hole": "Trou",
    "given": "donné",
    "noPros": "Aucun Pros trouvé",
    "noRanges": "Aucun créneau de portée trouvé",
    "nextFreeSlot": "Todays next free slot",
    "booked": "réservé",
    "precedingHours": "Heures précédentes pour réserver un créneau",
    "precedingStornoMinutes": "Minutes avant qu'une réservation puisse être supprimée",
    "proHelp": "Aide Pro",
    "proConfigs": "Pro Configs",
    "rangeHelp": "Gamme d'aide",
    "rangeConfigs": "Configuration de la gamme",
    "noCourses": "Aucun cours trouvé",
    "coursename": "Terrain de golf",
    "placename": "Lieu",
    "priceinfo": "Infos prix",
    "onlinecomments": "Commentaire",
    "livescorings": "Scoring numérique",
    "noLivescorings": "Aucun tournoi trouvé aujourd'hui",
    "noEmail": "Il n'y a pas d'adresse e-mail. Veuillez contacter le club afin que votre adresse e-mail soit enregistrée.",
    "notActivated": "Votre compte n'est pas activé. Veuillez cliquer sur envoyer le lien d'activation pour vérifier votre email. L'adresse e-mail n'est plus actuelle ? Dans ce cas, veuillez communiquer au club de golf l'adresse e-mail actuelle et répéter la procédure.",
    "sendActivationLink": "Envoyer le lien d'activation",
    "emailSendOK": "Courriel envoyé avec succès",
    "emailSendError": "Erreur d'envoi de l'email",
    "verify": "Activer le compte",
    "verifyEmailOK": "Le compte a été activé avec succès.",
    "verifyEmailError": "Erreur d'activation du compte",
    "reset": "Définir un nouveau mot de passe",
    "resetPassword": "Modifier le mot de passe",
    "passwordConfirm": "Confirmer le mot de passe",
    "password8Chars": "Au moins 8 caractères",
    "password1UpperCase": "Au moins une lettre majuscule",
    "password1Number": "Au moins un chiffre",
    "password1SpecialChar": "Au moins un caractère spécial (- + _ ! @ # $ % ^ & *)",
    "password1PasswordSame": "Les mots de passe correspondent",
    "resetPasswordOK": "Le mot de passe a été changé avec succès",
    "resetPasswordError": "Erreur lors de la modification du mot de passe",
    "passwordRules": "Esprit. 8 caractères, une majuscule, un chiffre et un caractère spécial (-, +, _, !, @, #, $, %, ^, &, *, .).",
    "bookingDeleted": "Réservation supprimée avec succès",
    "bookingAdded": "Réservation ajoutée avec succès",
    "dgvnumber" : "Numéro de carte à 10 chiffres (exclusivement pour les membres des fédérations de golf)",
    "dgvdescription" : "S'inscrire maintenant, facilement et rapidement !",
    "dgvdescription1" : "Membre d'une des trois associations de golf mentionnées ?",
    "dgvdescription2" : "Veuillez ensuite sélectionner la carte correspondante et saisir le numéro de carte à 10 chiffres, ainsi que les données personnelles",
    "searchClubs": "Recherchez le pays/club si vous n'êtes pas membre d'un club allemand, suisse ou autrichien.",
    "searchCountry": "Recherche par pays/club",
    "gender": "Genre",
    "male": "Homme",
    "female": "Femme",
    "title": "Titre",
    "activationLinkSent": "Un lien d'activation a été envoyé par e-mail. Veuillez vérifier votre boîte de réception et activer votre compte. L'e-mail peut également avoir été placé dans le dossier des spams. Votre identifiant pour vous connecter est le suivant #",
    "cardclub": "Carte",
    "marker": "Marqueur",
    "clear": "Clair",
    "confirm": "Confirmer",
    "signaturePlayer": "Signature Player",
    "signatureMarker": "Marqueur de signature",
    "read": "Lire",
    "gentleman": "Hommes",
    "ladies": "Femmes",
    "missingScores": "Les scores sont manquants. La confirmation de la signature n'est pas possible !",
    "hometeetimes": "Réservation teetime",
    "homefriends": "Mes amis",
    "homecard": "Mes cartes RFID",
    "homepros": "Réservation pro",
    "homecourses": "Réservation cours",
    "homeranges": "Emplacement de gamme de livres",
    "homecompetitions": "Calendrier des tournois",
    "homelivescorings": "Notation dans l'application",
    "homeevents": "Scorecard in app",
    "homeMemberdata": "Mes données",
    "homePayment": "Paiement en ligne",
    "homeMember": "Déjà membre ou invité ? Alors utilise directement tous les services en ligne après t'être connecté.",
    "homeEmail": "Adresse e-mail stockée dans le club ?",
    "homeNewCustomer": "Inscrivez-vous maintenant comme nouveau visiteur et profitez de tous les services en ligne.",
    "myclub": "Mes données",
    "personInfo": "Informations personnelles",
    "clubInfo": "Informations sur le club",
    "addressInfo": "Informations sur l'adresse",
    "bankInfo": "Informations bancaires",
    "authorizePayments": "Autoriser les paiements",
    "revokePayments": "Révoquer l'autorisation",
    "paypalPayment": "Paiement par Paypal",
    "paypalAuthorized": "Vous avez autorisé le paiement PayPal avec succès",
    "creditCardPayment": "Paiement par carte de crédit",
    "cardholderName": "Nom du titulaire de la carte",
    "creditCardNumber": "Numéro de carte de crédit",
    "expiration": "Expiration",
    "accept": "Ajouter une carte de crédit",
    "creditCardAuthorized": "Vous avez autorisé avec succès le paiement par carte de crédit",
    "creditCardAuthorizedFailed": "Authorizierung échoué",
    "noPaymentMethod": "Il n'y a pas encore de mode de paiement enregistré. Il suffit d'enregistrer dans le profil PayPal ou une carte de crédit.",
    "paymentMethodDeleted": "Le mode de paiement a été supprimé",
    "paymentMethodCreated": "Le mode de paiement a été ajouté",
    "startdate": "Date de début",
    "contractstart": "Date de début du contrat",
    "contractend": "Date de fin du contrat",
    "enddate": "Date de fin",
    "membership": "Adhésion",
    "contract": "Contrat",
    "homeclubnum": "Numéro du club d'origine",
    "street": "Rue",
    "city": "Ville",
    "telephone": "Téléphone",
    "mobile": "Mobile",
    "newsletter": "Bulletin d'information",
    "allowNewsletter": "Abonné",
    "notAllowNewsletter": "Pas souscrit",
    "emailbill": "Envoyer la facture par courriel",
    "allowBillEmail": "Abonné",
    "notAllowBillEmail": "Pas souscrit",
    "accountowner": "Propriétaire du compte",
    "bank": "Banque",
    "iban": "IBAN",
    "bic": "BIC",
    "debOk": "Retrait du débit",
    "debitsOk": "Autorisé",
    "debitsNotOk": "Non autorisé",
    "refNumber": "Numéro de référence du mandat",
    "homeclub": "Club d'accueil",
    "credit": "Crédit",
    "addFriendGroup": "Ajouter un groupe d'amis",
    "addCredit": "Crédit de recharge",
    "amount": "Montant",
    "recharge": "Recharge",
    "automaticRechargeBeyond": "Recharge automatique si le crédit est inférieur à ce montant",
    "paymenttype": "Option de paiement",
    "rechargeBy": "Augmentation du montant",
    "amountToPay": "Montant à payer",
    "freeSlots": "Machines à sous gratuites",
    "addAdditionalFlightFriends": "Amis supplémentaires",
    "card": "Ma carte",
    "assignedCards": "Cartes attribuées",
    "valid": "Validité",
    "noCardFound": "Aucune carte trouvée",
    "permissions": "Permissions",
    "status": "Statut",
    "locked": "Verrouillé",
    "active": "Actif",
    "cardHistory": "Historique de la carte",
    "noHistory": "Aucune donnée disponible",
    "clubSelection": "Sélection des clubs",
    "bonuspoints": "Points bonus",
    "creditoncard": "Crédit sur la carte",
    "getBalls": "Demande d'obtention de balles",
    "getCheckIn": "Demande d'enregistrement",
    "getDoor": "Demande par",
    "pressButton": "Appuyez sur le bouton argenté de la machine à balles.",
    "ballmachineTimerRunning": "Appuyez sur le bouton argenté de la machine à balles dans les prochains jours.",
    "checkInTimerRunning": "Appuyez sur le bouton argenté sur le CheckIn dans les prochains jours.",
    "doorTimerRunning": "Appuyer sur le bouton argenté de la porte dans les prochaines minutes",
    "seconds": "Secondes",
    "appButtonError": "Erreur de traitement de la demande. Veuillez réessayer dans quelques secondes.",
    "r0000001": "Un problème est survenu lors de la création de la transaction",
    "r0000002": "Vous avez créé un paiement. Veuillez appuyer sur le bouton argenté du lecteur pour confirmer le paiement.",
    "r0000003": "Le lecteur est utilisé par d'autres personnes. Veuillez attendre un moment et réessayer",
    "r0000004": "Ce numéro DGV a déjà été utilisé",
    "r0000005": "Matériel non trouvé",
    "frequency0": "",
    "frequency1": "mensuel",
    "frequency2": "quaterly",
    "frequency3": "semestriel",
    "frequency4": "annuel",
    "packageAvailable": "disponible sur",
    "generalPackage": "Paquets de paniers de balles",
    "personalPackage": "Paquets personnels de paniers de balles",
    "noRPRs": "Aucun tour privé enregistré trouvé aujourd'hui",
    "RPRs": "Rondes privées enregistrées",
    "RPR": "Cycle privé enregistré",
    "getBooking": "Demande de mise en marche",
    "news": "Actualités du club de golf",
    "imprint": "Impressum",
    "alreadyInFlight": " est déjà en flight",
    "acceptAgb": "J'accepte les conditions générales de vente",
    "acceptDSGVO": "J'accepte les dispositions relatives à la protection des données",
    "agb": "Všeobecné podmínky",
    "datalegal": "Privacy policy",
    "marshalls": "Marshall",
    "send": "Envoyer",
    "messageToOffice": "Message au secrétariat",
    "messageSent": "Message envoyé",
    "statusSet": "Le statut a été défini",
    "holes": "Trous",
    "tee": "Tee",
    "personalScorecard": "Carte de score personnelle",
    "getScorecard": "Vers la carte de score",
    "length": "Mètres",
    "faq": "FAQs",
    "yes": "Oui",
    "no": "Non",
    "checkIn": "Vérifier",
    "checkInText": "Les heures d'aujourd'hui doivent-elles être réglées sur le présent ?",
    "DSGVO": "Politique de confidentialité",
    "Agb": "Conditions générales d'utilisation",
    "noNews": "Pas de nouvelles disponibles",
    "noPlayers": "Il n'y a pas de joueurs dans le groupe",
    "functions": "Les expériences de golf numériques ci-dessous sont mises à votre disposition par ce club de golf!",
    "wantToLogout": "Je souhaite me déconnecter.",
    "canLogin": "Connecte-toi maintenant avec le mot de passe attribué et ce numéro à 10 chiffres : ",
    "registerGuest": "Inscription pour les invités",
    "nickname": "Pseudo",
    "contactClub": "Pour modifier les données, veuillez contacter le club",
    "today": "Aujourd'hui",
    "hcpiInputHint": "Saisir HCPI™ sous forme de nombre. Utiliser un point comme séparateur de la virgule.",
    "noInternet": "Tous les résultats ne sont pas transmis. Veuillez vérifier la connexion Internet !",
    "closestClub": "Le club le plus proche",
    "mobileScreen1" : "Expériences de golf numériques dans votre club de golf",
    "mobileScreen2" : "Réservation de parcours",
    "mobileScreen3" : "Pros & cours",
    "mobileScreen4" : "gérer les cartes",
    
    "de": "Allemand",
    "en": "Anglais",
    "it": "Italien",
    "fr": "Français",
    "cz": "Tchèque",
    "cn": "Chinois",
    "nl": "Néerlandais",
    "es": "Espagnole",
    "dfdae65b": "Aucun utilisateur n'a été trouvé avec l'adresse e-mail donnée",
    "71b72952": "Mot de passe erroné",
    "71b72951": "Adresse e-mail manquante. Veuillez contacter le club !",
    "71b72953": "Erreur de jeton",
    "71b72954": "Token expiré",
    "71b72955": "Mot de passe invalide",
    "df6b0128": "Un joueur du groupe n'est pas autorisé à réserver.",
    "df6b0129": "Non connecté",
    "df6b0130": "Utilisateur non trouvé",
    "df6b0131": "Teetime est verrouillé",
    "df6b0132": "Teetime non trouvé",
    "df6b0133": "Réservation dans le passé",
    "df6b0134": "La réservation n'a pas de configuration",
    "df6b0135": "La réservation ne peut être supprimée",
    "df6b0136": "Aucun créneau disponible",
    "df6b0137": "Cours non trouvé",
    "df6b0138": "Enregistrement non autorisé",
    "df6b0139": "Désinscription non autorisée",
    "df6b0140": "Calendrier non trouvé",
    "df6b0141": "Heure de fin atteinte",
    "df6b0142": "Réservation précédant l'heure atteinte",
    "df6b0143": "Slot non disponible",
    "df6b0144": "Le temps est dans le passé",
    "df6b0145": "Nombre maximal de jours atteints",
    "df6b0146": "Période de suppression atteinte",
    "df6b0147": "Tournoi non trouvé",
    "df6b0148": "Pro non trouvé",
    "df6b0149": "Plage non trouvée",
    "df6b0150": "Teetime payé en ligne",
    "df6b0151": "Gamme payée en ligne",
    "df6b0152": "Pro payé en ligne",
    "19e28470": "L'utilisateur existe déjà",
    "7e341661": "Erreur interne",
    "7e341662": "Erreur de connexion",
    "bfe57d09": "Aucun utilisateur avec ce login n'a été trouvé",
    "bfe57d10": "Pas d'email pour l'utilisateur",
    "b4d05e56": "Un problème est survenu lors du téléchargement du fichier",
    "b4d05e57": "Un problème est survenu lors de la suppression du fichier",
    "b4d05e58": "Un problème est survenu lors de la sauvegarde des données",
    "b4d05e59": "Un problème est survenu lors de l'obtention des données",
    "b4d05e60": "Le numéro de carte d'identité est déjà enregistré. Veuillez utiliser ce numéro pour vous connecter",
    "b4d05e61": "Le pays sélectionné n'a pas/club été trouvé",
    "b4d05e62": "Une personne avec les données fournies existe déjà dans le système. Connectez-vous avec cet identifiant : #",
    "b4d05e63": "La personne n'a pas été trouvée. Veuillez vérifier les données indiquées avec celles de la carte d'identité (nom, prénom)."
  }
}