<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>{{ $t('addCredit') }}</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">{{ $t('amount')}}</ion-label>
        <ion-select style="min-width: 100% !important;" interface="popover" v-model="creditamount" :value="creditamount">
          <ion-select-option value="20">20</ion-select-option>          
          <ion-select-option value="50">50</ion-select-option>          
          <ion-select-option value="100">100</ion-select-option>          
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('paymenttype')}}</ion-label>
          <div style="margin-top: 5px;" id="paypalButton"></div>

<!--        <ion-select style="min-width: 100% !important;" interface="popover" v-model="paymenttype" :value="paymenttype">
          <ion-select-option v-if="isSuccessfullBraintreePaypalAuthorized" value="PayPalAccount">{{ $t('paypalPayment') }}</ion-select-option>  
          <ion-select-option v-if="isSuccessfullBraintreeCreditCardAuthorized" value="CreditCard">{{ $t('creditCardPayment') }}</ion-select-option>  
        </ion-select>-->
      </ion-item>

      <!-- <ion-button :disabled="rechargeDisabled" @click="recharge()" expand="block" color="success">{{ $t('recharge') }}</ion-button>-->
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonSelect, IonSelectOption, IonButton, IonContent, 
          IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonToggle,
          IonCol, IonRow, modalController, IonDatetime, loadingController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline } from 'ionicons/icons';
import addCreditMutationFile from '../../graphql/addCredit.mutation.gql'
import createPaymentMutationFile from '../../graphql/createPayment.mutation.gql'
import { useMutation } from '@vue/apollo-composable'
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'AddCreditDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonToggle, IonDatetime },
  props: ['propsData'],
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
  },
  mounted() {
    loadScript({ "client-id": "AQe-1_UiC8WAkzp_FORkgq6UT6rRyNqXv2g74Q7ZczFrEIGCwAG65v6m6W3BcNNamN7gtuoNfahiRH0v", currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          const p = await this.createPaymentMutation({ description: this.$t('credit'), amount: parseFloat(this.creditamount), paymentType: 'payPal' })
          if (!p.data.createPayment.success) {
            return ''
          }
          return p.data.createPayment.token
         },
         onError: async () => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
            this.buttonDisabled = true
            loadingController.create().then(res => {
              res.present()
            }).catch(err => { })
            this.addCreditMutation({ 
              amount: parseFloat(this.creditamount),
              type: this.paymenttype,
              uid: this.selectedCard.uid,
              orderId: data.orderID
              })
            .then(res => {
                toastController
                  .create({
                    message: this.$t('addSuccessful'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
                toastController
                  .create({
                    message: this.$t('addNotSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
            }) 
         },
         onCancel: async () => {
          await this.createPaymentMutation({ amount: parseFloat(this.creditamount), paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});

    this.paymenttype = 'PayPalAccount'
    /*if (this.isSuccessfullBraintreePaypalAuthorized && this.isSuccessfullBraintreeCreditCardAuthorized )
      this.paymenttype = 'PayPalAccount'
    else if (this.isSuccessfullBraintreePaypalAuthorized)
      this.paymenttype = 'PayPalAccount'
    else if (this.isSuccessfullBraintreeCreditCardAuthorized)
      this.paymenttype = 'CreditCard' */
  },
  setup(props) {
    const creditamount = ref('50')
    const paymenttype = ref('')
    const rechargeDisabled = ref(false)
    let selectedCard = props.propsData.selectedCard
    const clubInfo = ref(getClubInfo())

    const { mutate: addCreditMutation } = useMutation(addCreditMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutation } = useMutation(createPaymentMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline, addCreditMutation, creditamount, paymenttype, rechargeDisabled, selectedCard, createPaymentMutation
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    async recharge() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.rechargeDisabled = true
      this.addCreditMutation({ 
        amount: parseFloat(this.creditamount),
        type: this.paymenttype,
        uid: this.selectedCard.uid
        })
      .then(res => {
        if (res && res.data && res.data.addCredit && res.data.addCredit.success) {
          toastController
            .create({
              message: this.$t('addSuccessful'),
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(this.creditamount)
        }
        else {
          loadingController.dismiss()
          toastController
            .create({
              message: res.data.addCredit + this.$t('addNotSuccessful'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        }
        })
      .catch(err => {
          toastController
            .create({
              message: err + this.$t('addNotSuccessful'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    },
  }
});


</script>